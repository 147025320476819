import { Card, Table } from "antd";
import { useCubeQuery } from "@cubejs-client/react";
import TopCasesTAT from "./TopCasesTAT";
import { handleQueryFilters, organTATQuery, toHHMMSS } from "../utils";
import { useSelector } from "react-redux";

const TatBreachTab = () => {
  const tatReducer = useSelector((state) => state.tatReducer);

  const {
    resultSet: organTATData,
    isLoading: organTATLoading,
    error: organTATError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: organTATQuery,
      before_date: tatReducer.endDate,
      after_date: tatReducer.startDate,
    })
  );

  const columns = [
    {
      title: "Study",
      dataIndex: "PhaseLevelTat.modality",
      align: "center",
      render: (text, row) => (
        <p style={{ margin: 0 }}>
          {row["PhaseLevelTat.modality"]} {row["PhaseLevelTat.organ"]}
          <br></br>
        </p>
      ),
    },
    {
      title: "IQC TAT",
      dataIndex: "PhaseLevelTat.organ_iqc_tat",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{toHHMMSS(text)}</p>,
    },
    {
      title: "Assignment TAT",
      dataIndex: "PhaseLevelTat.organ_assignment_tat",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{toHHMMSS(text)}</p>,
    },
    {
      title: "Radiologist TAT",
      dataIndex: "PhaseLevelTat.organ_radiologist_tat",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{toHHMMSS(text)}</p>,
    },
    {
      title: "OQC TAT",
      dataIndex: "PhaseLevelTat.organ_oqc_tat",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{toHHMMSS(text)}</p>,
    },
    {
      title: "Rework TAT",
      dataIndex: "PhaseLevelTat.organ_rework_tat",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{toHHMMSS(text)}</p>,
    },
  ];

  return (
    <>
      <Card size="small" bordered>
        <p>
          <b>Average TAT for most Common TAT Breached Study Organ</b>
        </p>
        <Table
          columns={columns}
          dataSource={organTATData?.rawData()}
          loading={organTATLoading}
          bordered
          error={organTATError}
          pagination={false}
          scroll={{ x: "max-content" }}
        />

        <TopCasesTAT />
      </Card>
    </>
  );
};
export default TatBreachTab;
