export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const CUBE_TOKEN = "CUBE_TOKEN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_VERIFY_START = "AUTH_VERIFY_START";
export const AUTH_VERIFY = "AUTH_VERIFY";
export const AUTH_FAILED = "AUTH_FAILED";

export const AM_ACCOUNT_ID = "AM_ACCOUNT_ID";

export const CHURN_DATE_RANGE = "CHURN_DATE_RANGE";
export const CHURN_CLIENT_LIST_FILTER = "CHURN_CLIENT_LIST_FILTER";
export const CHURN_CLEAR_CLIENT_LIST_FILTER = "CHURN_CLEAR_CLIENT_LIST_FILTER";

export const TAT_DATE_RANGE = "TAT_DATE_RANGE";

export const OAD_DD = "OAD_DD";
export const OAD_SEARCH_CLIENT = "OAD_SEARCH_CLIENT";
export const RESET_OAD_DD = "RESET_OAD_DD";

export const OD_ALERT_IQC = "OD_ALERT_IQC";
export const OD_ALERT_OQC = "OD_ALERT_OQC";
export const OD_ALERT_RADIOLOGIST = "OD_ALERT_RADIOLOGIST";
export const OD_ALERT_TAT_EWS = "OD_ALERT_TAT_EWS";
export const OD_ALERT_TAT_DEFAULTED = "OD_ALERT_TAT_DEFAULTED";

export const LA_SEARCH_CLIENT='LA_SEARCH_CLIENT'
export const LA_DATE_RANGE='LA_DATE_RANGE'