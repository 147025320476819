import React from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Row,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { AiOutlineMail } from "react-icons/ai";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {
  const history = useHistory();

  const state = useSelector((states) => states);

  const onFinish = (values) => {
    const auth = getAuth();

    try {
      sendPasswordResetEmail(auth, values.email)
        .then((userCredential) => {
          history.push("/login");
          Modal.info({
            title: "Verification mail has been sent to",
            content: values.email,
          });
        })
        .catch((error) => {
          if (error.code === "auth/user-not-found") {
            errorAlert("No account exists for this email. Please sign up.");
          } else if (error.code === "auth/missing-email") {
            errorAlert("No account exists for this email. Please sign up.");
          } else {
            errorAlert("Something went wrong. Please try again.");
          }
        });
    } catch (error) {
      errorAlert("Something went wrong. Please try again.", error);
    }
  };

  const errorAlert = (title, content) => {
    Modal.error({
      title: title,
      content: content,
    });
  };

  if (state.authReducer.isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Layout>
      <Content style={{ backgroundColor: "#eeeeee" }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ minHeight: "100vh" }}
        >
          <Col>
            <Card
              size="default"
              style={{
                width: "360px",
                borderRadius: "4px",
              }}
              align="middle"
            >
              <Form
                name="normal_login"
                align="start"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <p style={{ fontWeight: "800" }}>Reset your password</p>

                <Divider />

                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Email Id!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    prefix={<AiOutlineMail className="site-form-item-icon" />}
                    placeholder="Email Id"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    style={{ width: "100%" }}
                  >
                    Send Verification
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default ForgotPassword;
