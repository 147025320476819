import * as actions from "../actions/index";

const initialState = {
  clientName: null,
  modelDDPayload: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case actions.OAD_DD:
      return {
        ...state,
        modelDDPayload: action.payload,
      };
    case actions.OAD_SEARCH_CLIENT:
      return {
        ...state,
        clientName: action.payload,
      };
    case actions.RESET_OAD_DD:
      return {
        ...state,
        modelDDPayload: {},
      };
    default:
      return state;
  }
};
