import { Card, Col, DatePicker, Row, Typography } from "antd";
import React, { useState } from "react";
import KPIwithTrend from "../../chart/chartComponents/KPIwithTrend";
import {
  churnTabs,
  clientChurnKPIsQuery,
  existingTotalClientQuery,
  filterAccountManagerQuery,
  handleQueryFilters,
} from "./utils";
import CustomTab from "../../chart/utils/CustomTab";
import { useCubeQuery } from "@cubejs-client/react";
import Auxi from "../../chart/utils/Auxi";
import { useDispatch } from "react-redux";
import { handleDateRangeSearch } from "../../redux/actions/churnAction";
import { useSelector } from "react-redux";
import moment from "moment";
import { getAuth } from "firebase/auth";
import Spinner from "../../spinner";

export const churnDateFilterFormat = "YYYY-MM-DD";
const { Title } = Typography;
const { RangePicker } = DatePicker;

const ChurnAnalytics = () => {
  const dispatch = useDispatch();
  const churnReducer = useSelector((state) => state.churnReducer);
  const [switchTab, setSwitchTab] = useState("");
  const currentUserEmail = getAuth().currentUser.email;

  const {
    resultSet: filterAccountManagerQueryData,
    isLoading: filterAccountManagerQueryLoading,
  } = useCubeQuery(filterAccountManagerQuery);
  const currentAMId = (filterAccountManagerQueryData
    ?.rawData()
    ?.filter((data) => data["churn.am_email"] === currentUserEmail)[0] || {
    "churn.am_id": undefined,
  })["churn.am_id"];

  const {
    resultSet: existingClientKPIQueryData,
    isLoading: existingClientKPIQueryLoading,
    error: existingClientKPIQueryError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: existingTotalClientQuery,
      before_date: churnReducer.startDate,
      am_id: currentAMId,
    }),
    { skip: filterAccountManagerQueryLoading || !filterAccountManagerQueryData }
  );

  const {
    resultSet: existingActiveClientKPIQueryData,
    isLoading: existingActiveClientKPIQueryLoading,
    error: existingActiveClientKPIQueryError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: existingTotalClientQuery,
      defaultFilter: [
        {
          member: "churn.churned",
          operator: "equals",
          values: ["0"],
        },
      ],
      before_date: churnReducer.startDate,
      am_id: currentAMId,
    }),
    { skip: filterAccountManagerQueryLoading || !filterAccountManagerQueryData }
  );

  const {
    resultSet: newCientKPIQueryData,
    isLoading: newCientKPIQueryLoading,
    error: newCientKPIQueryError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: existingTotalClientQuery,
      before_date: churnReducer.endDate,
      after_date: churnReducer.startDate,
      am_id: currentAMId,
    }),
    { skip: filterAccountManagerQueryLoading || !filterAccountManagerQueryData }
  );

  const {
    resultSet: clientChurnKPIsQueryData,
    isLoading: clientChurnKPIsQueryLoading,
    error: clientChurnKPIsQueryError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: clientChurnKPIsQuery,
      defaultFilter: [
        {
          member: "churn.churned",
          operator: "equals",
          values: ["1"],
        },
      ],
      last_study_before_date: churnReducer.endDate,
      last_study_after_date: churnReducer.startDate,
      am_id: currentAMId,
    }),
    { skip: filterAccountManagerQueryLoading || !filterAccountManagerQueryData }
  );

  const handleDateRangePicker = (e) => {
    dispatch(
      e?.length
        ? handleDateRangeSearch(
            e[0]?.format(churnDateFilterFormat).toString(),
            e[1]?.format(churnDateFilterFormat).toString()
          )
        : handleDateRangeSearch("", "")
    );
  };

  if (filterAccountManagerQueryLoading || !filterAccountManagerQueryData) {
    return <Spinner tip={"Please Wait"} />;
  }

  // Enable when need of KPI to client list tab

  // const handleExistingClientsValueClick = () => {
  //   dispatch(
  //     handleClientListFilter("", [
  //       {
  //         member: "churn.onboarded_date",
  //         operator: "beforeDate",
  //         values: [churnReducer.startDate],
  //       },
  //     ])
  //   );
  //   switchTab !== "client_list" && setSwitchTab("client_list");
  // };
  // const handleExistingActiveClientsValueClick = () => {
  //   dispatch(
  //     handleClientListFilter("", [
  //       {
  //         member: "churn.onboarded_date",
  //         operator: "beforeDate",
  //         values: [churnReducer.startDate],
  //       },
  //       {
  //         member: "churn.churned",
  //         operator: "equals",
  //         values: ["0"],
  //       },
  //     ])
  //   );
  //   switchTab !== "client_list" && setSwitchTab("client_list");
  // };
  // const handleNewClientsValueClick = () => {
  //   dispatch(
  //     handleClientListFilter("", [
  //       {
  //         member: "churn.onboarded_date",
  //         operator: "beforeDate",
  //         values: [churnReducer.startDate],
  //       },
  //       {
  //         member: "churn.onboarded_date",
  //         operator: "afterDate",
  //         values: [moment(churnReducer.endDate).subtract(1, "days")],
  //       },
  //     ])
  //   );
  //   switchTab !== "client_list" && setSwitchTab("client_list");
  // };
  // const handleChurnValueClick = () => {
  //   dispatch(
  //     handleClientListFilter("", [
  //       {
  //         member: "churn.last_study_date",
  //         operator: "beforeDate",
  //         values: [churnReducer.startDate],
  //       },
  //       {
  //         member: "churn.last_study_date",
  //         operator: "afterDate",
  //         values: [moment(churnReducer.endDate).subtract(1, "days")],
  //       },
  //     ])
  //   );
  //   switchTab !== "client_list" && setSwitchTab("client_list");
  // };
  return (
    <Card>
      <Row style={{ backgroundColor: "#fff" }}>
        <Col xs={0} xl={18}></Col>
        <Col xs={24} xl={6}>
          <Card size="small" bordered={false}>
            <Title level={5}>Date Range</Title>
            <RangePicker
              style={{ width: "100%" }}
              size="large"
              defaultValue={[
                moment(churnReducer.startDate),
                moment(churnReducer.endDate),
              ]}
              onChange={handleDateRangePicker}
              format={churnDateFilterFormat}
              allowClear={false}
            />
          </Card>
        </Col>
      </Row>
      <Card bordered={false} size="small" className="card-plain">
        <Row style={{ width: "100%" }}>
          <Col xs={24} lg={6}>
            <Auxi
              rawData={existingClientKPIQueryData}
              error={existingClientKPIQueryError}
              isLoading={existingClientKPIQueryLoading}
              filterMeasures={["churn.total_clients"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"Existing Clients"}
                      value={existingClientKPIQueryData.totalRow()[series.key]}
                      // onClickListener={handleExistingClientsValueClick}
                    />
                  );
                })
              }
            />
          </Col>
          <Col xs={24} lg={6}>
            <Auxi
              rawData={existingActiveClientKPIQueryData}
              error={existingActiveClientKPIQueryError}
              isLoading={existingActiveClientKPIQueryLoading}
              filterMeasures={["churn.total_clients"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"Existing Active Clients"}
                      value={
                        existingActiveClientKPIQueryData.totalRow()[series.key]
                      }
                      // onClickListener={handleExistingActiveClientsValueClick}
                    />
                  );
                })
              }
            />
          </Col>
          <Col xs={24} lg={6}>
            <Auxi
              rawData={newCientKPIQueryData}
              error={newCientKPIQueryError}
              isLoading={newCientKPIQueryLoading}
              filterMeasures={["churn.total_clients"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"New Clients"}
                      value={newCientKPIQueryData.totalRow()[series.key]}
                      // onClickListener={handleNewClientsValueClick}
                    />
                  );
                })
              }
            />
          </Col>
          <Auxi
            rawData={clientChurnKPIsQueryData}
            error={clientChurnKPIsQueryError}
            isLoading={clientChurnKPIsQueryLoading}
            filterMeasures={[
              "churn.ltv_churn",
              "churn.mrr_churn",
              "churn.new_client_churn",
              "churn.still_born",
              "churn.onboarding_churn",
            ]}
            component={(filterData) =>
              filterData?.map((series, i) => {
                return (
                  <Col xs={24} lg={6} key={`i_${i}`}>
                    <KPIwithTrend
                      title={series.shortTitle}
                      value={clientChurnKPIsQueryData.totalRow()[series.key]}
                      // onClickListener={handleChurnValueClick}
                    />
                  </Col>
                );
              })
            }
          />
        </Row>
      </Card>
      <Card size="small" bordered={false}>
        <CustomTab
          tabData={churnTabs({
            handleTabChange: (value) => setSwitchTab(value),
            amId: currentAMId,
          })}
          defaultTab="overview"
          selectedTab={switchTab}
          setSwitchTab={setSwitchTab}
        />
      </Card>
    </Card>
  );
};

export default ChurnAnalytics;
