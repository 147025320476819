import React, { useRef } from "react";
import { useDeepCompareMemo } from "use-deep-compare";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Empty, Input, Space, Table } from "antd";
import moment from "moment";
import CSVConverter from "../../utility/CSVConverter";

const COLORS_SERIES = [
  "#38b9ff",
  "#ff4938",
  "#2ca02c",
  "#ff7f0e",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#FF9845",
  "#00f7ff",
  "#e35c4f",
];

const formatTableData = (columns, data) => {
  function flatten(columns = []) {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }

      return [...memo, column];
    }, []);
  }

  const typeByIndex = flatten(columns).reduce((memo, column) => {
    return { ...memo, [column.dataIndex]: column };
  }, {});

  function formatValue(value, { type, format } = {}) {
    if (value === undefined) {
      return value;
    }

    if (value === "Studies.pending_studies") {
      return (value = "Count");
    }

    if (type === "boolean") {
      if (typeof value === "boolean") {
        return value.toString();
      } else if (typeof value === "number") {
        return Boolean(value).toString();
      }
      return value;
    }

    if (type === "number" && format === "percent") {
      return [parseFloat(value).toFixed(2), "%"].join("");
    }

    if (type === "time" && Date.parse(value) && value.length > 10) {
      const ft = moment(value).format("D MMM YYYY");
      return ft === "1 Jan 1970" ? value : ft;
    }

    return value?.toString().replace(/{|}|"/g, "");
  }

  function format(row, index) {
    row["key"] = `i_${index}`;
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => {
        return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
      })
    );
  }

  return data.map(format);
};

const CommmonTable = ({
  setTotal,
  title,
  csv = true,
  bordered = false,
  resultSet,
  isLoading,
  searchKeys = [],
  sortStringKeys = [],
  sortNumKeys = [],
  filterKeys = [],
  pagination,
  pivotConfig = {},
  size = "large",
  scrollX = 650,
  filterColumn = (column) => {},
  renderColumn = (column) => {},
  onRowClick = (record, rowIndex) => {},
}) => {
  const searchInput = useRef(null);

  const handleSearch = (confirm) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? COLORS_SERIES[0] : "#808080",
          fontSize: "16px",
          padding: "0px 4px 0px 4px",
          margin: "0px 0px 0px 4px",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const formatTableColumn = (
    columnDatas,
    searchKey,
    sortStringKeys,
    sortNumKeys
  ) => {
    return columnDatas?.reduce((memo, column) => {
      let filters = [];
      let render = [];

      filters = filterColumn(column);

      if (searchKey.indexOf(column.key) >= 0) {
        filters = { ...getColumnSearchProps(column.key) };
      }

      if (sortStringKeys.indexOf(column.key) >= 0) {
        filters = {
          sorter: (a, b) => a[column.key].length - b[column.key].length,
        };
      }

      if (sortNumKeys.indexOf(column.key) >= 0) {
        filters = {
          sorter: (a, b) => a[column.key] - b[column.key],
        };
      }

      render = renderColumn(column);

      filterKeys?.map((item) => {
        if (item.key === column.key) {
          filters = {
            filters: item.filterList,
            onFilter: (value, record) => record[item.key].indexOf(value) === 0,
          };
        }
      });

      if (column.key.endsWith("url")) {
        render = {
          render: (_, data) => (
            <a href={data[column.key]} target="blank">
              {data[column.key]}
            </a>
          ),
        };
      }

      return [
        ...memo,
        {
          ...column,
          title: column.shortTitle,
          ...filters,
          ...render,
        },
      ];
    }, []);
  };

  const [tableColumns, dataSource] = useDeepCompareMemo(() => {
    if (!resultSet || resultSet?.rawData().length === 0) return [];

    const columns = resultSet?.tableColumns(pivotConfig);

    return [
      // columns,
      formatTableColumn(columns, searchKeys, sortStringKeys, sortNumKeys),
      formatTableData(columns, resultSet.tablePivot(pivotConfig)),
    ];
  }, [resultSet, pivotConfig]);

  if (!isLoading && (!resultSet || resultSet?.rawData().length === 0))
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  const handleCSVHeader = (data) => {
    let headers = [];
    data?.map((arr) =>
      headers.push({
        key: arr.key,
        label: arr.shortTitle,
      })
    );
    return headers;
  };

  return (
    <div style={{ textAlign: "end" }}>
      {csv && resultSet && (
        <CSVConverter
          data={resultSet?.tablePivot()}
          headers={handleCSVHeader(resultSet?.tableColumns())}
          filename={
            title
              ? title.replaceAll(" ", "_").toLowerCase() + ".csv"
              : `table_${Math.floor(Date.now() / 1000)}.csv`
          }
        />
      )}
      <Table
        size={size}
        loading={isLoading || !resultSet}
        bordered={bordered}
        columns={tableColumns}
        dataSource={dataSource}
        rowKey={"key"}
        pagination={pagination}
        scroll={{
          x: scrollX,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => onRowClick(record, rowIndex), // click row
          };
        }}
      />
    </div>
  );
};

export default CommmonTable;
