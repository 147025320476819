import * as PERMISSION from "../config/permissions";

import {
  FundViewOutlined,
  DashboardOutlined,
  PieChartOutlined,
  FallOutlined,
  ClockCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";

export const EXECUTIVE_DASHBOARD = "/executive-dashboard";
// readioligst data link

export const RADIOLOGIST_MASTER_DATA = "/radiologist-master-data";

export const EXECUTIVE_DASHBOARD_SUMMARY = "/executive-dashboard-summary";
export const ACCOUNT_MANAGEMENT_DASHBOARD = "/account-management";
export const CENTER_DETAIL_DASHBOARD = "/account-details/:centerId";
export const OPERATIONAL_ANALYTICS_DASHBOARD =
  "/operational-analytics-dashboard";
export const CHURN_DASHBOARD = "/churn-metrics-dashboard";
export const CHURN_ANALYTICS = "/churn-analytics-v2";
export const SALES_ANALYTICS_DASHBOARD = "/sales-analytics-dashboard";
export const SALES_EOD_SUMMARY_DASHBOARD = "/sales-eod-summary";
export const RADIOLOGIST_ANALYTICS_DASHBOARD = "/radiologist-analytics";
export const RAD_PERFORMANCE_DASHBOARD = "/rad-performance";
export const RAD_PERFORMANCE_DRILL_DOWN = "/rad-performance/:radId";
export const TAT_DASHBOARD = "/tat-dashboard";
export const TAT_DASHBOARD_DETAILED = "/tat-dashboard-detail";

export const TAT_METRICS = "/client-tat-metrics";

export const LARGE_ACCOUNTS_DASHBOARD='/large-accounts-dashboard'

//ALERTS
export const ALERT_DASHBOARD = "/alert";
export const ALERT_IQC_DASHBOARD = "/alert/iqc";
export const ALERT_OQC_DASHBOARD = "/alert/oqc";
export const ALERT_RADIOLOGIST_DASHBOARD = "/alert/radiologist";
export const ALERT_TAT_EWS_DASHBOARD = "/alert/tat/ews";
export const ALERT_TAT_DEFAULTED_DASHBOARD = "/alert/tat/defaulted";
// GENWORKS
export const GW_ACCOUNT_MANAGEMENT_DASHBOARD = "/gw-account-details/:centerId";
export const GW_EXECUTIVE_SUMMARY_DASHBOARD = "/gw-executive-summary-dashboard";
export const TABLE_SEARCH_DASHBOARD='/table-search-dashboard';

//whereismycase
export const WHERE_IS_MY_CASE = "/where-is-my-case";


function getItem(
  label,
  title,
  key,
  action,
  keyword,
  icon,
  link,
  type = "MENU",
  badge = null,
  expiry = Date.now(),
  items
) {
  return {
    key,
    icon,
    items,
    label,
    title,
    link,
    type,
    badge,
    expiry,
    action,
    keyword,
  };
}

export const MENU_ITEMS = [
  getItem(
    "Executive Dashboard",
    "Executive Dashboard",
    "1",
    "manage",
    PERMISSION.P_EXECUTIVE_DASHBOARD,
    DashboardOutlined,
    EXECUTIVE_DASHBOARD
  ),
  getItem(
    "Executive Dashboard Summary",
    "ED Summary",
    "2",
    "manage",
    PERMISSION.P_EXECUTIVE_DASHBOARD_SUMMARY,
    DashboardOutlined,
    EXECUTIVE_DASHBOARD_SUMMARY
  ),
  getItem(
    "Operational Analytics",
    "Operational Analytics",
    "3",
    "manage",
    PERMISSION.P_OPERATIONAL_DASHBOARD,
    PieChartOutlined,
    OPERATIONAL_ANALYTICS_DASHBOARD
  ),
  getItem(
    "Account Management",
    "Account Management",
    "4",
    "manage",
    PERMISSION.P_ACCOUNT_MANAGEMENT,
    FundViewOutlined,
    ACCOUNT_MANAGEMENT_DASHBOARD
  ),
  getItem(
    "Churn",
    "Churn Metrics",
    "5",
    "manage",
    PERMISSION.P_CHURN_DASHBOARD,
    FallOutlined,
    CHURN_DASHBOARD
  ),
  getItem(
    "Churn Analytics v2",
    "Churn Analytics v2",
    "7",
    "manage",
    PERMISSION.P_CHURN_ANALYTICS,
    FallOutlined,
    CHURN_ANALYTICS
  ),
  getItem(
    "Rad Performance",
    "Rad Performance Dashboard",
    "8",
    "manage",
    PERMISSION.P_RAD_PERFORMANCE_DASHBOARD,
    TeamOutlined,
    RAD_PERFORMANCE_DASHBOARD
  ),
  getItem(
    "TAT Dashboard",
    "TAT Dashboard",
    "11",
    "manage",
    PERMISSION.P_TAT_DASHBOARD,
    ClockCircleOutlined,
    TAT_DASHBOARD
  ),
  getItem(
    "Radiologist Master Data",
    "Radiologist Master Data",
    "12",
    "manage",
    PERMISSION.P_RADIOLOGIST_MASTER_DATA,
    DashboardOutlined,
    RADIOLOGIST_MASTER_DATA
  ),
  getItem(

    "Client TAT Metrics",
    "Client TAT Metrics",
    "13",
    "manage",
    PERMISSION.P_TAT_METRICS,
    ClockCircleOutlined,
    TAT_METRICS
  ),
  getItem(
    "Large Accounts",
    "Large Accounts",
    "14",
    "manage",
    PERMISSION.P_LARGE_ACCOUNT,
    FundViewOutlined,
    LARGE_ACCOUNTS_DASHBOARD
  ),
  //whereismycase
  getItem(
    "Where is my case?",
    "Where is my case?",
    "15",
    "manage",
    PERMISSION.P_WHERE_IS_MY_CASE,
    DashboardOutlined,
    WHERE_IS_MY_CASE
  ),
  


  // getItem(
  //   "Table Search",
  //   "Table Search",
  //   "14",
  //   "manage",
  //   PERMISSION.TABLE_SEARCH,
  //   FundViewOutlined,
  //   TABLE_SEARCH_DASHBOARD
  // ),
  // getItem(
  //   "Sales Analytics",
  //   "Sales Analytics",
  //   "9",
  //   "manage",
  //   PERMISSION.P_SALES_ANALYTICS_DASHBOARD,
  //   LineChartOutlined,
  //   SALES_ANALYTICS_DASHBOARD
  // ),

  // // getItem("DICOM Anonymiser", "6", FileSearchOutlined, "/dicom-anonymise"),

  // getItem(
  //   "Sales EOD Summary",
  //   "Sales EOD Summary",
  //   "10",
  //   "manage",
  //   PERMISSION.P_SALES_EOD_DASHBOARD,
  //   BoxPlotOutlined,
  //   SALES_EOD_SUMMARY_DASHBOARD
  // ),

  //GENWORKS
  getItem(
    "GenWorks",
    "",
    "",
    "client_manage",
    PERMISSION.GENWORKS,
    "",
    "",
    "SUB_MENU"
  ),
  getItem(
    "Executive Dashboard Summary",
    "GW Executive Dashboard Summary",
    "101",
    "client_manage",
    PERMISSION.GW_EXECUTIVE_SUMMARY_DASHBOARD,
    DashboardOutlined,
    GW_EXECUTIVE_SUMMARY_DASHBOARD
  ),
  getItem(
    "Account Management",
    "GW Account Management",
    "102",
    "client_manage",
    PERMISSION.GW_ACCOUNT_MANAGEMENT_DASHBOARD,
    FundViewOutlined,
    GW_ACCOUNT_MANAGEMENT_DASHBOARD
  ),
];
