import { useCubeQuery } from "@cubejs-client/react";
import { Card, Col, Row, Select, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
  clientListQuery,
  filterAccountManagerQuery,
  filterClientSourceQuery,
  filterOnboardedByQuery,
  filterStateQuery,
} from "../utils";
import CommmonTable from "../../../chart/chartComponents/CommonTable";
import CenterDetailModal from "./CenterDetailModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { handleClearClientListFilter } from "../../../redux/actions/churnAction";
import { Link } from "react-router-dom";

const { Option } = Select;

const ClientListTab = ({ am_id }) => {
  const dispatch = useDispatch();
  const {
    startDate,
    endDate,
    clientListFilter: filterList,
  } = useSelector((state) => state.churnReducer);

  const [clientListFilter, setClientListFilter] = useState({
    start_date: startDate,
    end_date: endDate,
    client_source: undefined,
    am_id: am_id,
    onboarded_by: undefined,
    state: [],
    defaultFilter: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    setClientListFilter({
      ...clientListFilter,
      state: filterList?.state ? [filterList?.state] : [],
      defaultFilter: filterList?.queryFilter,
    });
  }, [filterList]);

  const {
    resultSet: filterClientSourceQueryData,
    isLoading: filterClientSourceQueryLoading,
  } = useCubeQuery(filterClientSourceQuery);
  const {
    resultSet: filterAccountManagerQueryData,
    isLoading: filterAccountManagerQueryLoading,
  } = useCubeQuery(filterAccountManagerQuery);
  const {
    resultSet: filterOnboardedByQueryData,
    isLoading: filterOnboardedByQueryLoading,
  } = useCubeQuery(filterOnboardedByQuery);
  const {
    resultSet: filterStateQueryData,
    isLoading: filterStateQueryLoading,
  } = useCubeQuery(filterStateQuery);
  const { resultSet: clientListQueryData, isLoading: clientListQueryLoading } =
    useCubeQuery(clientListQuery({ ...clientListFilter }));

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
    showModal();
  };

  return (
    <>
      <Card bordered={false} size="small">
        {filterList.name && (
          <>
            <Typography.Text>Applied Filter: </Typography.Text>
            <Tag
              closable
              onClose={() => dispatch(handleClearClientListFilter())}
            >
              {filterList.name}
            </Tag>
            <br />
            &nbsp;
          </>
        )}
        <Row gutter={[16, 8]}>
          <Col xs={0} lg={6}>
            <Typography.Title level={5}>Client Source</Typography.Title>
            <Select
              value={clientListFilter.client_source}
              size="large"
              style={{
                width: "100%",
              }}
              allowClear
              placeholder={"Select Client Source"}
              loading={filterClientSourceQueryLoading}
              onChange={(client_source) =>
                setClientListFilter({
                  ...clientListFilter,
                  client_source,
                  defaultFilter: [],
                })
              }
            >
              {filterClientSourceQueryData?.rawData()?.map((data, index) => (
                <Option value={data["churn.client_source"]} key={`i_${index}`}>
                  {data["churn.client_source"]}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} lg={6}>
            <Typography.Title level={5}>Account Manager</Typography.Title>
            <Select
              value={clientListFilter.am_id}
              size="large"
              style={{
                width: "100%",
              }}
              disabled={!!am_id}
              allowClear
              loading={filterAccountManagerQueryLoading}
              placeholder={"Select Account Manager"}
              onChange={(am_id) =>
                setClientListFilter({
                  ...clientListFilter,
                  am_id,
                  defaultFilter: [],
                })
              }
            >
              {filterAccountManagerQueryData?.rawData()?.map((data, index) => (
                <Option value={data["churn.am_id"]} key={`i_${index}`}>
                  {data["churn.am_name"]}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} lg={6}>
            <Typography.Title level={5}>Onboarded By</Typography.Title>
            <Select
              value={clientListFilter.onboarded_by}
              size="large"
              style={{
                width: "100%",
              }}
              allowClear
              loading={filterOnboardedByQueryLoading}
              placeholder={"Select Onboarded By"}
              onChange={(onboarded_by) =>
                setClientListFilter({
                  ...clientListFilter,
                  onboarded_by,
                  defaultFilter: [],
                })
              }
            >
              {filterOnboardedByQueryData?.rawData()?.map((data, index) => (
                <Option value={data["churn.onboarded_by"]} key={`i_${index}`}>
                  {data["churn.onboarded_by"]}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} lg={6}>
            <Typography.Title level={5}>State</Typography.Title>
            <Select
              mode="multiple"
              allowClear
              value={clientListFilter.state}
              size="large"
              style={{
                width: "100%",
              }}
              loading={filterStateQueryLoading}
              placeholder={"Select State's"}
              onChange={(state) =>
                setClientListFilter({
                  ...clientListFilter,
                  state,
                  defaultFilter: [],
                })
              }
            >
              {filterStateQueryData?.rawData()?.map((data, index) => (
                <Option value={data["churn.state"]} key={`i_${index}`}>
                  {data["churn.state"]}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Card>
      <Card size="small" bordered>
        <CommmonTable
          title={"Client List"}
          pagination={true}
          resultSet={clientListQueryData}
          pivotConfig={{}}
          isLoading={clientListQueryLoading}
          searchKeys={[
            "churn.client_name",
            "study_count.xray_sum",
            "study_count.ct_sum",
            "study_count.mri_sum",
            "study_count.nm_sum",
          ]}
          sortNumKeys={["study_count.studies_sum"]}
          size="middle"
          csv={true}
          scrollX={700}
          bordered
          onRowClick={(record) => {
            handleRowClick(record);
          }}
          renderColumn={(column) => {
            if (column.key === "churn.client_name") {
              return {
                render: (value, row) => (
                  <Link
                    to={`account-details/${row["churn.client_id"]}`}
                    target={"_blank"}
                  >
                    {value}
                  </Link>
                ),
              };
            }
          }}
        />
      </Card>

      {isModalVisible && (
        <CenterDetailModal
          title={selectedRow["churn.client_name"]}
          am_id={selectedRow["churn.client_id"]}
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default ClientListTab;
