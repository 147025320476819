import React from "react";
import "./index.css";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Skeleton, Tooltip } from "antd";
import { validateNumber } from "../utils";

const KPIwithTrend = ({
  title,
  value,
  subValue,
  tooltipText,
  isLoading = false,
  percentageTooltipText,
  trends = [],
  font_size = "35px",
  onClickListener = () => {},
}) => {
  const returnColor = (status) => {
    return status ? (status === "red" ? "#cc0000" : "#00ca00") : "#333333";
  };

  return (
    <Skeleton loading={isLoading} className="card-with-kpi-and-trend">
      <div
        className="card-with-kpi-and-trend"
        onClick={() => onClickListener()}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyItems: "center",
            alignItems: "center",
            textAlign: "center",
            gap: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyItems: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h4>{title}</h4>
            {subValue ? <span>({subValue})</span> : null}
          </div>
          <div>
            {tooltipText ? (
              <Tooltip title={tooltipText}>
                <InfoCircleOutlined />
              </Tooltip>
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            gap: "4px",
          }}
        >
          <h1 style={{ fontSize: font_size }}>{value}</h1>
        </div>
        {trends.length ? (
          trends.map((item, index) => (
            <div className="card-with-kpi-and-trend-child" key={`i_${index}`}>
              {item.value && (
                <h3
                  className={`card-with-kpi-and-trend-child-h3-${
                    item.status ? item.status : "gray"
                  }`}
                >
                  {validateNumber(Math.abs(item.value))}%&nbsp;
                  {item.status ? (
                    item.value > 0 ? (
                      <CaretUpOutlined
                        style={{ color: returnColor(item.status) }}
                      />
                    ) : (
                      <CaretDownOutlined
                        style={{ color: returnColor(item.status) }}
                      />
                    )
                  ) : (
                    ""
                  )}
                </h3>
              )}
              <p>{item.text}</p>
              {percentageTooltipText ? (
                <Tooltip title={percentageTooltipText}>
                  <InfoCircleOutlined />
                </Tooltip>
              ) : null}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </Skeleton>
  );
};

export default KPIwithTrend;
