import cubejs from "@cubejs-client/core";
import { QueryBuilder } from "@cubejs-client/react";
import { Alert, Button, Card, Empty, Input, Layout } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import CommonTable from "../../chart/chartComponents/CommonTable";
import Spinner from "../../spinner";

const DemoPage = () => {
  const [url, setURL] = useState("");
  const [token, setToken] = useState("");

  const [data, setData] = useState("");
  const [demoData, setDemoData] = useState(null);

  return (
    <Layout>
      <Card>
        <Card bordered={false} size="small">
          <Input
            placeholder="Enter API Token (ex.: https:/...)"
            value={token}
            onChange={(e) => setToken(e.currentTarget.value)}
          />
        </Card>
        <Card bordered={false} size="small">
          <Input
            placeholder="Enter URL (ex.: huaUH23....)"
            value={url}
            onChange={(e) => setURL(e.currentTarget.value)}
          />
        </Card>
        <Card bordered={false} size="small">
          <TextArea
            rows={16}
            value={data}
            onChange={(e) => setData(e.currentTarget.value)}
          />
        </Card>
        <Button type="primary" onClick={() => setDemoData(data)}>
          View
        </Button>

        <RenderTable query={demoData} token={token} url={url} />
      </Card>
    </Layout>
  );
};

const RenderTable = ({ url, token, query }) => {
  if (!query || !url || !token) return null;

  return (
    <QueryBuilder
      cubejsApi={cubejs(token, {
        apiUrl: url,
      })}
      query={JSON.parse(query)}
      render={(chartData) => {
        if (!chartData.resultSet) {
          return <Spinner tip={"Please Wait..."} />;
        }

        if (chartData.error) {
          return (
            <Alert
              message="Error"
              description={chartData?.error}
              type="error"
              showIcon
              closable
            />
          );
        }

        return chartData.measures.length > 0 ||
          chartData.dimensions.length > 0 ? (
          <CommonTable
            type="DEMO"
            pagination={true}
            resultSet={chartData.resultSet}
            pivotConfig={{}}
            isLoading={false}
            searchKeys={[]}
            size="middle"
            scrollX={1920}
            csv={false}
            bordered
          />
        ) : (
          <Empty description="Query not loaded! Please Try Again." />
        );
      }}
    />
  );
};

export default DemoPage;
