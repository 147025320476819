import { useCubeQuery } from "@cubejs-client/react";
import React, { useEffect, useState } from "react";
import {
  clientList,
  handleQueryFilters,
  patientList,
  tableData,
  tableTotalCount,
} from "./utils";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Layout,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import WICQueryBuilder from "./utils/WICQueryBuilder";
import { Option } from "antd/lib/mentions";
import Spinner from "../../spinner";
import moment from "moment";
import { LaDateFilterFormat } from "../large-account-dashboard";
import styled from "@emotion/styled";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

const MUITable = (
  columns,
  rows,
  theme,
  AnimatedDataGrid,
  YourComponentWrapper
) => {
  const [mappedData, setMappedData] = useState();
  useEffect(() => {
    if (rows?.loadResponse?.results) {
      const data = rows.loadResponse.results[0].data;

      const mappedData = data.map((item, index) => ({
        id: index,
        hospitalName: item["studies.client_name"],
        patientName: item["studies.patient_name"],
        activatedDate: moment(item["studies.createdAt"]),
        lastAssignedTime: item["studies.modality"],
        currentStatus: item["studies.status"],
        oldPfLink: item["studies.old_pf_link"],
        newPfLink: item["studies.new_pf_link"],
        tatStatus: item["studies.tat_status"],
      }));
      setMappedData(mappedData);
    }
  }, []);

  return (
    <div>
      {mappedData && (
        <YourComponentWrapper>
          <div
            style={{
              height: "768px",
              width: "100%",
              backgroundColor: "#01242d",
            }}
          >
            <ThemeProvider theme={theme}>
              <AnimatedDataGrid rows={mappedData} columns={columns} />
            </ThemeProvider>
          </div>
          {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                      <button onClick={handlePreviousPage}>Previous</button>
                      <button onClick={handleNextPage}>Next</button>
                    </div> */}
        </YourComponentWrapper>
      )}
    </div>
  );
};

function WhereIsMyCase() {
  const { RangePicker } = DatePicker;
  const { Title } = Typography;
  const [pageOffset, setPageOffset] = useState(0);
  const [clientName, setClientName] = useState("");
  const [viewPatientFilter, setViewPatientFilter] = useState(false);
  const [patientName, setPatientName] = useState("");
  const [dateFilter, setDateFilter] = useState("all");
  const [startDate, setStartDate] = useState(moment("2020/01/01").toString());
  const [endDate, setEndDate] = useState(moment().toString());
  const [showCustomDate, setShowCustomDate] = useState(false);

  const HandleClearFilter = () => {
    setPageOffset(0);
    setClientName("");
    setViewPatientFilter(false);
    setPatientName("");
    setDateFilter("all");
    setStartDate(moment("2020/01/01").toString());
    setEndDate(moment().toString());
    showCustomDate(false);
  };

  const YourComponentWrapper = styled("div")(({ theme }) => ({
    tableContainer: {
      maxHeight: 500,
    },
  }));
  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            "& .MuiDataGrid-cell": {
              color: "#33e8e9",
            },
          },
          columnHeader: {
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "#33e8e9",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-iconButton": {
              color: "#33e8e9",
            },
          },
        },
      },
    },
  });
  const AnimatedDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-row": {
      transition: "transform 0.3s",
      "&:hover": {
        transform: "scale(1.08)",
        border: "2px solid #00d5da ",
      },
    },
  }));
  const columns = [
    { field: "", headerName: "", width: 50 },
    {
      field: "studies.client_name",
      headerName: "Hospital Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "studies.patient_name",
      headerName: "Patient Name",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "studies.createdAt",
      headerName: "Case Activated Date",
      flex: 1,
      minWidth: 180,
    },
    {
      field: "studies.modality",
      headerName: "Modality",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "studies.status",
      headerName: "Current Status",
      flex: 1,
      minWidth: 160,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            color: "white",
          }}
        >
          <div
            style={{
              display: "inline-block",
              padding: "4px 8px",
              borderRadius: "4px",
              backgroundColor: getStatusColor(params.value),
            }}
          >
            {params.value}
          </div>
        </div>
      ),
    },
    {
      field: "studies.old_pf_link",
      headerName: "Old PF Link",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          legacy.5cnetwork.com
        </a>
      ),
    },
    {
      field: "studies.new_pf_link",
      headerName: "New PF Link",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          <div
            style={{
              display: "inline-block",
              backgroundColor: "#transparent",
              padding: "5px 10px",
              borderRadius: "4px",
            }}
          >
            <img
              src="https://www.5cnetwork.com/images/5cnetwork--logo.png"
              alt="5C Network Logo"
              width={100}
              height={50}
            />
          </div>
        </a>
      ),
    },
    {
      field: "studies.tat_status",
      headerName: "TAT Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div
          style={{
            display: "inline-block",
            backgroundColor: getStatusColor(params.value),
            color: "#ffffff",
            padding: "5px 10px",
            borderRadius: "4px",
          }}
        >
          {params.value}
        </div>
      ),
    },
  ];

  const handleDateFilterChange = (e) => {
    setPageOffset(0);
    setShowCustomDate(false);
    setPatientName("");
    if (e === "all") {
      setStartDate(moment("2020/01/01").toString());
      setEndDate(moment().toString());
    }
    if (e === "today") {
      setStartDate(moment().toString());
      setEndDate(moment().toString());
    } else if (e === "yesterday") {
      let today = moment().subtract(1, "days").toString();
      setStartDate(today);
      setEndDate(today);
    } else if (e === "this_week") {
      setStartDate(moment().subtract(6, "days").toString());
      setEndDate(moment().toString());
    } else if (e === "this_month") {
      setStartDate(moment().subtract(1, "month").add(1, "day").toString());
      setEndDate(moment().toString());
    } else if (e === "last_month") {
      setStartDate(moment().subtract(2, "months").add(1, "day").toString());
      setEndDate(moment().subtract(1, "month").subtract(1, "days").toString());
    } else if (e === "custom") {
      setShowCustomDate(true);
    }
    setDateFilter(e);
  };
  const handleClientChange = (e) => {
    setClientName(e);
    setPatientName("");
    setPageOffset(0);
    if (e === "") {
      setViewPatientFilter(false);
    } else {
      setViewPatientFilter(true);
    }
  };

  const handleDateRangePicker = (e) => {
    setPatientName("");
    setPageOffset(0);
    setStartDate(moment(e[0]).add(1, "day"));
    setEndDate(moment(e[1]));
  };

  const handlePatientChange = (e) => {
    setPatientName(e);
    setPageOffset(0);
  };
  const getStatusColor = (status) => {
    if (status === "ACTIVATED") {
      return "#FFC107 ";
    } else if (status === "IQC_REVIEW") {
      return "#FF9800 "; // Green color
    } else if (status === "ASSIGNED") {
      return "#03A9F4"; // Blue color
    } else if (status === "OPENED") {
      return "#4CAF50"; // Blue color
    } else if (status === "COMPLETED") {
      return "#9C27B0"; // Blue color
    } else if (status === "In-Progress") {
      return "#1976D2 "; // Blue color
    } else if (status === "On-Time") {
      return "#388E3C "; // Blue color
    } else if (status === "Delayed") {
      return "#FF8F00 "; // Blue color
    }
    return "#795548 "; // Gray color as default
  };
  const {
    resultSet: searchTableContent,
    isLoading: searchTableContentLoading,
    error: searchTableContentError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableData,
      pageOffset: pageOffset,
      clientName: clientName,
      patientName: patientName,
      startDate: startDate,
      endDate: endDate,
    })
  );

  const {
    resultSet: totalCount,
    isLoading: totalCountLoading,
    error: totalCountError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableTotalCount,
      clientName: clientName,
      patientName: patientName,
      startDate: startDate,
      endDate: endDate,
    })
  );

  return (
    <Layout style={{ overflow: "hidden" }}>
      <Card>
        <Row>
          <Col md={12} lg={3}>
            <WICQueryBuilder
              query={clientList}
              render={(chartResult) => (
                <Select
                  showSearch
                  style={{ width: "90%" }}
                  size="large"
                  placeholder="Search for Client"
                  optionFilterProp="children"
                  onChange={handleClientChange}
                  value={clientName}
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      .includes(input?.toLowerCase())
                  }
                >
                  {chartResult.resultSet?.tablePivot()?.map((x) => (
                    <Option
                      key={x["studies.client_name"]}
                      value={x["studies.client_name"]}
                    >
                      {x["studies.client_name"]}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </Col>

          {viewPatientFilter && (
            <Col md={12} lg={3}>
              <WICQueryBuilder
                query={handleQueryFilters({
                  defaultQuery: patientList,
                  clientName: clientName,
                  startDate: startDate,
                  endDate: endDate,
                })}
                render={(chartResult) => (
                  <Select
                    showSearch
                    style={{ width: "90%" }}
                    size="large"
                    placeholder="Patient Name"
                    optionFilterProp="children"
                    onChange={handlePatientChange}
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        .includes(input?.toLowerCase())
                    }
                  >
                    {chartResult.resultSet?.tablePivot()?.map((x) => (
                      <Option
                        key={x["studies.patient_name"]}
                        value={x["studies.patient_name"]}
                      >
                        {x["studies.patient_name"]}
                      </Option>
                    ))}
                  </Select>
                )}
              />
            </Col>
          )}

          <Col md={24} lg={3}>
            <Select
              className="rounded-search-bar"
              showSearch
              style={{ width: "90%" }}
              size="large"
              placeholder="Select Date Filter"
              optionFilterProp="children"
              onChange={handleDateFilterChange}
              value={dateFilter}
            >
              <Option value="all">All Dates</Option>
              <Option value="today">Today</Option>
              <Option value="yesterday">Yesterday</Option>
              <Option value="this_week">This Week</Option>
              <Option value="this_month">This Month</Option>
              <Option value="last_month">Last Month</Option>
              <Option value="custom">Custom Date</Option>
            </Select>
          </Col>
          {showCustomDate && (
            <Col md={24} lg={6}>
              <RangePicker
                style={{ width: "90%" }}
                size="large"
                defaultValue={[moment(), moment()]}
                onChange={handleDateRangePicker}
                format={LaDateFilterFormat}
                allowClear={false}
              />
            </Col>
          )}

          <Col md={24} lg={6}>
            <Button
              onClick={() => {
                HandleClearFilter();
              }}
              size="large"
            >
              Clear Filter
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "8px" }}>
          <Col md={12} lg={3}>
            <Title level={5}>Total Cases</Title>
          </Col>
          {totalCount && (
            <Col md={12} lg={3}>
              <Title level={5}>
                {totalCount.totalRow()["studies.study_count"]}
              </Title>
            </Col>
          )}
        </Row>
      </Card>
      {searchTableContent?.loadResponse?.results[0] ? (
        // columns,rows,theme,AnimatedDataGrid,
        <YourComponentWrapper>
          <div
            style={{
              height: "768px",
              width: "100%",
              backgroundColor: "#01242d",
            }}
          >
            <ThemeProvider theme={theme}>
              <AnimatedDataGrid
                rows={searchTableContent.tablePivot()}
                columns={columns}
                getRowId={(row) =>
                  row["studies.client_name"] +
                  row["studies.patient_name"] +
                  row["studies.createdAt"] +
                  row["studies.modality"]
                }
              />
            </ThemeProvider>
          </div>
          {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                      <button onClick={handlePreviousPage}>Previous</button>
                      <button onClick={handleNextPage}>Next</button>
                    </div> */}
        </YourComponentWrapper>
      ) : (
        <Spinner />
      )}
      <div>
        {totalCount && (
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              disabled={pageOffset > 0 ? false : true}
              onClick={() => {
                setPageOffset(pageOffset - 100);
              }}
            >
              Previous
            </Button>
            <Button disabled style={{ margin: "0 5px" }}>
              {Math.ceil(pageOffset / 100) + 1}
            </Button>
            {/* {(pageOffset+2)<(totalCount.totalRow()['studies.study_count']-10) ? <Button onClick={()=>{setPageOffset(pageOffset+10)}} style={{margin:'0 5px'}}>{Math.ceil(pageOffset/10)+1}</Button>:null}
            {(pageOffset+3)<totalCount.totalRow()['studies.study_count']-20 ? <Button onClick={()=>{setPageOffset(pageOffset+10)}} style={{margin:'0 5px'}}>{Math.ceil(pageOffset/10)+2}</Button>:null} */}
            {/* <Button onClick={()=>{setPageOffset(pageOffset+10)}} style={{margin:'0 5px'}}>{Math.ceil(pageOffset/10)+2}</Button> */}
            {/* <Button onClick={()=>{setPageOffset(pageOffset+20)}} style={{margin:'0 5px'}}>{Math.ceil(pageOffset/10)+3}</Button> */}
            {/* {totalCount&&<Button onClick={()=>{setPageOffset(totalCount.totalRow()['studies.study_count']-20)}}>{Math.ceil(totalCount.totalRow()['studies.study_count']/10)-1}</Button>} */}
            <Button
              Button
              disabled={
                pageOffset < totalCount.totalRow()["studies.study_count"] - 100
                  ? false
                  : true
              }
              onClick={() => {
                setPageOffset(pageOffset + 100);
              }}
            >
              Next
            </Button>
            <Button
              onClick={() => {
                setPageOffset(
                  totalCount.totalRow()["studies.study_count"] - 100
                );
              }}
            >
              Last
            </Button>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default WhereIsMyCase;
