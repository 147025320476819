import React, { useRef } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Layout,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import Auxi from "../../chart/utils/Auxi";
import KPIwithTrend from "../../chart/chartComponents/KPIwithTrend";
import "./index.css";
import { useCubeQuery } from "@cubejs-client/react";
import {
  Tat,
  clientList,
  clientQueryFilter,
  dataBreach_G120,
  dataBreach_G30_L60,
  dataBreach_G60_L120,
  dataBreach_L30,
  groupList,
  handleQueryFilters,
  modalityList,
  qualityCase,
  tableBreachAbNormal,
  tableBreachComplex,
  tableBreachNormal,
  tableCaseNormal,
  tableCases,
  tableCasesAbNormal,
  tableCasesComplex,
  tableCasesNormal,
  tableCritical,
  tableDataBreachNormal,
  tableRework,
  tableTat,
  tatAdhere,
  tatBreach,
  tatExcluded,
  tatOnce,
  totalCases,
  totalReportedStudies,
} from "./utils";
import { useState } from "react";
import Spinner from "../../spinner";
import { LAV1CubeQueryBuilder } from "./utils/LAV1CubeQueryBuilder";
import { handleDateRangeSearch } from "../../redux/actions/laAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import jsPDF from 'jspdf';
export const LaDateFilterFormat = "YYYY-MM-DD";

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const LargeAccountDashboard = () => {
  const dispatch = useDispatch();
  const LargeAccountReducer = useSelector((state) => state.laReducer);
  const [clientId, setClientId] = useState("");
  const [modality, setModality] = useState("");
  const [group,setGroup]=useState("")

  const percent = 100;
  const handleClientChange = (e) => {
    setClientId(e);
  };
  const handleModalityChange = (e) => {
    setModality(e);
  };
  const handleGroupChange=(e)=>{
    setGroup(e)
    if(e===''){
     setClientId("")

    }
  }
  const reportTemplateRef = useRef(null);
  const handleGeneratePdf = () => {
		const doc = new jsPDF({
			format: 'a4',
			unit: 'px',
      orientation: "landscape"
		});

		// Adding the fonts.
		doc.setFont('Inter-Regular', 'normal');

		doc.html(reportTemplateRef.current, {
      html2canvas: {
        scale: [0.5],
    },
			async callback(doc) {
				await doc.save('Large Account Dashboard');
			},
		});
    console.log(reportTemplateRef)
    console.log("Hii")
	};
  const {
    resultSet: laTotalCaseData,
    isLoading: laTotalCaseLoading,
    error: laTotalCaseError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: totalCases,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laReportedStudiesData,
    isLoading: laReportedStudiesLoading,
    error: laReportedStudiesError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: totalReportedStudies,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laQualityCaseData,
    isLoading: laQualityCaseLoading,
    error: laQualityCaseError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: qualityCase,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTatAdhereData,
    isLoading: laTatAdhereLoading,
    error: laTatAdhereError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tatAdhere,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTatBreachData,
    isLoading: laTatBreachLoading,
    error: laTatBreachError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tatBreach,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTatExcludedData,
    isLoading: laTatExcludedLoading,
    error: laTatExcludedError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tatExcluded,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTatOncoData,
    isLoading: laTatOncoLoading,
    error: laTatOncoError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tatOnce,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTat_L30Data,
    isLoading: laTat_L30Loading,
    error: laTat_L30Error,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: dataBreach_L30,
      client_id: clientId,
      modality: modality,
      lt: 30,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTat_G30_L60Data,
    isLoading: laTat_G30_L60Loading,
    error: laTat_G30_L60Error,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: dataBreach_G30_L60,
      client_id: clientId,
      modality: modality,
      gt: 30,
      lt: 60,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTat_G60_L120Data,
    isLoading: laTat_G60_L120Loading,
    error: laTat_G60_L120Error,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: dataBreach_G60_L120,
      client_id: clientId,
      modality: modality,
      gt: 60,
      lt: 120,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTat_G120Data,
    isLoading: laTat_G120Loading,
    error: laTat_G120Error,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: dataBreach_G120,
      client_id: clientId,
      modality: modality,
      gt: 120,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTatData,
    isLoading: laTatLoading,
    error: laTatError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: Tat,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  //CLient List and modality for filters
  const {
    resultSet: laClientData,
    isLoading: laClientLoading,
    error: laClientError,
  } = useCubeQuery(clientList, { subscribe: false });

  const {
    resultSet: laModalityList,
    isLoading: laModalityListLoading,
    error: laModalityListError,
  } = useCubeQuery(modalityList, { subscribe: false });

  const{
    resultSet:laGroupList,
    isLoading:laGroupListLoading,
    error:laGroupListError
  }=useCubeQuery(groupList,{subscribe:false})

  console.log(laGroupList)
  //table data

  const {
    resultSet: laTableTATNormal,
    isLoading: laTableTATNoralLoading,
    error: laTableTATError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableTat,
      client_id: clientId,
      modality: modality,
      complex: "normal",
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTableTATAbNormal,
    isLoading: laTableTATAbNoralLoading,
    error: laTableTATAbError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableTat,
      client_id: clientId,
      modality: modality,
      complex: "abnormal",
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTableTATComplex,
    isLoading: laTableTATComplexLoading,
    error: laTableTATComplexError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableTat,
      client_id: clientId,
      modality: modality,
      complex: "Complex",
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTableCasesNormalData,
    isLoading: laTableCasesNormalLoading,
    error: laTableCasesNormalError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableCasesNormal,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTableCasesAbNormalData,
    isLoading: laTableCasesAbNormalLoading,
    error: laTableCasesAbNormalError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableCasesAbNormal,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTableCasesComplexData,
    isLoading: laTableCasesComplexLoading,
    error: laTableCasesComplexError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableCasesComplex,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTableBreachNormalData,
    isLoading: laTableBreachNormalLoading,
    error: laTableBreachNormalError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableBreachNormal,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTableBreachAbNormalData,
    isLoading: laTableBreachAbNormalLoading,
    error: laTableBreachAbNormalError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableBreachAbNormal,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTableBreachComplexData,
    isLoading: laTableBreachComplexLoading,
    error: laTableBreachComplexError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableBreachComplex,
      client_id: clientId,
      modality: modality,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTableReworkNormalData,
    isLoading: laTableReworkNormalLoading,
    error: laTableReworkNormalError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableRework,
      client_id: clientId,
      modality: modality,
      complex: "normal",
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTableReworkAbnormalData,
    isLoading: laTableReworkAbnormalLoading,
    error: laTableReworkAbnormalError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableRework,
      client_id: clientId,
      modality: modality,
      complex: "abnormal",
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );
  const {
    resultSet: laTableReworkComplexData,
    isLoading: laTableReworkComplexLoading,
    error: laTableReworkComplexError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableRework,
      client_id: clientId,
      modality: modality,
      complex: "complex",
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );

  const {
    resultSet: laTableCriticalData,
    isLoading: laTableCriticalLoading,
    error: laTableCriticalError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: tableCritical,
      client_id: clientId,
      modality: modality,
      critical: true,
      startDate: LargeAccountReducer.startDate,
      endDate: LargeAccountReducer.endDate,
      group:group
    })
  );


  const handleDateRangePicker = (e) => {
    dispatch(
      e?.length
        ? handleDateRangeSearch(
            e[0]?.format(LaDateFilterFormat).toString(),
            e[1]?.format(LaDateFilterFormat).toString()
          )
        : handleDateRangeSearch("", "")
    );
  };
  // TABLE

  const columns = [
    {
      title: "",
      dataIndex: "topic",
    },
    {
      title: "Normal",
      dataIndex: "normal",
    },
    {
      title: "Abnormal",
      dataIndex: "abnormal",
    },
    {
      title: "Complex",
      dataIndex: "complex",
    },
    {
      title: "Critical",
      dataIndex: "critical",
    },
  ];
  const data = [];
  const rowHead = ["Cases", "TAT", "TAT Breach", "Rework"];

  data.push({
    key: 0,
    normal: laTableCasesNormalData ? (
      laTableCasesNormalData?.totalRow()["metrics.normal_total_studies"]
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    abnormal: laTableCasesAbNormalData ? (
      laTableCasesAbNormalData?.totalRow()["metrics.abnormal_total_studies"]
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    complex: laTableCasesComplexData ? (
      laTableCasesComplexData?.totalRow()["metrics.complex_total_studies"]
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    critical: laTableCriticalData ? (
      laTableCriticalData?.totalRow()["metrics.total_studies"]
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    topic: rowHead[0],
  });

  data.push({
    key: 1,
    normal: laTableTATNormal ? (
      laTableTATNormal?.totalRow()["metrics.avg_tat"].toFixed(0) + " mins"
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    abnormal: laTableTATAbNormal ? (
      laTableTATAbNormal?.totalRow()["metrics.avg_tat"].toFixed(0) + " mins"
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    complex: laTableTATComplex ? (
      laTableTATComplex?.totalRow()["metrics.avg_tat"].toFixed(0) + " mins"
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    critical: laTableCriticalData ? (
      laTableCriticalData?.totalRow()["metrics.avg_tat"].toFixed(0) + " mins"
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    topic: rowHead[1],
  });
  data.push({
    key: 2,
    normal: laTableBreachNormalData ? (
      laTableBreachNormalData?.totalRow()["metrics.normal_tat_breach_studies"]
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    abnormal: laTableBreachAbNormalData ? (
      laTableBreachAbNormalData?.totalRow()[
        "metrics.abnormal_tat_breach_studies"
      ]
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    complex: laTableBreachComplexData ? (
      laTableBreachComplexData?.totalRow()["metrics.complex_tat_breach_studies"]
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    critical: laTableCriticalData ? (
      laTableCriticalData?.totalRow()["metrics.tat_breach_count"]
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    topic: rowHead[2],
  });

  data.push({
    key: 3,
    normal: laTableReworkNormalData ? (
      laTableReworkNormalData?.totalRow()["metrics.rework_studies"]
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    abnormal: laTableReworkAbnormalData ? (
      laTableReworkAbnormalData?.totalRow()["metrics.rework_studies"]
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    complex: laTableReworkComplexData ? (
      laTableReworkComplexData?.totalRow()["metrics.rework_studies"]
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    critical: laTableCriticalData ? (
      laTableCriticalData?.totalRow()["metrics.rework_studies"]
    ) : (
      <Spinner tip={"Please Wait..."} />
    ),
    topic: rowHead[3],
  });

  return (
    <Layout style={{ overflow: "hidden" }} >
      <Card>
        <Row>
          <Col md={12} lg={3}>
            <Title level={5} style={{ margin: "0" }}>
              Group
            </Title>
          </Col>
          <Col md={24} lg={6}>
          <Select
              placeholder="Group"
              onChange={(e) => handleGroupChange(e)}
              style={{width:'50%'}}
            >
              {laGroupList?.rawData()?.map((item) => (
                <Option
                  key={item["metrics.group"]}
                  value={item["metrics.group"]}
                >
                  {item["metrics.group"]}
                </Option>
              ))}
              {!laGroupList && <Spin tip={"Loading"} size="large" spinning={true}>
                Loading
              </Spin>}
            </Select>
            {/* <LAV1CubeQueryBuilder
              query={clientList}
              render={(chartResult) => (
                <Select
                  showSearch
                  style={{ width: "90%" }}
                  size="large"
                  placeholder="Search for Client"
                  optionFilterProp="children"
                  onChange={handleClientChange}
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      .includes(input?.toLowerCase())
                  }
                >
                  {chartResult.resultSet?.tablePivot()?.map((x) => (
                    <Option
                      key={x["metrics.client_id"]}
                      value={x["metrics.client_id"]}
                    >
                      {x["metrics.client_name"]}
                    </Option>
                  ))}
                </Select>
              )}
            /> */}
          </Col>

          <Col md={12} lg={3}>
            <Title level={5} style={{ margin: "0" }}>
              Modality
            </Title>
          </Col>
          <Col md={12} lg={3}>
            <Select
              placeholder="Modality"
              onChange={(e) => handleModalityChange(e)}
            >
              {laModalityList?.rawData()?.map((item) => (
                <Option
                  key={item["metrics.modality"]}
                  value={item["metrics.modality"]}
                >
                  {item["metrics.modality"]}
                </Option>
              ))}
            </Select>
          </Col>
          <Col md={12} lg={3}>
            <Title level={5}>Date Range</Title>
          </Col>
          <Col md={24} lg={6}>
            <RangePicker
              style={{ width: "90%" }}
              size="large"
              defaultValue={[
                moment(LargeAccountReducer.startDate),
                moment(LargeAccountReducer.endDate),
              ]}
              onChange={handleDateRangePicker}
              format={LaDateFilterFormat}
              allowClear={false}
            />
          </Col>
        </Row>
        <Row style={{marginTop:'4px'}}>
          <Col md={12} lg={3}>
              <Title level={5}>Client</Title>
          </Col>
          <Col md={24} lg={6}>
              <LAV1CubeQueryBuilder
              query={clientQueryFilter(group)}
              render={(chartResult) => (
                <Select
                  showSearch
                  style={{ width: "90%" }}
                  size="large"
                  placeholder="Search for Client"
                  optionFilterProp="children"
                  onChange={handleClientChange}
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      .includes(input?.toLowerCase())
                  }
                >
                  {chartResult.resultSet?.tablePivot()?.map((x) => (
                    <Option
                      key={x["metrics.client_id"]}
                      value={x["metrics.client_id"]}
                    >
                      {x["metrics.client_name"]}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </Col>
        </Row>
        <Row>
          {laTotalCaseData && <Button onClick={()=>handleGeneratePdf()}>
              Download
            </Button>}
        </Row>
      </Card>

      {/* cards for large data */}
      <Card style={{ margin: "5", backgroundColor: " rgb(249, 249, 249)" }} ref={reportTemplateRef}>
        <Row>
          <Col xs={24} lg={8}>
            <Auxi
              rawData={laTotalCaseData}
              error={laTotalCaseError}
              filterMeasures={["metrics.total_studies"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={
                        <Typography
                          style={{ color: "#02a641", fontSize: 24 }}
                          level={4}
                        >
                          {series.shortTitle}
                        </Typography>
                      }
                      value={laTotalCaseData.totalRow()[series.key]}
                    />
                  );
                })
              }
            />
          </Col>
          <Col xs={24} lg={8}>
            <Auxi
              rawData={laReportedStudiesData}
              error={laReportedStudiesError}
              filterMeasures={["metrics.reported_studies"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={
                        <Typography
                          style={{ color: "#02a641", fontSize: 24 }}
                          level={4}
                        >
                          Reported Case
                        </Typography>
                      }
                      value={laReportedStudiesData.totalRow()[series.key]}
                    />
                  );
                })
              }
            />
          </Col>
          <Col xs={24} lg={8}>
            <Auxi
              rawData={laQualityCaseData}
              error={laQualityCaseError}
              filterMeasures={["metrics.rework_quality"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  let val = 0;
                  if (laQualityCaseData.totalRow()[series.key] != 0) {
                    val =
                      percent -
                      laQualityCaseData.totalRow()[series.key].toFixed(0);
                  }

                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={
                        <Typography
                          style={{ color: "#02a641", fontSize: 24 }}
                          level={4}
                        >
                          Rework
                        </Typography>
                      }
                      value={val + " %"}
                    />
                  );
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={6}>
            <Auxi
              rawData={laTatAdhereData}
              error={laTatAdhereError}
              filterMeasures={["metrics.tat_adherence"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={
                        <Typography
                          style={{ color: "#20b1d5", fontSize: 18 }}
                          level={4}
                        >
                          {series.shortTitle}
                        </Typography>
                      }
                      value={laTatAdhereData.totalRow()[series.key] + " %"}
                    />
                  );
                })
              }
            />
          </Col>
          <Col xs={24} lg={6}>
            <Auxi
              rawData={laTatData}
              error={laTatError}
              filterMeasures={["metrics.avg_tat"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={
                        <Typography
                          style={{ color: "#20b1d5", fontSize: 18 }}
                          level={4}
                        >
                          {series.shortTitle} <br />{" "}
                          <span style={{ fontSize: "16px" }}>In Mins</span>{" "}
                        </Typography>
                      }
                      value={laTatData.totalRow()[series.key].toFixed(0)}
                    />
                  );
                })
              }
            />
          </Col>
          <Col xs={24} lg={6}>
            {/* tat breach */}
            <Auxi
              rawData={laTatBreachData}
              error={laTatBreachError}
              filterMeasures={["metrics.tat_breach_count"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={
                        <Typography
                          style={{ color: "#20b1d5", fontSize: 18 }}
                          level={4}
                        >
                          {series.shortTitle}
                        </Typography>
                      }
                      value={laTatBreachData.totalRow()[series.key]}
                    />
                  );
                })
              }
            />
          </Col>
          {/*TAT once'
           <Col xs={24} lg={6}>
         
            <Auxi
              rawData={laTatOncoData}
              error={laTatOncoError}
              filterMeasures={["metrics.avg_tat_onco"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={<Typography style={{color:'#20b1d5',fontSize:18}} level={4}>{series.shortTitle} <br/> <span style={{fontSize:'16px'}}>In Mins</span> </Typography>}
                      value={laTatOncoData.totalRow()[series.key].toFixed(0)}
                    />
                  );
                })
              }
            />
          </Col> */}
          <Col xs={24} lg={6}>
            <Auxi
              rawData={laTatExcludedData}
              error={laTatExcludedError}
              filterMeasures={["metrics.tat_excluded_studies"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={
                        <Typography
                          style={{ color: "#20b1d5", fontSize: 18 }}
                          level={4}
                        >
                          {series.shortTitle}
                        </Typography>
                      }
                      value={laTatExcludedData.totalRow()[series.key]}
                    />
                  );
                })
              }
            />
          </Col>
        </Row>

        {/* thrid row  */}
        <Row>
          <Col xs={24} lg={6}>
            <Auxi
              rawData={laTat_L30Data}
              error={laTat_L30Error}
              filterMeasures={["metrics.total_studies"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={
                        <Typography
                          style={{ color: "#20b1d5", fontSize: 18 }}
                          level={4}
                        >
                          TAT BREACH <br />{" "}
                          <span
                            className="blue_font"
                            style={{ fontSize: "16px" }}
                          >
                            {" "}
                            Less 30 MIN
                          </span>
                        </Typography>
                      }
                      value={laTat_L30Data.totalRow()[series.key]}
                    />
                  );
                })
              }
            />
          </Col>
          <Col xs={24} lg={6}>
            <Auxi
              rawData={laTat_G30_L60Data}
              error={laTat_G30_L60Error}
              filterMeasures={["metrics.total_studies"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={
                        <Typography
                          style={{ color: "#20b1d5", fontSize: 18 }}
                          level={4}
                        >
                          TAT BREACH <br />{" "}
                          <span style={{ fontSize: "16px" }}>
                            {" "}
                            30 to 60 MIN
                          </span>
                        </Typography>
                      }
                      value={laTat_G30_L60Data.totalRow()[series.key]}
                    />
                  );
                })
              }
            />
          </Col>
          <Col xs={24} lg={6}>
            <Auxi
              rawData={laTat_G60_L120Data}
              error={laTat_G60_L120Error}
              filterMeasures={["metrics.total_studies"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={
                        <Typography
                          style={{ color: "#20b1d5", fontSize: 18 }}
                          level={4}
                        >
                          TAT BREACH <br />{" "}
                          <span style={{ fontSize: "16px" }}>
                            {" "}
                            60 to 120 MIN
                          </span>
                        </Typography>
                      }
                      value={laTat_G60_L120Data.totalRow()[series.key]}
                    />
                  );
                })
              }
            />
          </Col>
          <Col xs={24} lg={6}>
            <Auxi
              rawData={laTat_G120Data}
              error={laTat_G120Error}
              filterMeasures={["metrics.total_studies"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={
                        <Typography style={{ color: "#20b1d5", fontSize: 18 }}>
                          TAT BREACH <br />{" "}
                          <span style={{ fontSize: "16px" }}>
                            {" "}
                            Greater than 120 MIN
                          </span>
                        </Typography>
                      }
                      value={laTat_G120Data.totalRow()[series.key]}
                    />
                  );
                })
              }
            />
          </Col>
        </Row>
      
        <Table
          className="large-acc-table"
          columns={columns}
          dataSource={data}
          rowClassName={"table-row-light"}
          pagination={false}
        />
      </Card>
    </Layout>
  );
};

export default LargeAccountDashboard;
