import cubejs from "@cubejs-client/core";
import OverviewTab from "../helper/OverviewTab";
import TatBreachTab from "../helper/TatBreachTab";
import moment from "moment";

export const tatDashboardCubejsApi = cubejs(
  process.env.REACT_APP_CUBEJS_TAT_DASHBOARD_AUTH_TOKEN,
  {
    apiUrl: process.env.REACT_APP_CUBEJS_TAT_DASHBOARD_URL,
  }
);

export const toHHMMSS = (sec) => {
  var sec_num = parseInt(sec, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

export const handleQueryFilters = ({
  defaultQuery,
  tableName = "PhaseLevelTat",
  defaultFilter = [],
  before_date,
  after_date,
}) => {
  let filters = defaultFilter;

  if (before_date) {
    filters.push({
      member: tableName + ".completed_at",
      operator: "beforeDate",
      values: [before_date],
    });
  }

  if (after_date) {
    filters.push({
      member: tableName + ".completed_at",
      operator: "afterDate",
      values: [moment(after_date).subtract(1, "days")],
    });
  }

  if (filters.length) {
    return { ...defaultQuery, filters };
  }
  return defaultQuery;
};

export const avgTATQuery = {
  measures: [
    "PhaseLevelTat.avg_overall_tat_cross",
    "PhaseLevelTat.avg_rework_tat_cross",
    "PhaseLevelTat.avg_normal_tat_cross",
    "PhaseLevelTat.avg_assignment_tat_cross",
    "PhaseLevelTat.avg_iqc_tat_cross",
    "PhaseLevelTat.avg_radiologist_tat_cross",
    "PhaseLevelTat.avg_oqc_tat_cross",
    "PhaseLevelTat.avg_overall_tat_xray",
    "PhaseLevelTat.avg_rework_tat_xray",
    "PhaseLevelTat.avg_normal_tat_xray",
    "PhaseLevelTat.avg_assignment_tat_xray",
    "PhaseLevelTat.avg_radiologist_tat_xray",
  ],
};

export const casesTATQuery = {
  limit: 10,
  dimensions: [
    "PhaseLevelTat.modality",
    "PhaseLevelTat.organ",
    "PhaseLevelTat.client_name",
    "PhaseLevelTat.start_time",
    "PhaseLevelTat.end_time",
    "PhaseLevelTat.type",
    "PhaseLevelTat.critical_type",
    "PhaseLevelTat.link",
    "PhaseLevelTat.iqc_tat",
    "PhaseLevelTat.oqc_tat",
    "PhaseLevelTat.assignment_tat",
    "PhaseLevelTat.radiologist_tat",
    "PhaseLevelTat.rework_tat",
  ],
  measures: ["PhaseLevelTat.tat_difference"],
  order: {
    "PhaseLevelTat.tat_difference": "desc",
  },
};

export const organTATQuery = {
  limit: 5,
  measures: [
    "PhaseLevelTat.organ_iqc_tat",
    "PhaseLevelTat.organ_oqc_tat",
    "PhaseLevelTat.organ_radiologist_tat",
    "PhaseLevelTat.organ_assignment_tat",
    "PhaseLevelTat.organ_rework_tat",
    "PhaseLevelTat.tat_difference",
  ],
  dimensions: ["PhaseLevelTat.modality", "PhaseLevelTat.organ"],
  order: [["PhaseLevelTat.tat_difference", "desc"]],
};

export const overallTATQuery = {
  measures: [
    "PhaseLevelTat.within_tat",
    "PhaseLevelTat.tat_breach",
    "PhaseLevelTat.with_rework",
    "PhaseLevelTat.without_rework",
    "PhaseLevelTat.within_tat_per",
    "PhaseLevelTat.tat_breach_per",
    "PhaseLevelTat.with_rework_per",
    "PhaseLevelTat.without_rework_per",
  ],
  dimensions: ["PhaseLevelTat.modality"],
  order: [["PhaseLevelTat.modality", "desc"]],
};

export const hourlyTATQuery = {
  order: [["PhaseLevelTat.hr", "asc"]],
  measures: [
    "PhaseLevelTat.case_load_percentage",
    "PhaseLevelTat.tat_breach_percentage",
  ],
  dimensions: ["PhaseLevelTat.hr"],
};

export const monthlyTATQuery = {
  measures: [
    "PhaseLevelTat.tat_breach_percentage",
    "PhaseLevelTat.case_load_percentage",
  ],
  order: [["PhaseLevelTat.day", "asc"]],
  dimensions: ["PhaseLevelTat.day"],
};

export const monthlyModalityTATQuery = {
  measures: ["PhaseLevelTat.XRAY", "PhaseLevelTat.CT", "PhaseLevelTat.MRI"],
  dimensions: ["PhaseLevelTat.day"],
  order: [["PhaseLevelTat.day", "asc"]],
};

export const hourlyModalityTATQuery = {
  measures: ["PhaseLevelTat.XRAY", "PhaseLevelTat.CT", "PhaseLevelTat.MRI"],
  dimensions: ["PhaseLevelTat.hr"],
  order: [["PhaseLevelTat.hr", "asc"]],
};

export const xrayTopOrganQuery = {
  limit: 5,
  measures: ["PhaseLevelTat.organ_avg"],
  order: {
    "PhaseLevelTat.organ_avg": "desc",
  },
  dimensions: ["PhaseLevelTat.modality", "PhaseLevelTat.organ"],
};

export const mriTopOrganQuery = {
  limit: 5,
  measures: ["PhaseLevelTat.organ_avg"],
  order: {
    "PhaseLevelTat.organ_avg": "desc",
  },
  dimensions: ["PhaseLevelTat.modality", "PhaseLevelTat.organ"],
};

export const ctTopOrganQuery = {
  limit: 5,
  measures: ["PhaseLevelTat.organ_avg"],
  order: {
    "PhaseLevelTat.organ_avg": "desc",
  },
  dimensions: ["PhaseLevelTat.modality", "PhaseLevelTat.organ"],
};

export const caseTypeQuery = {
  measures: [
    "PhaseLevelTat.overall",
    "PhaseLevelTat.normal_per",
    "PhaseLevelTat.abnormal_per",
    "PhaseLevelTat.complex_per",
    "PhaseLevelTat.critical_per",
  ],
  dimensions: ["PhaseLevelTat.modality"],
};

export const studyLevelTat = {
  measures: [
    "tat_chart.IQC_sec",
    "tat_chart.OQC_sec",
    "tat_chart.Assignment_sec",
    "tat_chart.Rad_sec",
    "tat_chart.patient_name",
    "tat_chart.client_name",
    "tat_chart.createdAt",
    "tat_chart.overall_tat",
    "tat_chart.modality",
  ],
  limit: 100,
  dimensions: ["tat_chart.studyId"],
  order: [
    ["tat_chart.overall_tat", "desc"],
  ],
};

export const tatDashboardTabs = () => {
  return [
    {
      label: "Overview",
      key: "overview",
      children: <OverviewTab />,
    },
    {
      label: "Breached Studies",
      key: "breached_studies",
      children: <TatBreachTab />,
    },
  ];
};
