import React from "react";
import { Surface, Symbols } from "recharts";

const CustomLegend = ({ payload }) => {
  return payload.map((entry, index) => {
    const { value, color } = entry;

    return (
      <span
        key={`i_${index}`}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Surface
          width={12}
          height={12}
          viewBox={{ x: 0, y: 0, width: 10, height: 10 }}
        >
          <Symbols cx={5} cy={5} type="circle" size={150} fill={color} />
        </Surface>
        <p style={{ fontSize: "14px", margin: 0 }}>&nbsp;&nbsp;{value}</p>
      </span>
    );
  });
};

export default CustomLegend;
