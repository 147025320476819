import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { navigationRoutes } from "../../router/Navigation";
import Can from "../Can/Can";

const RedirectPage = () => {
  const [url, setURL] = useState("");
  useEffect(() => {
    setURL(
      navigationRoutes.filter((data) => {
        return Can("manage", data.key) && data.url;
      })[0]?.url
    );
  }, []);

  return <Redirect to={url} exact />;
};

export default RedirectPage;
