import moment from "moment";
import * as actions from ".";
import { tatDateFilterFormat } from "../../views/tat-dashboard";

export const handleDateRangeSearch = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: actions.TAT_DATE_RANGE,
      startDate: moment(startDate).format(tatDateFilterFormat).toString(),
      endDate: moment(endDate).format(tatDateFilterFormat).toString(),
    });
  };
};


  
