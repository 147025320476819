import cubejs from "@cubejs-client/core";


export const tatMetricsCubejsApi = cubejs(
  process.env.REACT_APP_CUBEJS_TAT_DASHBOARD_AUTH_TOKEN,
  {
    apiUrl: process.env.REACT_APP_CUBEJS_TAT_DASHBOARD_URL,
  }
);



export const searchClientQuery = {
  "dimensions": [

    "optimised.group_client_id",
    "optimised.is_group",
    "optimised.group_client_name"
  ],
  "order": {
    "optimised.group_client_name": "asc"
  },
}


export const handleSearchClientQuery = (group) => {
  let filters = []
  if (group === 'All') {
    filters.push({
      member: 'optimised.is_group',
      operator: "contains",
      values: ["Group", "Individual"],
    });
  } else if (group === 'Group') {
    filters.push({
      member: 'optimised.is_group',
      operator: "contains",
      values: ["Group"],
    });
  }
  else if (group === 'Individual') {
    filters.push({
      member: 'optimised.is_group',
      operator: "contains",
      values: ["Individual"],
    });
  }

  return { ...searchClientQuery, filters }

}

export const studyLevelTatQuery = {
  "dimensions": [
    "optimised.group_client_name",
    "optimised.study_id",
    "optimised.modality",
    "optimised.category",
    "optimised.completed_datetime",
    "optimised.created_datetime",
    "optimised.tat_min",
    "optimised.has_rework",
    "optimised.patient_name",
    "optimised.created_date",
    "optimised.time_elapsed",
    "optimised.is_irad",
    "optimised.provisional_tat_min",
    "optimised.assignment_type",

  ],
  "order": {
    "optimised.group_client_name": "asc"
  }
}


export const TatMetricsQuery = {
  "order": [
    [
      "optimised.modality",
      "asc"
    ],

    [
      "optimised.category",
      "asc"
    ]
  ],
  "dimensions": [
    "optimised.modality",
    "optimised.category",
  ],
  "measures": [
    "optimised.hr_zero_to_one",
    "optimised.no_of_cases",
    "optimised.hr_one_to_two",
    "optimised.hr_two_to_three",
    "optimised.hr_three_to_four",
    "optimised.hr_four_to_six",
    "optimised.hr_six_to_eight",
    "optimised.hr_eight_to_twelve",
    "optimised.hr_twelve_to_twenty_four",
    "optimised.hr_over_twenty_four",

  ]
}

export const handleStudyQueryTat = (startDate, endDate, clientName, dayShift, organ, hour, modality, options, activeTime) => {

  let filters = []
  if (startDate) {
    filters.push({
      member: 'optimised.created_date',
      operator: "afterDate",
      values: [startDate],
    });
  }
  if (endDate) {
    filters.push({
      member: "optimised.created_date",
      operator: "beforeDate",
      values: [
        endDate
      ]
    });
  }
  if (clientName && clientName !== 'Search By Client') {
    filters.push({
      member: 'optimised.group_client_id',
      operator: "equals",
      values: [clientName],
    });
  }

  if (dayShift === '') {
    filters.push({
      member: 'optimised.created_hr',
      operator: "gte",
      values: [0],
    })
    filters.push({
      member: 'optimised.created_hr',
      operator: "lte",
      values: [23],
    })
  } else if (dayShift === 'day') {
    filters.push({
      member: 'optimised.created_hr',
      operator: "gte",
      values: [8],
    })
    filters.push({
      member: 'optimised.created_hr',
      operator: "lt",
      values: [22],
    })
  }
  else if (dayShift === 'night') {

    filters.push({
      or: [
        {
          dimension: 'optimised.created_hr',
          operator: 'gte',
          values: [22],
        },
        {
          dimension: 'optimised.created_hr',
          operator: 'lt',
          values: [8],
        }],
    })
  } else if (dayShift === 'custom') {
    const start = parseInt(activeTime[0].split(':')[0])
    const end = parseInt(activeTime[1].split(':')[0])
    filters.push({
      member: 'optimised.created_hr',
      operator: "gte",
      values: [start],
    })
    filters.push({
      member: 'optimised.created_hr',
      operator: "lte",
      values: [end],
    })
  }

  //  if(modalitySelect.length===0){
  //   filters.push({
  //     member: 'optimised.modality',
  //     operator: "contains",
  //     values: ['CT','MRI','NM','XRAY'],
  //    })
  //  }else{
  //   filters.push({
  //     member: 'optimised.modality',
  //     operator: "contains",
  //     values: modalitySelect,
  //    })

  //  }
  if (modality) {
    filters.push({
      member: 'optimised.modality',
      operator: "equals",
      values: [modality],
    })
  }

  if (organ && organ !== 'All') {
    console.log(organ, 'category')
    filters.push({
      member: 'optimised.category',
      operator: "equals",
      values: [organ],
    })
  }
  console.log(organ, 'category')
  console.log(hour)

  if (hour === 'optimised.hr_zero_to_one') {
    filters.push({
      member: 'optimised.tat_min',
      operator: "gt",
      values: [0],
    })
    filters.push({
      member: 'optimised.tat_min',
      operator: "lte",
      values: [60],
    })
  } else if (hour === 'optimised.hr_one_to_two') {
    filters.push({
      member: 'optimised.tat_min',
      operator: "gt",
      values: [60],
    })
    filters.push({
      member: 'optimised.tat_min',
      operator: "lte",
      values: [120],
    })
  } else if (hour === 'optimised.hr_two_to_three') {
    filters.push({
      member: 'optimised.tat_min',
      operator: "gt",
      values: [120],
    })
    filters.push({
      member: 'optimised.tat_min',
      operator: "lte",
      values: [180],
    })
  }
  else if (hour === 'optimised.hr_three_to_four') {
    filters.push({
      member: 'optimised.tat_min',
      operator: "gt",
      values: [180],
    })
    filters.push({
      member: 'optimised.tat_min',
      operator: "lte",
      values: [240],
    })
  }
  else if (hour === 'optimised.hr_four_to_six') {
    filters.push({
      member: 'optimised.tat_min',
      operator: "gt",
      values: [240],
    })
    filters.push({
      member: 'optimised.tat_min',
      operator: "lte",
      values: [360],
    })

  } else if (hour === 'optimised.hr_six_to_eight') {
    filters.push({
      member: 'optimised.tat_min',
      operator: "gt",
      values: [360],
    })
    filters.push({
      member: 'optimised.tat_min',
      operator: "lte",
      values: [480],
    })

  }
  else if (hour === 'optimised.hr_eight_to_twelve') {
    filters.push({
      member: 'optimised.tat_min',
      operator: "gt",
      values: [480],
    })
    filters.push({
      member: 'optimised.tat_min',
      operator: "lte",
      values: [720],
    })

  }
  else if (hour === 'optimised.hr_twelve_to_twenty_four') {
    filters.push({
      member: 'optimised.tat_min',
      operator: "gt",
      values: [720],
    })
    filters.push({
      member: 'optimised.tat_min',
      operator: "lte",
      values: [1440],
    })

  }
  else if (hour === 'optimised.hr_over_twenty_four') {
    filters.push({
      member: 'optimised.tat_min',
      operator: "gt",
      values: [1440],
    })


  }

  if (options === 'All') {
    filters.push({
      member: 'optimised.is_group',
      operator: "contains",
      values: ["Group", "Individual"],
    })

  } else if (options === 'Group') {
    filters.push({
      member: 'optimised.is_group',
      operator: "contains",
      values: ["Group"],
    })
  } else if (options === 'Individual') {
    filters.push({
      member: 'optimised.is_group',
      operator: "contains",
      values: ["Individual"],
    })
  }

  //  if(emergency==='All'){
  //   filters.push({
  //     member: 'optimised.is_critical',
  //     operator: "contains",
  //     values: ['Regular','Emergency'],
  //    })
  //  }else if(emergency==='Critical'){
  //    filters.push({
  //     member: 'optimised.is_critical',
  //     operator: "contains",
  //     values: ['Emergency'],
  //    })
  //  }
  //  else if(emergency==='Regular'){
  //   filters.push({
  //    member: 'optimised.is_critical',
  //    operator: "contains",
  //    values: ['Regular'],
  //   })
  // }

  return { ...studyLevelTatQuery, filters }
}

export const handleQueryTat = (startDate, endDate, clientName, dayShift, modalitySelect, options, activeTime) => {

  let filters = []
  if (startDate) {
    filters.push({
      member: 'optimised.created_date',
      operator: "afterDate",
      values: [startDate],
    });
  }
  if (endDate) {
    filters.push({
      member: "optimised.created_date",
      operator: "beforeDate",
      values: [
        endDate
      ]
    });
  }
  if (clientName && clientName !== 'Search By Client') {
    filters.push({
      member: 'optimised.group_client_id',
      operator: "equals",
      values: [clientName],
    });
  }
  if (dayShift === '') {
    filters.push({
      member: 'optimised.created_hr',
      operator: "gte",
      values: [0],
    })
    filters.push({
      member: 'optimised.created_hr',
      operator: "lte",
      values: [23],
    })
  } else if (dayShift === 'day') {
    filters.push({
      member: 'optimised.created_hr',
      operator: "gte",
      values: [8],
    })
    filters.push({
      member: 'optimised.created_hr',
      operator: "lt",
      values: [22],
    })
  }
  else if (dayShift === 'night') {

    filters.push({
      or: [
        {
          dimension: 'optimised.created_hr',
          operator: 'gte',
          values: [22],
        },
        {
          dimension: 'optimised.created_hr',
          operator: 'lt',
          values: [8],
        }],
    })
  } else if (dayShift === 'custom') {
    const start = parseInt(activeTime[0].split(':')[0])
    const end = parseInt(activeTime[1].split(':')[0])
    filters.push({
      member: 'optimised.created_hr',
      operator: "gte",
      values: [start],
    })
    filters.push({
      member: 'optimised.created_hr',
      operator: "lte",
      values: [end],
    })
  }
  //  if(modalitySelect.length===0){
  //   filters.push({
  //     member: 'optimised.modality',
  //     operator: "contains",
  //     values: ['CT','MRI','NM','XRAY'],
  //    })
  //  }else{
  //   filters.push({
  //     member: 'optimised.modality',
  //     operator: "contains",
  //     values: modalitySelect,
  //    })
  //  }
  if (options === 'All') {
    filters.push({
      member: 'optimised.is_group',
      operator: "contains",
      values: ["Group", "Individual"],
    })

  } else if (options === 'Group') {
    filters.push({
      member: 'optimised.is_group',
      operator: "contains",
      values: ["Group"],
    })
  } else if (options === 'Individual') {
    filters.push({
      member: 'optimised.is_group',
      operator: "contains",
      values: ["Individual"],
    })
  }


  return { ...TatMetricsQuery, filters }
}


