import { Modal } from "antd";
import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import posthog from "posthog-js";
import * as actions from ".";

export const handleLogin = (data) => {
  return {
    type: actions.AUTH_LOGIN,
    payload: data,
  };
};

export const handleLogout = () => {
  return {
    type: actions.AUTH_LOGOUT,
  };
};

export const handleAuthVerifyStart = () => {
  return {
    type: actions.AUTH_VERIFY_START,
  };
};

export const handleVerifyLogin = () => {
  return (dispatch) => {
    dispatch({
      type: actions.AUTH_VERIFY,
    });
  };
};

export const handleAuthStart = () => {
  return (dispatch) => {
    dispatch({
      type: actions.AUTH_REQUEST,
    });
  };
};

export const handleAuthError = (title, content) => {
  Modal.error({
    title: title,
    content: content,
  });
  return {
    type: actions.AUTH_FAILED,
  };
};

export const authLogin = (data) => (dispatch) => {
  dispatch(handleAuthStart());
  try {
    setPersistence(getAuth(), browserLocalPersistence)
      .then(async () => {
        try {
          const userCredential = await signInWithEmailAndPassword(
            getAuth(),
            data.email?.trim(),
            data.password
          );

          if (!window.location.href.includes("http://localhost")) {
            posthog.people.set({ email: data.email.toLowerCase() });
          }
          dispatch(handleLogin(userCredential.user));
        } catch (error) {
          if (error.code === "auth/user-not-found") {
            dispatch(
              handleAuthError(
                "No account exists for this email. Please sign up."
              )
            );
          } else if (error.code === "auth/missing-email") {
            dispatch(
              handleAuthError(
                "No account exists for this email. Please sign up."
              )
            );
          } else if (error.code === "auth/wrong-password") {
            dispatch(
              handleAuthError("You have entered an incorrect password.")
            );
          } else if (error.code === "auth/too-many-requests") {
            dispatch(
              handleAuthError(
                "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
              )
            );
          } else {
            dispatch(
              handleAuthError("Something went wrong. Please try again.", error)
            );
          }
        }
      })
      .catch((error) => {
        dispatch(
          handleAuthError("Something went wrong. Please try again.", error)
        );
      });
  } catch (error) {
    dispatch(handleAuthError("Something went wrong. Please try again.", error));
  }
};

export const authLogout = () => (dispatch) => {
  try {
    signOut(getAuth())
      .then(() => {
        return dispatch(handleLogout());
      })
      .catch((error) => {
        dispatch(
          handleAuthError("Something went wrong. Please try again.", error)
        );
      });
  } catch (error) {
    dispatch(handleAuthError("Something went wrong. Please try again.", error));
  }
};

export const authVerify = () => (dispatch) => {
  dispatch(handleAuthVerifyStart());
  onAuthStateChanged(getAuth(), (user) => {
    if (getAuth().currentUser?.email) {
      dispatch(handleLogin(user));
    } else {
      dispatch(handleLogout());
    }
  });
};
