import cubejs from "@cubejs-client/core";
import moment from "moment";

export const largeAccountCubejsApi = cubejs(
  process.env.REACT_APP_CUBEJS_LARGE_ACCOUNT_AUTH_TOKEN,
  {
    apiUrl: process.env.REACT_APP_CUBEJS_LARGE_ACCOUNT_URL,
  }
);

var filters = [
  {
    member: "metrics.client_id",
    operator: "equals",
    values: ["15"],
  },
  {
    member: "metrics.modality",
    operator: "equals",
    values: ["CT"],
  },
  {
    member: "metrics.date",
    operator: "afterDate",
    values: ["2023-06-01"],
  },
  {
    member: "metrics.date",
    operator: "beforeDate",
    values: ["2023-06-23"],
  },
];

export const totalCases = {
  measures: ["metrics.total_studies"],
  filters: filters,
  timeDimensions: [
    {
      dimension: "metrics.createdAt",
    },
  ],
  order: {},
};

export const totalReportedStudies = {
  measures: ["metrics.reported_studies"],
  filters: filters,
  timeDimensions: [
    {
      dimension: "metrics.createdAt",
    },
  ],
  order: {},
};
export const qualityCase = {
  measures: ["metrics.rework_quality"],
  filters: filters,
  timeDimensions: [
    {
      dimension: "metrics.createdAt",
    },
  ],
  order: {},
};

export const tatAdhere = {
  measures: ["metrics.tat_adherence"],
  order: {},
  filters: filters,
};

export const tatBreach = {
  measures: ["metrics.tat_breach_count"],
  order: {
    "metrics.createdAt": "asc",
  },
  filters: filters,
};

export const tatExcluded = {
  measures: ["metrics.tat_excluded_studies"],
  order: {
    "metrics.createdAt": "asc",
  },
  filters: filters,
};

export const tatOnce = {
  measures: ["metrics.avg_tat_onco"],
  filters: filters,
  order: {},
  timeDimensions: [
    {
      dimension: "metrics.createdAt",
    },
  ],
};

export const dataBreach_L30 = {
  measures: ["metrics.total_studies"],
  filters: [
    {
      member: "metrics.client_id",
      operator: "equals",
      values: ["15"],
    },
    {
      member: "metrics.modality",
      operator: "equals",
      values: ["CT"],
    },

    {
      member: "metrics.date",
      operator: "afterDate",
      values: ["2023-06-01"],
    },
    {
      member: "metrics.date",
      operator: "beforeDate",
      values: ["2023-06-23"],
    },
    {
      member: "metrics.tat_exceeded_mins",
      operator: "lte",
      values: ["30"],
    },
  ],
  order: {
    "metrics.createdAt": "asc",
  },
};

export const dataBreach_G30_L60 = {
  measures: ["metrics.total_studies"],
  filters: [
    {
      member: "metrics.client_id",
      operator: "equals",
      values: ["15"],
    },
    {
      member: "metrics.modality",
      operator: "equals",
      values: ["CT"],
    },
    {
      member: "metrics.date",
      operator: "afterDate",
      values: ["2023-06-01"],
    },
    {
      member: "metrics.date",
      operator: "beforeDate",
      values: ["2023-06-24"],
    },
    {
      member: "metrics.tat_exceeded_mins",
      operator: "gte",
      values: ["30"],
    },
    {
      member: "metrics.tat_exceeded_mins",
      operator: "lt",
      values: ["60"],
    },
    {
      member: "metrics.tat_breach",
      operator: "equals",
      values: ["1"],
    },
  ],
  order: {
    "metrics.createdAt": "asc",
  },
};

export const dataBreach_G60_L120 = {
  measures: ["metrics.total_studies"],
  filters: [
    {
      member: "metrics.client_id",
      operator: "equals",
      values: ["15"],
    },
    {
      member: "metrics.modality",
      operator: "equals",
      values: ["CT"],
    },
    {
      member: "metrics.date",
      operator: "afterDate",
      values: ["2023-06-01"],
    },
    {
      member: "metrics.date",
      operator: "beforeDate",
      values: ["2023-06-23"],
    },
    {
      member: "metrics.tat_exceeded_mins",
      operator: "gte",
      values: ["60"],
    },
    {
      member: "metrics.tat_exceeded_mins",
      operator: "lt",
      values: ["120"],
    },
    {
      member: "metrics.tat_breach",
      operator: "equals",
      values: ["1"],
    },
  ],
  order: {
    "metrics.createdAt": "asc",
  },
};

export const dataBreach_G120 = {
  measures: ["metrics.total_studies"],
  filters: [
    {
      member: "metrics.client_id",
      operator: "equals",
      values: ["15"],
    },
    {
      member: "metrics.modality",
      operator: "equals",
      values: ["CT"],
    },
    {
      member: "metrics.date",
      operator: "afterDate",
      values: ["2023-06-01"],
    },
    {
      member: "metrics.date",
      operator: "beforeDate",
      values: ["2023-06-24"],
    },
    {
      member: "metrics.tat_exceeded_mins",
      operator: "gte",
      values: ["120"],
    },
    {
      member: "metrics.tat_breach",
      operator: "equals",
      values: ["1"],
    },
  ],
  order: {
    "metrics.createdAt": "asc",
  },
};

export const clientList = {
  dimensions: ["metrics.client_id", "metrics.client_name"],
  order: {
    "metrics.client_name": "asc",
  },
};

export const clientQueryFilter=(value)=>{
  const clientListQuery={
    dimensions: ["metrics.client_id", "metrics.client_name"],
    filters:[{
      member:'metrics.group',
      operator:"equals",
      values:[value.toString()]
    }],
    order: {
      "metrics.client_name": "asc",
    },
  }
  return clientListQuery
}

export const modalityList = {
  dimensions: ["metrics.modality"],
};

export const groupList={
  "dimensions": [
    "metrics.group"
  ]
}

export const Tat = {
  measures: ["metrics.avg_tat"],
  filters: filters,
};

export const tableTat = {
  measures: ["metrics.avg_tat"],
  filters: [
    {
      member: "metrics.modality",
      operator: "equals",
      values: ["CT"],
    },
    {
      member: "metrics.client_id",
      operator: "equals",
      values: ["15"],
    },
    {
      member: "metrics.date",
      operator: "afterDate",
      values: ["2023-06-01"],
    },
    {
      member: "metrics.date",
      operator: "beforeDate",
      values: ["2023-06-23"],
    },
    {
      member: "metrics.complexity",
      operator: "equals",
      values: ["normal"],
    },
  ],
  order: {
    "metrics.createdAt": "asc",
  },
};

export const tableCasesNormal = {
  measures: ["metrics.normal_total_studies"],
  filters: filters,
};
export const tableCasesComplex = {
  measures: ["metrics.complex_total_studies"],
  filters: filters,
};
export const tableCasesAbNormal = {
  measures: ["metrics.abnormal_total_studies"],
  filters: filters,
};

export const tableBreachNormal = {
  measures: ["metrics.normal_tat_breach_studies"],
  filters: filters,
  order: {
    "metrics.createdAt": "asc",
  },
};
export const tableBreachAbNormal = {
  measures: ["metrics.abnormal_tat_breach_studies"],
  filters: filters,
  order: {
    "metrics.createdAt": "asc",
  },
};
export const tableBreachComplex = {
  measures: ["metrics.complex_tat_breach_studies"],
  filters: filters,
  order: {
    "metrics.createdAt": "asc",
  },
};

export const tableRework = {
  measures: ["metrics.rework_studies"],
  filters: [
    ...filters,
    {
      member: "metrics.complexity",
      operator: "equals",
      values: ["normal"],
    },
  ],
  order: {
    "metrics.createdAt": "asc",
  },
};

export const tableCritical = {
  measures: [
    "metrics.total_studies",
    "metrics.tat_breach_count",
    "metrics.avg_tat",
    "metrics.rework_studies",
  ],
  filters: [
    {
      member: "metrics.modality",
      operator: "equals",
      values: ["CT"],
    },
    {
      member: "metrics.client_id",
      operator: "equals",
      values: ["15"],
    },
    {
      member: "metrics.date",
      operator: "afterDate",
      values: ["2023-06-01"],
    },
    {
      member: "metrics.date",
      operator: "beforeDate",
      values: ["2023-06-23"],
    },
    {
      member: "metrics.critical",
      operator: "equals",
      values: ["0"],
    },
  ],
  order: {},
};

export const handleQueryFilters = ({
  defaultQuery,
  metrics = "metrics",
  defaultFilter = [],
  client_id,
  modality,
  complex,
  gt,
  lt,
  critical,
  startDate,
  endDate,
  group
}) => {
  let filters = defaultFilter;

  if (client_id) {
    filters[filters.length] = {
      member: metrics + ".client_id",
      operator: "equals",
      values: [client_id.toString()],
    };
  }
  if (modality) {
    filters[filters.length] = {
      member: metrics + ".modality",
      operator: "equals",
      values: [modality.toString()],
    };
  }

  if (complex) {
    filters[filters.length] = {
      member: "metrics.complexity",
      operator: "equals",
      values: [complex.toString()],
    };
  }
  if (lt) {
    filters[filters.length] = {
      member: "metrics.tat_exceeded_mins",
      operator: "lte",
      values: [lt.toString()],
    };
  }
  if (gt) {
    filters[filters.length] = {
      member: "metrics.tat_exceeded_mins",
      operator: "gt",
      values: [gt.toString()],
    };
  }

  if (critical) {
    filters[filters.length] = {
      member: "metrics.critical",
      operator: "equals",
      values: ["0"],
    };
  }
  if (startDate) {
    filters[filters.length] = {
      member: "metrics.date",
      operator: "afterDate",
      values: [moment(startDate).subtract(1, "days")],
    };
  }
  if (endDate) {
    filters[filters.length] = {
      member: "metrics.date",
      operator: "beforeDate",
      values: [moment(endDate).add(1, "days")],
    };
  }
  if(group)
  {
    filters[filters.length]={
      member:'metrics.group',
      operator:"equals",
      values:[group.toString()]
    }
  }


  if (filters.length) {
    return { ...defaultQuery, filters };
  }
  return defaultQuery;
};
