export const P_ACCOUNT_MANAGEMENT = "ACCOUNT_MANAGEMENT";
export const P_CENTER_DETAIL_DASHBOARD = "CENTER_DETAIL_DASHBOARD";
export const P_EXECUTIVE_DASHBOARD = "EXECUTIVE_DASHBOARD";
export const P_EXECUTIVE_DASHBOARD_SUMMARY = "EXECUTIVE_DASHBOARD_SUMMARY";
export const P_OPERATIONAL_DASHBOARD = "OPERATIONAL_DASHBOARD";
export const P_CHURN_DASHBOARD = "CHURN_DASHBOARD";
export const P_CHURN_ANALYTICS = "CHURN_ANALYTICS_V2";
export const P_SALES_ANALYTICS_DASHBOARD = "SALES_ANALYTICS_DASHBOARD";
export const P_SALES_EOD_DASHBOARD = "SALES_EOD_DASHBOARD";
export const P_RADIOLOGIST_ANALYTICS_DASHBOARD ="RADIOLOGIST_ANALYTICS_DASHBOARD";
export const P_RAD_PERFORMANCE_DASHBOARD = "RAD_PERFORMANCE_DASHBOARD";
export const P_RAD_PERFORMANCE_DRILL_DOWN = "RAD_PERFORMANCE_DRILL_DOWN";
export const P_TAT_DASHBOARD = "TAT_DASHBOARD";
export const P_TAT_DASHBOARD_DETAILS = "TAT_DASHBOARD_DETAILS";

export const P_TAT_METRICS = "TAT_METRICS";

export const P_LARGE_ACCOUNT="LARGE_ACCOUNT";

// radiologit master data

export const P_RADIOLOGIST_MASTER_DATA = "RADIOLOGIST_MASTER_DATA";

// ALERTS
export const A_ALERT_DASHBOARD = "A_ALERT_DASHBOARD";
export const A_IQC_ALERT_DASHBOARD = "A_IQC_ALERT_DASHBOARD";
export const A_OQC_ALERT_DASHBOARD = "A_OQC_ALERT_DASHBOARD";
export const A_RADIOLOGIST_ALERT_DASHBOARD = "A_RADIOLOGIST_ALERT_DASHBOARD";
export const A_TAT_EWS_ALERT_DASHBOARD = "A_TAT_EWS_ALERT_DASHBOARD";
export const A_TAT_DEFAULTED_ALERT_DASHBOARD =
  "A_TAT_DEFAULTED_ALERT_DASHBOARD";

// OPERATIONAL DASHBOARD
//
export const C_OPD_STUDIES = "STUDIES";
export const C_OPD_ASSIGNMENTS = "ASSIGNMENTS";
export const C_OPD_ASSIGNED = "ASSIGNED";
export const C_OPD_CLIENT_REJECT = "CLIENT_REJECT";
//
export const C_OPD_PENDING_ORGAN = "PENDING_ORGAN";
//
export const C_OPD_IQC = "IQC";
export const C_OPD_OLD_PENDING_CASES = "OLD_PENDING_CASES";
export const C_OPD_RADIOLOGIST = "RADIOLOGIST";
export const C_OPD_ATTENTION_REQUEST = "ATTENTION_REQUEST";
export const C_OPD_OQC = "OQC";
export const C_OPD_REWORK = "REWORK";

//GENWORKS
export const GENWORKS = "GENWORKS";
export const GW_ACCOUNT_MANAGEMENT_DASHBOARD = "ACCOUNT_MANAGEMENT_DASHBOARD";
export const GW_EXECUTIVE_SUMMARY_DASHBOARD = "EXECUTIVE_SUMMARY_DASHBOARD";


//
export const TABLE_SEARCH="TABLE_SEARCH";
//whereismycase
export const P_WHERE_IS_MY_CASE = "WHERE_IS_MY_CASE";

