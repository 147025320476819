import { useCubeQuery } from "@cubejs-client/react";
import { Card, Col, Modal, Row, Select, Typography } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CommmonTable from "../../../chart/chartComponents/CommonTable";
import {
  dauClientModelQuery,
  mauClientModelQuery,
  wauClientModelQuery,
} from "../utils";

const { Title } = Typography;
const { Option } = Select;

const returnFilteredQuery = ({
  query,
  tableName,
  accountId,
  clientType,
  accountType,
}) => {
  query["filters"] = [];

  query["filters"] = [
    {
      member: tableName + ".am_id",
      operator: "equals",
      values: [accountId.toString()],
    },
  ];
  if (accountType) {
    if (query["filters"]) {
      query["filters"] = [
        ...query["filters"],
        {
          member: tableName + ".flag",
          operator: "equals",
          values: [accountType.toString()],
        },
      ];
    } else {
      query["filters"] = [
        {
          member: tableName + ".flag",
          operator: "equals",
          values: [accountType.toString()],
        },
      ];
    }
  }
  if (clientType) {
    if (query["filters"]) {
      query["filters"] = [
        ...query["filters"],
        {
          member: tableName + ".client_segment",
          operator: "equals",
          values: [clientType.toString()],
        },
      ];
    } else {
      query["filters"] = [
        {
          member: tableName + ".client_segment",
          operator: "equals",
          values: [clientType.toString()],
        },
      ];
    }
  }

  return query;
};

const returnQuery = (type) => {
  switch (type) {
    case "DAU":
      return dauClientModelQuery;
    case "WAU":
      return wauClientModelQuery;
    case "MAU":
      return mauClientModelQuery;
    default:
      return {};
  }
};

const OverviewModel = ({
  isModalOpen,
  handleOk,
  handleCancel,
  modalTitle,
  clientType,
}) => {
  const accountId = useSelector((state) => state.amReducer.accountId);

  const [accountType, setAccountType] = useState("Diminishing");
  const [activeType, setActiveType] = useState("DAU");
  const {
    resultSet: modelData,
    isLoading: modelLoading,
    error: modelError,
    refetch: modelRefetch,
  } = useCubeQuery(
    returnFilteredQuery({
      query: returnQuery(activeType),
      accountId: accountId,
      clientType: clientType,
      tableName: activeType.toLowerCase(),
      accountType: accountType,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );

  const handleAccountTypeOnChange = (value) => {
    setAccountType(value);
    modelRefetch();
  };

  return (
    <Modal
      width={"75%"}
      title={modalTitle}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Row align="middle">
        <Col md={24} lg={12}>
          <Title level={5}>Choose a account type</Title>
        </Col>
        <Col md={12} lg={6}>
          <Select
            defaultValue={accountType}
            size="large"
            style={{
              width: "100%",
            }}
            onChange={handleAccountTypeOnChange}
          >
            <Option value="Diminishing">Diminishing Account</Option>
            <Option value="Growing">Growing Account</Option>
            <Option value="Stable">Stable Account</Option>
          </Select>
        </Col>
        <Col md={12} lg={6}>
          <Select
            defaultValue={activeType}
            size="large"
            style={{
              width: "100%",
            }}
            onChange={setActiveType}
          >
            <Option value="DAU">DAU</Option>
            <Option value="WAU">WAU</Option>
            <Option value="MAU">MAU</Option>
          </Select>
        </Col>
      </Row>

      <Card size="small" bordered={false} loading={!modelData}>
        {modelData && (
          <CommmonTable
            type="MODEL_CLIENTS"
            pagination={modelData?.rawData().length >= 10}
            resultSet={modelData}
            pivotConfig={{}}
            isLoading={modelLoading || modelError}
            searchKeys={[]}
            size="middle"
            scrollX={700}
            csv={true}
            bordered
          />
        )}
      </Card>
    </Modal>
  );
};

export default OverviewModel;
