import cubejs from "@cubejs-client/core";
import ColdStartLoss from "../helpers/alert-tab/ColdStartLoss";
import DAUNegative from "../helpers/alert-tab/DAUNegative";
import DAUtoWAU from "../helpers/alert-tab/DAUtoWAU";
import NewClientNurturing from "../helpers/alert-tab/NewClientNurturing";
import ReactivatedNurturing from "../helpers/alert-tab/ReactivatedNurturing";
import WAUNegative from "../helpers/alert-tab/WAUNegative";
import WAUtoMAU from "../helpers/alert-tab/WAUtoMAU";
import AlertsDashboard from "../helpers/AlertsDashboard";
import OverviewDashboard from "../helpers/OverviewDashboard";

export const accountManagementV2CubejsApi = cubejs(
  process.env.REACT_APP_CUBEJS_ACCOUNT_MANAGEMENT_AUTH_TOKEN,
  {
    apiUrl: process.env.REACT_APP_CUBEJS_ACCOUNT_MANAGEMENT_URL,
  }
);

export const returnQueryOnlyWithID = ({
  query,
  accountId,
  tableName,
  accountType,
}) => {
  if (!accountId) return {};

  query["filters"] = [
    {
      member: tableName + ".am_id",
      operator: "equals",
      values: [accountId.toString()],
    },
  ];

  return query;
};

export const returnQueryWithID = ({
  query,
  accountId,
  tableName,
  accountType,
}) => {
  if (!accountId) return {};

  if (query["filters"]) {
    query["filters"] = [
      ...query["filters"],
      {
        member: tableName + ".am_id",
        operator: "equals",
        values: [accountId.toString()],
      },
    ];
  } else {
    query["filters"] = [
      {
        member: tableName + ".am_id",
        operator: "equals",
        values: [accountId.toString()],
      },
    ];
  }
  // query["filters"] = [
  //   {
  //     member: tableName + ".am_id",
  //     operator: "equals",
  //     values: [accountId.toString()],
  //   },
  // ];

  // if (query["filters"]) {
  //   query["filters"] = [
  //     ...query["filters"],
  //     {
  //       member: tableName + ".flag",
  //       operator: "equals",
  //       values: [accountType.toString()],
  //     },
  //   ];
  // } else {
  //   query["filters"] = [
  //     {
  //       member: tableName + ".flag",
  //       operator: "equals",
  //       values: [accountType.toString()],
  //     },
  //   ];
  // }

  return query;
};

export const dashboardTabs = [
  {
    label: "Overview",
    key: "overview",
    children: <OverviewDashboard />,
  },
  {
    label: "Alerts",
    key: "alerts",
    children: <AlertsDashboard />,
  },
];

export const alertsTabs = [
  {
    label: "DAU to WAU Accounts",
    key: "dau-to-wau",
    children: <DAUtoWAU />,
  },
  {
    label: "WAU to MAU Accounts",
    key: "wau-to-mau",
    children: <WAUtoMAU />,
  },
  // {
  //   label: "Churn / Cold start / Onboarding loss",
  //   key: "cold-and-onboarding-loss",
  //   children: <ColdStartLoss />,
  // },
  {
    label: "DAU Diminishing Accounts",
    key: "dau-diminishing",
    children: <DAUNegative />,
  },
  {
    label: "WAU Diminishing Accounts",
    key: "wau-diminishing",
    children: <WAUNegative />,
  },
  {
    label: "Reactivated Nurturing",
    key: "reactivated",
    children: <ReactivatedNurturing />,
  },
  {
    label: "Onboarded Client Nurturing",
    key: "onboarded",
    children: <NewClientNurturing />,
  },
];

export const dauTableQuery = {
  measures: [
    "dau.dau_new",
    "dau.dau_new_lw",
    "dau.dau_mrr",
    "dau.dau_mrr_lw",
    "dau.dau_ltv",
    "dau.dau_ltv_lw",
  ],
};

export const wauTableQuery = {
  measures: [
    "wau.wau_new",
    "wau.wau_new_lw",
    "wau.wau_mrr",
    "wau.wau_mrr_lw",
    "wau.wau_ltv",
    "wau.wau_ltv_lw",
  ],
};

export const mauTableQuery = {
  measures: [
    "mau.mau_new",
    "mau.mau_new_lw",
    "mau.mau_mrr",
    "mau.mau_mrr_lw",
    "mau.mau_ltv",
    "mau.mau_ltv_lw",
  ],
};

export const amDetailsQuery = {
  dimensions: ["am_details.am_id", "am_details.am_name"],
  order: {
    "am_details.am_id": "asc",
  },
};

// =================== OVERVIEW ================

// KPI

export const totalAccountKPIQuery = {
  measures: [
    "clients.total_clients",
    "clients.total_clients_last_30d",
    "clients.total_client_pcentage",
    "clients.new_clients",
    "clients.new_clients_last_month",
    "clients.new_clients_comparision",
    "clients.mrr_clients",
    "clients.mrr_clients_last_month",
    "clients.mrr_cleints_comparision",
    "clients.ltv_clients",
    "clients.ltv_clients_last_month",
    "clients.ltv_clients_comparision",
  ],
};

export const onboardingClientsKPIQuery = {
  measures: [
    "clients.onboarded_clients",
    "clients.onboarded_clients_last_month",
    "clients.onboarded_clients_comparision",
  ],
};

export const reactivatedClientsKPIQuery = {
  measures: [
    "reactivated_clients.total_clients",
    "reactivated_clients.last_month_clients",
    "reactivated_clients.last_month_comparision",
  ],
};

export const dauKPIQuery = {
  measures: [
    "dau.dau_total",
    "dau.last_month_dau_total",
    "dau.lastmonth_comparision",
  ],
  order: {
    "comparision_last_month.dau_count": "asc",
  },
};

export const wauKPIQuery = {
  measures: [
    "wau.wau_total",
    "wau.last_month_wau_total",
    "wau.lastmonth_comparision",
  ],
  order: {
    "comparision_last_month.dau_count": "asc",
  },
};

export const mauKPIQuery = {
  measures: [
    "mau.mau_total",
    "mau.last_month_mau_total",
    "mau.lastmonth_comparision",
  ],
  order: {
    "comparision_last_month.dau_count": "asc",
  },
};

// NEW CLIENT

export const newClientColdStartKPIQuery = {
  measures: ["cold_start_loss.cold_start_loss"],
};

export const newClientOnboardingKPIQuery = {
  measures: ["onboarding_loss.onboarding_loss"],
};

export const newClientColdStartDrillDownQuery = {
  dimensions: ["cold_start_loss.client_name"],
  order: {
    "cold_start_loss.client_name": "asc",
  },
};

export const newClientOnboardingDrillDownQuery = {
  dimensions: ["onboarding_loss.client_name"],
  order: {
    "onboarding_loss.client_name": "asc",
  },
};

export const newClientDAUQuery = {
  measures: ["dau.growing_new_clients", "dau.diminishing_new_clients"],
  order: {
    "dau.date": "asc",
  },
};

export const newClientWAUQuery = {
  measures: ["wau.growing_new_clients", "wau.diminishing_new_clients"],
  order: {
    "wau.date": "asc",
  },
};

export const newClientMAUQuery = {
  measures: ["mau.growing_new_clients", "mau.diminishing_new_clients"],
  order: {
    "mau.date": "asc",
  },
};

// MRR

export const mrrChurnKPIQuery = {
  measures: ["mrr_churn.mrr_churn"],
};

export const mrrChurnDrillDownQuery = {
  dimensions: ["mrr_churn.client_name"],
  order: {
    "mrr_churn.client_name": "asc",
  },
};

export const mrrDAUQuery = {
  measures: ["dau.growing_mrr_clients", "dau.diminishing_mrr_clients"],
  order: {
    "dau.date": "asc",
  },
};

export const mrrWAUQuery = {
  measures: ["wau.growing_mrr_clients", "wau.diminishing_mrr_clients"],
  order: {
    "wau.date": "asc",
  },
};

export const mrrMAUQuery = {
  measures: ["mau.growing_mrr_clients", "mau.diminishing_mrr_clients"],
  order: {
    "mau.date": "asc",
  },
};

// LTV

export const ltvChurnKPIQuery = {
  measures: ["ltv_churn.ltv_churn"],
};

export const ltvChurnDrillDownQuery = {
  dimensions: ["ltv_churn.client_name"],
  order: {
    "mrr_churn.client_name": "asc",
  },
};

export const ltvDAUQuery = {
  measures: ["dau.growing_ltv_clients", "dau.diminishing_ltv_clients"],
  order: {
    "dau.date": "asc",
  },
};

export const ltvWAUQuery = {
  measures: ["wau.growing_ltv_clients", "wau.diminishing_ltv_clients"],
  order: {
    "wau.date": "asc",
  },
};

export const ltvMAUQuery = {
  measures: ["mau.growing_ltv_clients", "mau.diminishing_ltv_clients"],
  order: {
    "mau.date": "asc",
  },
};

//

export const reactivatedKPIQuery = {
  measures: ["reactivated.reactivated_clients"],
};

export const reactivatedDrillDownQuery = {
  dimensions: [
    "reactivated_clients.client_name",
    "reactivated_clients.recent_study_date",
    "reactivated_clients.last_study_date",
  ],
  order: {
    "study_count.date": "asc",
  },
  measures: ["study_count.studies_sum"],
  filters: [
    {
      member: "reactivated_clients.tag",
      operator: "equals",
      values: ["Reactivated"],
    },
  ],
};

export const onboardingKPIQuery = {
  measures: ["onboarded.onboarded_clients"],
};

export const onboardingDrillDownQuery = {
  dimensions: [
    "onboarded_clients_list.client_name",
    "onboarded_clients_list.total_studies",
  ],
  order: {
    "onboarded_clients_list.client_id": "asc",
  },
};
// MODEL

export const dauClientModelQuery = {
  measures: [
    "study_count.previous_1st_week_studies",
    "study_count.previous_2nd_week_studies",
    "study_count.previous_3rd_week_studies",
    "study_count.previous_4th_week_studies",
  ],
  dimensions: ["clients.client_name"],
  order: {
    "study_count.previous_1st_week_studies": "desc",
  },
};

export const wauClientModelQuery = {
  measures: [
    "study_count.previous_1st_week_studies",
    "study_count.previous_2nd_week_studies",
    "study_count.previous_3rd_week_studies",
    "study_count.previous_4th_week_studies",
  ],
  dimensions: ["clients.client_name"],
  order: {
    "study_count.previous_1st_week_studies": "desc",
  },
};

export const mauClientModelQuery = {
  measures: [
    "study_count.previous_1st_month_studies",
    "study_count.previous_2nd_month_studies",
    "study_count.previous_3rd_month_studies",
  ],
  dimensions: ["clients.client_name"],
  order: {
    "study_count.previous_1st_month_studies": "desc",
  },
};

// =================== ALERTS ================

export const dauTowauAccountQuery = {
  dimensions: [
    "dau_to_wau.client_id",
    "dau_to_wau.client_name",
    "dau_to_wau.flag",
    "dau_to_wau.client_segment",
    "dau_to_wau.tag",
    "dau_to_wau.am_id",
    // "dau_to_wau.wau",
  ],
  order: {
    "dau_to_wau.client_id": "asc",
  },
  // filters: [
  //   {
  //     member: "dau_to_wau.am_id",
  //     operator: "equals",
  //     values: ["144"],
  //   },
  // ],
};

export const wauTodauAccountQuery = {
  dimensions: [
    "wau_to_mau.client_id",
    "wau_to_mau.client_name",
    "wau_to_mau.flag",
    "wau_to_mau.client_segment",
    "wau_to_mau.tag",
    "wau_to_mau.am_id",
    // "wau_to_mau.mau",
  ],
  order: {
    "wau_to_mau.client_name": "asc",
  },
};

export const coldStartLossQuery = {
  dimensions: ["cold_start_loss.client_id", "cold_start_loss.client_name"],
  order: {
    "cold_start_loss.client_name": "asc",
  },
};

export const onboardingLossQuery = {
  dimensions: ["onboarding_loss.client_id", "onboarding_loss.client_name"],
  order: {
    "onboarding_loss.client_name": "asc",
  },
};

export const dauNegativeAccountQuery = {
  dimensions: [
    "clients.client_id",
    "clients.client_name",
    "dau.client_segment",
    "dau.tag",
    "dau.flag",
  ],
  order: {
    "dau.client_id": "asc",
  },
  filters: [
    {
      member: "dau.flag",
      operator: "equals",
      values: ["Diminishing"],
    },
    {
      member: "dau.tag",
      operator: "equals",
      values: ["DAU"],
    },
  ],
};

export const wauNegativeAccountQuery = {
  dimensions: [
    "clients.client_id",
    "clients.client_name",
    "wau.client_segment",
    "wau.tag",
    "wau.flag",
  ],
  order: {
    "wau.client_id": "asc",
  },
  filters: [
    {
      member: "wau.flag",
      operator: "equals",
      values: ["Diminishing"],
    },
    {
      member: "wau.tag",
      operator: "equals",
      values: ["WAU"],
    },
  ],
};

export const reactivatedNurturingQuery = {
  dimensions: ["reactivated_clients.client_name", "reactivated_clients.tag"],
  order: {
    "study_count.studies_sum": "desc",
  },
  filters: [
    {
      member: "reactivated_clients.tag",
      operator: "equals",
      values: ["Reactivated"],
    },
  ],
};

export const newClientNurturingQuery = {
  dimensions: ["clients.client_name", "clients.client_segment"],
  order: {
    "clients.client_name": "asc",
  },
  filters: [
    {
      member: "clients.client_segment",
      operator: "equals",
      values: ["Onboarded Client"],
    },
  ],
};
