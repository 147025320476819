import React, { useRef } from "react";
import MultiLineChart from "../../../chart/chartComponents/MultiLineChart";
import { Button, Card, Input, Space, Table, Typography } from "antd";
import { useCubeQuery } from "@cubejs-client/react";
import {
  churnOverTimeChartQuery,
  handleQueryFilters,
  overviewTableChurnQuery,
  overviewTableExistingActiveClientQuery,
  overviewTableExistingClientQuery,
  overviewTableNewClientQuery,
} from "../utils";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";
import { handleClientListFilter } from "../../../redux/actions/churnAction";
import { churnDateFilterFormat } from "..";
import { SearchOutlined } from "@ant-design/icons";
import { COLORS_SERIES } from "../../../chart/utils";

const OverviewTab = ({ handleTabChange, am_id }) => {
  const dispatch = useDispatch();
  const churnDate = useSelector((state) => state.churnReducer);
  const searchInput = useRef(null);

  const {
    resultSet: overviewTableExistingClientQueryData,
    isLoading: overviewTableExistingClientQueryLoading,
    error: overviewTableExistingClientQueryError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: overviewTableExistingClientQuery,
      before_date: churnDate.startDate,
      am_id,
    })
  );

  const {
    resultSet: overviewTableExistingActiveClientQueryData,
    isLoading: overviewTableExistingActiveClientQueryLoading,
    error: overviewTableExistingActiveClientQueryError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: overviewTableExistingActiveClientQuery,
      defaultFilter: [
        {
          member: "churn.churned",
          operator: "equals",
          values: ["0"],
        },
      ],
      before_date: churnDate.startDate,
      am_id,
    })
  );

  const {
    resultSet: overviewTableNewClientQueryData,
    isLoading: overviewTableNewClientQueryLoading,
    error: overviewTableNewClientQueryError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: overviewTableNewClientQuery,
      before_date: churnDate.endDate,
      after_date: churnDate.startDate,
      am_id,
    })
  );

  const {
    resultSet: overviewTableChurnQueryData,
    isLoading: overviewTableChurnQueryLoading,
    error: overviewTableChurnQueryError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: overviewTableChurnQuery,
      defaultFilter: [
        {
          member: "churn.churned",
          operator: "equals",
          values: ["1"],
        },
      ],
      last_study_before_date: churnDate.endDate,
      last_study_after_date: churnDate.startDate,
      am_id,
    })
  );

  const {
    resultSet: churnOverTimeChartQueryData,
    isLoading: churnOverTimeChartQueryLoading,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: churnOverTimeChartQuery,
      defaultFilter: [
        {
          member: "churn.year",
          operator: "gte",
          values: ["2021"],
        },
      ],
      am_id,
    })
  );

  if (
    overviewTableExistingClientQueryError ||
    overviewTableExistingActiveClientQueryError ||
    overviewTableNewClientQueryError ||
    overviewTableChurnQueryError
  ) {
    return <></>;
  }

  const handleExistingClientsValueClick = (row) => {
    dispatch(
      handleClientListFilter("Existing Clients", row["churn.state"], [
        {
          member: "churn.onboarded_date",
          operator: "beforeDate",
          values: [
            moment(churnDate.startDate)
              .format(churnDateFilterFormat)
              .toString(),
          ],
        },
      ])
    );
    handleTabChange("client_list");
  };
  const handleExistingActiveClientsValueClick = (row) => {
    dispatch(
      handleClientListFilter("Existing Active Clients", row["churn.state"], [
        {
          member: "churn.churned",
          operator: "equals",
          values: ["0"],
        },
        {
          member: "churn.onboarded_date",
          operator: "beforeDate",
          values: [
            moment(churnDate.startDate)
              .format(churnDateFilterFormat)
              .toString(),
          ],
        },
      ])
    );
    handleTabChange("client_list");
  };
  const handleNewClientsValueClick = (row) => {
    dispatch(
      handleClientListFilter("New Clients", row["churn.state"], [
        {
          member: "churn.onboarded_date",
          operator: "beforeDate",
          values: [
            moment(churnDate.endDate).format(churnDateFilterFormat).toString(),
          ],
        },
        {
          member: "churn.onboarded_date",
          operator: "afterDate",
          values: [
            moment(churnDate.startDate)
              .subtract(1, "days")
              .format(churnDateFilterFormat)
              .toString(),
          ],
        },
      ])
    );
    handleTabChange("client_list");
  };
  const handleChurnValueClick = (filterName = [], row, filters) => {
    console.log({ filterName, row, filters });
    dispatch(
      filters.length
        ? handleClientListFilter(filterName, row["churn.state"], [
            ...filters,
            {
              member: "churn.last_study_date",
              operator: "beforeDate",
              values: [
                moment(churnDate.endDate)
                  .format(churnDateFilterFormat)
                  .toString(),
              ],
            },
            {
              member: "churn.last_study_date",
              operator: "afterDate",
              values: [
                moment(churnDate.startDate)
                  .subtract(1, "days")
                  .format(churnDateFilterFormat)
                  .toString(),
              ],
            },
          ])
        : handleClientListFilter(filterName, row["churn.state"], [
            {
              member: "churn.last_study_date",
              operator: "beforeDate",
              values: [
                moment(churnDate.endDate)
                  .format(churnDateFilterFormat)
                  .toString(),
              ],
            },
            {
              member: "churn.last_study_date",
              operator: "afterDate",
              values: [
                moment(churnDate.startDate)
                  .subtract(1, "days")
                  .format(churnDateFilterFormat)
                  .toString(),
              ],
            },
          ])
    );
    handleTabChange("client_list");
  };

  const handleSearch = (confirm) => {
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? COLORS_SERIES[0] : "#808080",
          fontSize: "16px",
          padding: "0px 4px 0px 4px",
          margin: "0px 0px 0px 4px",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      title: "State",
      dataIndex: "churn.state",
      ...getColumnSearchProps("churn.state"),
    },
    {
      title: "Existing Clients",
      dataIndex: "churn.total_clients",
      align: "center",
      sorter: (a, b) => a["churn.total_clients"] - b["churn.total_clients"],
      render: (text, row) => (
        <p
          style={{ cursor: "pointer", margin: 0 }}
          onClick={() => handleExistingClientsValueClick(row)}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Existing Active Clients",
      dataIndex: "churn.existing_active_clients",
      align: "center",
      sorter: (a, b) =>
        a["churn.existing_active_clients"] - b["churn.existing_active_clients"],
      render: (text, row) => (
        <p
          style={{ cursor: "pointer", margin: 0 }}
          onClick={() => handleExistingActiveClientsValueClick(row)}
        >
          {text}
        </p>
      ),
    },
    {
      title: "New Clients",
      dataIndex: "churn.new_clients",
      align: "center",
      sorter: (a, b) => a["churn.new_clients"] - b["churn.new_clients"],
      render: (text, row) => (
        <p
          style={{ cursor: "pointer", margin: 0 }}
          onClick={() => handleNewClientsValueClick(row)}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Still Born",
      dataIndex: "churn.still_born",
      align: "center",
      sorter: (a, b) => a["churn.still_born"] - b["churn.still_born"],
      render: (text, row) => (
        <p
          style={{ cursor: "pointer", margin: 0 }}
          onClick={() =>
            handleChurnValueClick("Still Born", row, [
              {
                member: "churn.churned",
                operator: "equals",
                values: ["1"],
              },
              {
                member: "churn.time_span",
                operator: "lte",
                values: ["3"],
              },
            ])
          }
        >
          {text}
        </p>
      ),
    },
    {
      title: "Onboarding Churn",
      dataIndex: "churn.onboarding_churn",
      align: "center",
      sorter: (a, b) =>
        a["churn.onboarding_churn"] - b["churn.onboarding_churn"],
      render: (text, row) => (
        <p
          style={{ cursor: "pointer", margin: 0 }}
          onClick={() =>
            handleChurnValueClick("Onboarding Churn", row, [
              {
                member: "churn.churned",
                operator: "equals",
                values: ["1"],
              },
              {
                member: "churn.time_span",
                operator: "gt",
                values: ["3"],
              },
              {
                member: "churn.time_span",
                operator: "lte",
                values: ["15"],
              },
            ])
          }
        >
          {text}
        </p>
      ),
    },
    {
      title: "New Client Churn",
      dataIndex: "churn.new_client_churn",
      align: "center",
      sorter: (a, b) =>
        a["churn.new_client_churn"] - b["churn.new_client_churn"],
      render: (text, row) => (
        <p
          style={{ cursor: "pointer", margin: 0 }}
          onClick={() =>
            handleChurnValueClick("New Client Churn", row, [
              {
                member: "churn.churned",
                operator: "equals",
                values: ["1"],
              },
              {
                member: "churn.time_span",
                operator: "gt",
                values: ["15"],
              },
              {
                member: "churn.time_span",
                operator: "lte",
                values: ["60"],
              },
            ])
          }
        >
          {text}
        </p>
      ),
    },
    {
      title: "MRR Churn",
      dataIndex: "churn.mrr_churn",
      align: "center",
      sorter: (a, b) => a["churn.mrr_churn"] - b["churn.mrr_churn"],
      render: (text, row) => (
        <p
          style={{ cursor: "pointer", margin: 0 }}
          onClick={() =>
            handleChurnValueClick("MRR Churn", row, [
              {
                member: "churn.churned",
                operator: "equals",
                values: ["1"],
              },
              {
                member: "churn.time_span",
                operator: "gt",
                values: ["60"],
              },
              {
                member: "churn.time_span",
                operator: "lte",
                values: ["365"],
              },
            ])
          }
        >
          {text}
        </p>
      ),
    },
    {
      title: "LTV Churn",
      dataIndex: "churn.ltv_churn",
      align: "center",
      sorter: (a, b) => a["churn.ltv_churn"] - b["churn.ltv_churn"],
      render: (text, row) => (
        <p
          style={{ cursor: "pointer", margin: 0 }}
          onClick={() =>
            handleChurnValueClick("LTV Churn", row, [
              {
                member: "churn.churned",
                operator: "equals",
                values: ["1"],
              },
              {
                member: "churn.time_span",
                operator: "gt",
                values: ["365"],
              },
            ])
          }
        >
          {text}
        </p>
      ),
    },
  ];

  const initStatesList = () => {
    if (!overviewTableNewClientQueryData?.tablePivot()) return [];

    const initStates1 =
      overviewTableNewClientQueryData
        ?.tablePivot()
        ?.map((data) => data["churn.state"]) || [];
    const initStates2 = [
      ...initStates1,
      ...(overviewTableExistingClientQueryData
        ?.tablePivot()
        ?.map(
          (data) =>
            !initStates1.includes(data["churn.state"]) && data["churn.state"]
        ) || []),
    ];
    const initStates3 = [
      ...initStates2,
      ...(overviewTableExistingActiveClientQueryData
        ?.tablePivot()
        ?.map(
          (data) =>
            !initStates2.includes(data["churn.state"]) && data["churn.state"]
        ) || []),
    ];
    const initStates4 = [
      ...initStates3,
      ...(overviewTableChurnQueryData
        ?.tablePivot()
        ?.map(
          (data) =>
            !initStates3.includes(data["churn.state"]) && data["churn.state"]
        ) || []),
    ];

    return initStates4.filter((val) => val !== false);
  };

  const mergedBasedExistingClients = initStatesList()?.map((parent) => {
    return Object.assign(
      {},
      overviewTableExistingClientQueryData
        ?.tablePivot()
        .find((b) => b["churn.state"] === parent) || {
        "churn.total_clients": 0,
      },
      overviewTableExistingActiveClientQueryData
        ?.tablePivot()
        .find((b) => b["churn.state"] === parent) || {
        "churn.existing_active_clients": 0,
      },
      overviewTableNewClientQueryData
        ?.tablePivot()
        .find((b) => b["churn.state"] === parent) || {
        "churn.new_clients": 0,
      },
      overviewTableChurnQueryData
        ?.tablePivot()
        .find((b) => b["churn.state"] === parent) || {
        "churn.onboarding_churn": 0,
        "churn.new_client_churn": 0,
        "churn.mrr_churn": 0,
        "churn.still_born": 0,
        "churn.ltv_churn": 0,
      }
    );
  });

  return (
    <>
      <Card size="small" bordered>
        <Table
          columns={columns}
          dataSource={mergedBasedExistingClients}
          loading={
            overviewTableExistingClientQueryLoading ||
            overviewTableExistingActiveClientQueryLoading ||
            overviewTableNewClientQueryLoading ||
            overviewTableChurnQueryLoading
          }
          bordered
          pagination={true}
        />
      </Card>

      <Card size="small" bordered={false}>
        <p>
          <Typography.Title level={3}>Churn Over The Time</Typography.Title>
          (Monthly break down)
        </p>
        <MultiLineChart
          resultSet={churnOverTimeChartQueryData}
          isLoading={churnOverTimeChartQueryLoading}
          minWidth={100}
          minHeight={400}
          setTooltipTitleAsDate
          labelFormatter={(tick) => {
            return moment(tick, "YYYY,M").format("MMM-YYYY");
          }}
          tickFormatter={(e) => {
            return moment(e, "YYYY,MM").format("MMM-YY");
          }}
        />
      </Card>
    </>
  );
};

export default OverviewTab;
