import { QueryBuilder } from "@cubejs-client/react";
import { Empty } from "antd";
import React from "react";
import { whereIsMycaseCubejsApi } from ".";
import Spinner from "../../../spinner";

export const WICQueryBuilder=({query,render})=>{
    if(!query) return <></>;
    return(
        <QueryBuilder
        cubejsApi={whereIsMycaseCubejsApi}
        query={query}
        render={(chartData)=>{
            if (!chartData.resultSet || chartData.loadingState.isLoading) {
                return <Spinner tip={"Please Wait..."} />;
              }
              return chartData.measures.length > 0 ||
                chartData.dimensions.length > 0 ? (
                render(chartData)
              ) : (
                <Empty description="Query not loaded! Please Try Again." />
              );
        }}
        />
    )
}

export default WICQueryBuilder