import cubejs from "@cubejs-client/core";
// import WebSocketTransport from "@cubejs-client/ws-transport";

export const executiveDashboardCubejsApi = cubejs(
  process.env.REACT_APP_EXECUTIVE_DASHBOARD_AUTH_TOKEN,
  {
    apiUrl: process.env.REACT_APP_EXECUTIVE_DASHBOARD_URL,
  }
);

// export const executiveDashboardCubejsApi = cubejs({
//   transport: new WebSocketTransport({
//     authorization: process.env.REACT_APP_EXECUTIVE_DASHBOARD_AUTH_TOKEN,
//     apiUrl: process.env.REACT_APP_EXECUTIVE_DASHBOARD_URL,
//   }),
// });

export const totalStudies = {
  measures: [
    "studies.total_studies",
    "studies.total_percent",
    "studies.total_studies_lastweek",
  ],
};

export const activeStudies = {
  measures: [
    "studies.active_studies",
    "studies.active_percent",
    "studies.active_studies_lastweek",
  ],
};

export const totalClients = {
  measures: [
    "studies.total_clients",
    "studies.total_clients_precent",
    "studies.total_clients_last_week",
  ],
};

export const activeClients = {
  measures: [
    "studies.active_clients",
    "studies.active_clients_precent",
    "studies.active_clients_last_week",
  ],
};

//BAR CHART QUERY
// 2nd Line
export const totalStudiesByModality = {
  measures: [
    "studies.total_studies_xray",
    "studies.total_studies_ct",
    "studies.total_studies_mri",
    "studies.total_studies_nm",
  ],
  order: {
    "studies.createdAt": "asc",
  },
};
export const totalStudiesByModalityTrends = {
  measures: [
    "studies.total_percent_xray",
    "studies.total_percent_ct",
    "studies.total_percent_mri",
    "studies.total_percent_nm",
  ],
  dimensions: ["studies.modality"],
  order: {
    "studies.createdAt": "asc",
  },
};

export const activeStudiesByModality = {
  measures: [
    "studies.active_studies_xray",
    "studies.active_studies_ct",
    "studies.active_studies_mri",
    "studies.active_studies_nm",
  ],
  order: {
    "studies.createdAt": "asc",
  },
};
export const activeStudiesByModalityTrends = {
  measures: [
    "studies.active_percent_xray",
    "studies.active_percent_ct",
    "studies.active_percent_mri",
    "studies.active_percent_nm",
  ],
  dimensions: ["studies.modality"],
  order: {
    "studies.createdAt": "asc",
  },
};

export const activeIQCRejectsByModality = {
  measures: [
    "iqc_rejects.total_iqc_rejects_xray",
    "iqc_rejects.total_iqc_rejects_ct",
    "iqc_rejects.total_iqc_rejects_mri",
    "iqc_rejects.total_iqc_rejects_nm",
  ],
  order: {
    "iqc_rejects.createdAt": "asc",
  },
};
export const activeIQCRejectsByModalityTrends = {
  measures: [
    "iqc_rejects.total_iqc_rejects_percent_xray",
    "iqc_rejects.total_iqc_rejects_percent_ct",
    "iqc_rejects.total_iqc_rejects_percent_mri",
    "iqc_rejects.total_iqc_rejects_percent_nm",
  ],
};

export const onlineRadiologist = {
  measures: [
    "NotificationAncillarypresences.onlineradsforday",
    "NotificationAncillarypresences.onlineradsforhour",
  ],
};

export const activeRadiologist = {
  measures: [
    "study_status.active_rads_for_the_day",
    "study_status.active_rads_for_the_hour",
  ],
};

// 3rd Line

export const totalRadiologistReturnByModality = {
  measures: [
    "returns.total_returns_xray",
    "returns.total_returns_ct",
    "returns.total_returns_mri",
    "returns.total_returns_nm",
  ],
  order: {
    "returns.created_at": "asc",
  },
};
export const totalRadiologistReturnByModalityTrends = {
  measures: [
    "returns.total_returns_percent_xray",
    "returns.total_returns_percent_ct",
    "returns.total_returns_percent_mri",
    "returns.total_returns_percent_nm",
  ],
};

export const activeRadiologistReturnsByModality = {
  measures: [
    "returns.active_returns_xray",
    "returns.active_returns_ct",
    "returns.active_returns_mri",
    "returns.active_returns_nm",
  ],
  order: {
    "returns.created_at": "asc",
  },
};
export const activeRadiologistReturnsByModalityTrends = {
  measures: [
    "returns.active_returns_percent_xray",
    "returns.active_returns_percent_ct",
    "returns.active_returns_percent_mri",
    "returns.active_returns_percent_nm",
  ],
};

export const totalOQCRejectsByModality = {
  measures: [
    "oqc_rejects.total_oqc_reject_xray",
    "oqc_rejects.total_oqc_reject_ct",
    "oqc_rejects.total_oqc_reject_mri",
    "oqc_rejects.total_oqc_reject_nm",
  ],
  order: {
    "oqc_rejects.createdAt": "asc",
  },
};
export const totalOQCRejectsByModalityTrends = {
  measures: [
    "oqc_rejects.total_oqc_rejects_percent_xray",
    "oqc_rejects.total_oqc_rejects_percent_ct",
    "oqc_rejects.total_oqc_rejects_percent_mri",
    "oqc_rejects.total_oqc_rejects_percent_nm",
  ],
};

// 4th Line
export const activeOQCRejectsByModality = {
  measures: [
    "oqc_rejects.active_oqc_rejects_xray",
    "oqc_rejects.active_oqc_rejects_ct",
    "oqc_rejects.active_oqc_rejects_mri",
    "oqc_rejects.active_oqc_rejects_nm",
  ],
  order: {
    "oqc_rejects.createdAt": "asc",
  },
};
export const activeOQCRejectsByModalityTrends = {
  measures: [
    "oqc_rejects.active_oqc_rejects_percenxray",
    "oqc_rejects.active_oqc_rejects_percent_ct",
    "oqc_rejects.active_oqc_rejects_percent_mri",
    "oqc_rejects.active_oqc_rejects_percent_nm",
  ],
};

export const totalClientReworksByModality = {
  measures: [
    "reworks.total_reworks_xray",
    "reworks.total_reworks_ct",
    "reworks.total_reworks_mri",
    "reworks.total_reworks_nm",
  ],
  order: {
    "reworks.created_at": "asc",
  },
};
export const totalClientReworksByModalityTrends = {
  measures: [
    "reworks.total_reworks_percent_xray",
    "reworks.total_reworks_percent_ct",
    "reworks.total_reworks_percent_mri",
    "reworks.total_reworks_percent_nm",
  ],
};

export const activeClientReworksByModality = {
  measures: [
    "reworks.active_reworks_xray",
    "reworks.active_reworks_ct",
    "reworks.active_reworks_mri",
    "reworks.active_reworks_nm",
  ],
  order: {
    "reworks.created_at": "asc",
  },
};
export const activeClientReworksByModalityTrends = {
  measures: [
    "reworks.active_reworks_percent_xray",
    "reworks.active_reworks_percent_ct",
    "reworks.active_reworks_percent_mri",
    "reworks.active_reworks_percent_nm",
  ],
};

export const tatDefaultedStudiesByModality = {
  measures: [
    "tat_default_studies.tat_default_studies_xray",
    "tat_default_studies.tat_default_studies_ct",
    "tat_default_studies.tat_default_studies_mri",
    "tat_default_studies.tat_default_studies_nm",
  ],
  order: {
    "tat_default_studies.created_at": "asc",
  },
};
export const tatDefaultedStudiesByModalityTrends = {
  measures: [
    "tat_default_studies.tat_default_studies_percent_xray",
    "tat_default_studies.tat_default_studies_percent_ct",
    "tat_default_studies.tat_default_studies_percent_mri",
    "tat_default_studies.tat_default_studies_percent_nm",
  ],
};

// CHART QUERY

export const activeCasesByHourQuery = {
  measures: [
    "studies.total_studies_xray",
    "studies.total_studies_ct",
    "studies.total_studies_mri",
    "studies.total_studies_nm",
  ],
  order: {
    "studies.hour": "asc",
  },
  dimensions: ["studies.hour"],
};

export const activeClientsByHourQuery = {
  measures: ["studies.total_clients"],
  dimensions: ["studies.hour"],
  order: {
    "studies.hour": "asc",
  },
};

export const activeOnlineRadiologistsQuery = {
  measures: ["NotificationAncillarypresences.onlineradsforday"],
  dimensions: ["NotificationAncillarypresences.hour"],
  order: {
    "NotificationAncillarypresences.hour": "asc",
  },
};

export const tatDefaultStudiesOnlineQuery = {
  dimensions: [
    "tat_default_studies.study_id",
    "tat_default_studies.modality",
    "tat_default_studies.organ",
    "tat_default_studies.tat",
    "tat_default_studies.tat_exceeded_minutes",
    "tat_default_studies.hour",
  ],
  order: {
    "tat_default_studies.study_id": "asc",
  },
  filters: [
    {
      member: "tat_default_studies.date_filter",
      operator: "equals",
      values: ["today"],
    },
  ],
};
