import { Card, Modal } from "antd";
import React from "react";
import CommmonTable from "../../../chart/chartComponents/CommonTable";
import { useCubeQuery } from "@cubejs-client/react";
import moment from "moment";

const CenterDetailModal = ({
  title,
  am_id,
  isModalVisible,
  handleOk,
  handleCancel,
}) => {
  const {
    resultSet: ddData,
    isLoading: ddLoading,
    error: ddError,
  } = useCubeQuery({
    measures: [
      "study_count.xray_sum",
      "study_count.ct_sum",
      "study_count.mri_sum",
      "study_count.nm_sum",
    ],
    dimensions: ["study_count.month", "study_count.year"],
    order: [
      ["study_count.year", "desc"],
      ["study_count.month", "desc"],
    ],
    filters: [
      {
        member: "churn.client_id",
        operator: "equals",
        values: [am_id],
      },
    ],
    limit: 12,
  });

  if (ddError) return <></>;
  return (
    <Modal
      title={title}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={"60%"}
      style={{ maxHeight: "90vh" }}
    >
      <Card>
        <CommmonTable
          pagination={true}
          resultSet={ddData}
          pivotConfig={{}}
          isLoading={ddLoading}
          searchKeys={[]}
          size="middle"
          scrollX={640}
          csv={false}
          bordered
          renderColumn={(column) => {
            if (column.key === "study_count.month") {
              return {
                render: (value) => moment(value, "M").format("MMMM").toString(),
              };
            }
          }}
        />
      </Card>
    </Modal>
  );
};

export default CenterDetailModal;
