import { Card, Empty, Typography } from "antd";
import React from "react";
import Spinner from "../../spinner";

const { Title, Text } = Typography;

const Auxi = ({
  rawData = {},
  filterMeasures = [],
  subMeasures = [],
  component,
  error,
  isLoading = false,
}) => {
  if (error) {
    console.log("AUXI_ERROR: ", error);
    return (
      <Empty
        description={
          <>
            <Title level={5}>Unexpected Error!</Title>
          </>
        }
      />
    );
  }

  if (!rawData) {
    return <Spinner tip={"Please Wait..."} />;
  }

  if (isLoading) {
    return <Spinner tip={"Please Wait..."} />;
  }

  return component(
    filterMeasures.length
      ? rawData
          ?.tableColumns()
          .filter((el) => filterMeasures?.indexOf(el.key) !== -1)
      : rawData
  );
  //   <Card size="small" className="card-plain" bordered={false}>
  // </Card>
};

export default Auxi;
