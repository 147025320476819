import { createMongoAbility, AbilityBuilder } from "@casl/ability";
import { storeConfig } from "../../redux/storeConfig/index";

import userData from "../../config/accessList.json";

const ability = createMongoAbility();

// eslint-disable-next-line import/no-anonymous-default-export
export default (action, subject) => {
  return ability.can(action, subject);
};

storeConfig.subscribe(() => {
  let auth = storeConfig.getState().authReducer;
  ability.update(defineRulesFor(auth));
});

export const getUserRules = () => {
  return ability.rules;
};

const defineRulesFor = (auth) => {
  const email = auth?.userCredentials?.email;
  const { can, rules } = new AbilityBuilder(ability);

  const user = userData.users.filter((data) => email === data.email);

  if (user.length) {
    for (let data of user) {
      if (email === data.email) {
        data.access.subject.forEach((item) => can(data.access.action, item));
      }
    }
  } else {
    can("manage", "all");
  }

  return rules;
};
