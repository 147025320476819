import { Button, Card, Col, Row, Space, Tag, Typography } from "antd";
import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Title } = Typography;

const CenterCardWithTags = ({
  id = "",
  title = "",
  tags = [],
  isRedirectable = true,
}) => {
  const RenderCard = () => {
    return (
      <Card className="card-with-name-and-tags" bordered={false}>
        <Row align="middle">
          <Col span={22}>
            <Space direction="vertical" align="middle">
              <Title level={4} style={{ margin: 0 }}>
                {title}
              </Title>
              {tags.length !== 0 && (
                <Space direction="horizontal" size={0}>
                  {tags.map((item, index) => (
                    <Tag key={`i_${index}`}>{item}</Tag>
                  ))}
                </Space>
              )}
            </Space>
          </Col>
          {isRedirectable && (
            <Col span={2}>
              <Button type="text" size="large" shape="circle">
                <ArrowRightOutlined />
              </Button>
            </Col>
          )}
        </Row>
      </Card>
    );
  };

  if (!isRedirectable) return <RenderCard />;

  return (
    <Link to={`/account-details/${id}`}>
      <RenderCard />
    </Link>
  );
};

export default CenterCardWithTags;
