import * as actions from "../actions/index";

const initialState = {
  odIQCState: {
    resultData: [],
    loading: true,
    error: undefined,
  },
  odOQCState: {
    resultData: [],
    loading: true,
    error: undefined,
  },
  odRadiologistState: {
    resultData: [],
    loading: true,
    error: undefined,
  },
  odTATEWSState: {
    resultData: [],
    loading: true,
    error: undefined,
  },
  odTATDefaultedState: {
    resultData: [],
    loading: true,
    error: undefined,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case actions.OD_ALERT_IQC:
      console.log(action.payload);
      return {
        ...state,
        odIQCState: action.payload,
      };
    case actions.OD_ALERT_OQC:
      return {
        ...state,
        odOQCState: action.payload,
      };
    case actions.OD_ALERT_RADIOLOGIST:
      return {
        ...state,
        odRadiologistState: action.payload,
      };
    case actions.OD_ALERT_TAT_EWS:
      return {
        ...state,
        odTATEWSState: action.payload,
      };
    case actions.OD_ALERT_TAT_DEFAULTED:
      return {
        ...state,
        odTATDefaultedState: action.payload,
      };
    default:
      return state;
  }
};
