import cubejs from "@cubejs-client/core";
import ClientListTab from "../helper/ClientListTab";
import OverviewTab from "../helper/OverviewTab";
import moment from "moment";

export const churnAnalyticsCubejsApi = cubejs(
  process.env.REACT_APP_CUBEJS_CHURN_ANALYTICS_AUTH_TOKEN,
  {
    apiUrl: process.env.REACT_APP_CUBEJS_CHURN_ANALYTICS_URL,
  }
);

export const handleQueryFilters = ({
  defaultQuery,
  tableName = "churn",
  defaultFilter = [],
  before_date,
  after_date,
  last_study_before_date,
  last_study_after_date,
  am_id,
}) => {
  let filters = defaultFilter;

  if (before_date) {
    filters.push({
      member: tableName + ".onboarded_date",
      operator: "beforeDate",
      values: [before_date],
    });
  }

  if (after_date) {
    filters.push({
      member: tableName + ".onboarded_date",
      operator: "afterDate",
      values: [moment(after_date).subtract(1, "days")],
    });
  }
  
  if (last_study_before_date) {
    filters.push({
      member: tableName + ".last_study_date",
      operator: "beforeDate",
      values: [last_study_before_date],
    });
  }

  if (last_study_after_date) {
    filters.push({
      member: tableName + ".last_study_date",
      operator: "afterDate",
      values: [last_study_after_date],
    });
  }

  if (am_id) {
    filters.push({
      member: tableName + ".am_id",
      operator: "equals",
      values: [am_id],
    });
  }

  if (filters.length) {
    return { ...defaultQuery, filters };
  }
  return defaultQuery;
};

export const churnTabs = (props) => {
  const { handleTabChange, amId } = props;
  return [
    {
      label: "Overview",
      key: "overview",
      children: <OverviewTab handleTabChange={handleTabChange} am_id={amId} />,
    },
    {
      label: "Client List",
      key: "client_list",
      children: <ClientListTab am_id={amId} />,
    },
  ];
};

// KPI QUERIES
export const existingTotalClientQuery = {
  measures: ["churn.total_clients"],
};

export const clientChurnKPIsQuery = {
  measures: [
    "churn.onboarding_churn",
    "churn.still_born",
    "churn.new_client_churn",
    "churn.mrr_churn",
    "churn.ltv_churn",
  ],
};
// KPI QUERIES

//OVERVIEW TABLE
export const overviewTableExistingClientQuery = {
  measures: ["churn.total_clients"],
  dimensions: ["churn.state"],
  order: {
    "churn.total_clients": "desc",
  },
};

export const overviewTableExistingActiveClientQuery = {
  measures: ["churn.existing_active_clients"],
  dimensions: ["churn.state"],
  order: {
    "churn.existing_active_clients": "desc",
  },
};
export const overviewTableNewClientQuery = {
  measures: ["churn.new_clients"],
  dimensions: ["churn.state"],
  order: {
    "churn.new_clients": "desc",
  },
};
export const overviewTableChurnQuery = {
  measures: [
    "churn.onboarding_churn",
    "churn.new_client_churn",
    "churn.mrr_churn",
    "churn.still_born",
    "churn.ltv_churn",
  ],
  dimensions: ["churn.state"],
  order: {
    "churn.total_clients": "desc",
  },
};
//OVERVIEW TABLE

//Overview Churn Over Time
export const churnOverTimeChartQuery = {
  measures: ["churn.onboarding_churn", "churn.mrr_churn", "churn.ltv_churn"],
  dimensions: ["churn.year", "churn.month"],
  order: [
    ["churn.year", "asc"],
    ["churn.month", "asc"],
  ],
  filters: [
    {
      member: "churn.year",
      operator: "gte",
      values: ["2021"],
    },
  ],
};

// CLIENT LIST
export const filterClientSourceQuery = {
  dimensions: ["churn.client_source"],
  order: {
    "churn.client_source": "asc",
  },
};

export const filterAccountManagerQuery = {
  dimensions: ["churn.am_id", "churn.am_name", "churn.am_email"],
  order: {
    "churn.am_id": "asc",
  },
};
//{
//   dimensions: ["am_details.am_id", "am_details.am_name"],
//   order: {
//     "am_details.am_id": "asc",
//   },
// };

export const filterOnboardedByQuery = {
  dimensions: ["churn.onboarded_by"],
  order: {
    "am_details.am_id": "asc",
  },
};

export const filterStateQuery = {
  dimensions: ["churn.state"],
  order: {
    "am_details.am_id": "asc",
  },
};

export const clientListQuery = ({
  start_date = "",
  end_date = "",
  client_source = "",
  am_id = "",
  onboarded_by = "",
  state = [],
  defaultFilter = [],
}) => {
  let filters = [];
  const defaultQuery = {
    dimensions: ["churn.client_id", "churn.client_name", "churn.state"],
    measures: [
      "study_count.studies_sum",
      "study_count.xray_sum",
      "study_count.ct_sum",
      "study_count.mri_sum",
      "study_count.nm_sum",
    ],
    order: [["study_count.year", "asc"]],
  };

  if (defaultFilter.length) {
    filters = [...filters, ...defaultFilter];
  }

  // if (start_date) {
  //   filters.push({
  //     member: "churn.last_study_date",
  //     operator: "beforeDate",
  //     values: [start_date],
  //   });
  // }

  // if (end_date) {
  //   filters.push({
  //     member: "churn.last_study_date",
  //     operator: "afterDate",
  //     values: [moment(end_date).subtract(1, "days")],
  //   });
  // }

  if (client_source) {
    filters.push({
      member: "churn.client_source",
      operator: "equals",
      values: [client_source],
    });
  }
  if (am_id) {
    filters.push({
      member: "churn.am_id",
      operator: "equals",
      values: [am_id],
    });
  }
  if (onboarded_by) {
    filters.push({
      member: "churn.onboarded_by",
      operator: "equals",
      values: [onboarded_by],
    });
  }
  if (state.length) {
    filters.push({
      member: "churn.state",
      operator: "contains",
      values: state,
    });
  }
  if (filters.length) {
    return { ...defaultQuery, filters };
  }
  return defaultQuery;
};
