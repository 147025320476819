import cubejs from "@cubejs-client/core";

export const churnDashboardCubejsApi = cubejs(
  process.env.REACT_APP_CUBEJS_CHURN_DASHBOARD_AUTH_TOKEN,
  {
    apiUrl: process.env.REACT_APP_CUBEJS_CHURN_DASHBOARD_URL,
  }
);

export const computationTime = {
  dimensions: ["ncc_churn_metrics.computation_date"],
};

export const totalClientOnboarded = {
  dimensions: ["onboarding_loss.total_last_month_clients_dim"],
};

export const totalClientOnboardedMonthYear = {
  dimensions: ["cold_start_loss_list.clients_onboarded_month_year"],
};

export const coldStartLoss = {
  measures: [
    "cold_start_loss.cold_start_loss_count",
    "cold_start_loss.cold_start_loss_percent",
    // "cold_start_loss.total_last_month_clients",
  ],
};

export const onBoardingLoss = {
  measures: [
    "onboarding_loss.onboarding_loss_count",
    "onboarding_loss.onboarding_loss_percent",
  ],
};

export const coldStartLossCustomers = {
  dimensions: [
    "cold_start_loss_list.client_name",
    "cold_start_loss_list.onboarding_date",
    "cold_start_loss_list.days_since_onboarded",
    "cold_start_loss_list.sm_name",
  ],
  order: {
    "cold_start_loss_list.client_id": "asc",
  },
};

export const onBoardingLossClients = {
  dimensions: [
    "onboarding_loss_list.client_name",
    "onboarding_loss_list.onboarding_date",
    "onboarding_loss_list.study_count",
    "onboarding_loss_list.sm_name",
  ],
  order: {
    "onboarding_loss_list.client_id": "asc",
  },
};

//RETENTION

export const nccAndmrrKPI = {
  measures: [
    "ncc_churn_metrics.total_clients_onboarded",
    "ncc_churn_metrics.ncc_churn",
    "ncc_churn_metrics.ncc_churn_pcent",
    "ncc_churn_metrics.mrr_churn",
    "ncc_churn_metrics.mrr_churn_pcent",
  ],
};

export const ltvClientChurnKPI = {
  measures: ["ret_ltv_clients.ltv_churn"],
};

export const ltvMetricsChurnKPI = {
  dimensions: ["ret_ltv_metrics.ltv_churn"],
  filters: [
    {
      member: "ret_ltv_metrics.churn_inverval",
      operator: "equals",
      values: ["churned_after_12m"],
    },
  ],
};

export const newClientChurnTable = {
  dimensions: [
    "ncc_churn_metrics.client_name",
    "ncc_churn_metrics.onboarding_date",
    "ncc_churn_metrics.study_count",
    "ncc_churn_metrics.xray",
    "ncc_churn_metrics.ct",
    "ncc_churn_metrics.mri",
    "ncc_churn_metrics.nm",
  ],
  order: {
    "ncc_churn_metrics.client_id": "asc",
  },
  filters: [
    {
      member: "ncc_churn_metrics.churn_inverval",
      operator: "equals",
      values: ["churned_within_30d"],
    },
  ],
};

export const mrrChurnTable = {
  dimensions: [
    "mrr_churn_clients.client_name",
    "mrr_churn_clients.onboarding_date",
    "mrr_churn_clients.study_count",
    "mrr_churn_clients.xray",
    "mrr_churn_clients.ct",
    "mrr_churn_clients.mri",
    "mrr_churn_clients.nm",
  ],
  order: {
    "mrr_churn_clients.client_id": "asc",
  },
};

export const ltvChurnTable = {
  dimensions: [
    "ret_ltv_clients.client_name",
    "ret_ltv_clients.onboarding_date",
    "ret_ltv_clients.study_count",
    "ret_ltv_clients.xray",
    "ret_ltv_clients.ct",
    "ret_ltv_clients.mri",
    "ret_ltv_clients.nm",
  ],
  order: {
    "ret_ltv_clients.client_id": "asc",
  },
};
