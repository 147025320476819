import { useCubeQuery } from "@cubejs-client/react";
import { Empty, Space, Spin } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { dauTowauAccountQuery, returnQueryWithID } from "../../utils";
import CenterCardWithTags from "../CenterCardWithTags";

const DAUtoWAU = () => {
  const accountId = useSelector((state) => state.amReducer.accountId);

  const {
    resultSet: dauTowauAccountData,
    isLoading: dauTowauAccountLoading,
    error: dauTowauAccountError,
    refetch: dauTowauAccountRefetch,
  } = useCubeQuery(
    returnQueryWithID({
      query: dauTowauAccountQuery,
      accountId: accountId,
      tableName: "dau_to_wau",
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );

  useEffect(() => {
    if (!dauTowauAccountLoading) {
      dauTowauAccountRefetch();
    }
  }, [accountId]);

  if (dauTowauAccountLoading || dauTowauAccountError)
    return <Spin tip={"Please Wait"} />;

  if (dauTowauAccountData?.rawData().length === 0)
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {dauTowauAccountData?.rawData().map((item, index) => (
        <CenterCardWithTags
          key={`i_${index}`}
          id={item["dau_to_wau.client_id"]}
          title={item["dau_to_wau.client_name"]}
          tags={[
            item["dau_to_wau.tag"],
            item["dau_to_wau.client_segment"],
            item["dau_to_wau.flag"],
          ]}
        />
      ))}
    </Space>
  );
};

export default DAUtoWAU;
