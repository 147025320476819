import * as actions from "../actions/index";

const initialState = {
  loading: false,
  isVerifying: true,
  isLoggedIn: false,
  isValidating: false,
  userCredentials: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case actions.AUTH_REQUEST:
      return {
        loading: true,
      };
    case actions.AUTH_LOGIN:
      return {
        loading: false,
        isLoggedIn: true,
        isVerifying: false,
        userCredentials: action.payload,
      };
    case actions.AUTH_LOGOUT:
      return {
        loading: false,
        isLoggedIn: false,
        isVerifying: false,
        userCredentials: [],
      };
    case actions.AUTH_VERIFY_START:
      return {
        loading: false,
        isLoggedIn: false,
        isVerifying: true,
        isValidating: true,
        userCredentials: [],
      };
    case actions.AUTH_VERIFY:
      return {
        loading: false,
        isLoggedIn: true,
        isVerifying: false,
        isValidating: false,
        userCredentials: action.payload,
      };
    case actions.AUTH_FAILED:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
