import cubejs from "@cubejs-client/core";
import moment from "moment";

export const whereIsMycaseCubejsApi = cubejs(
  process.env.REACT_APP__CUBEJS_SEARCH_TABLE_AUTH_TOKEN,
  {
    apiUrl: process.env.REACT_APP__CUBEJS_SEARCH_TABLE_URL,
  }
);

export const tableData = {
  dimensions: [
    "studies.client_name",
    "studies.patient_name",
    "studies.modality",
    "studies.organ",
    "studies.createdAt",
    "studies.new_pf_link",
    "studies.old_pf_link",
    "studies.status",
    "studies.tat_status",
  ],
  order: {
    "studies.createdAt": "desc",
  },
  limit: 100,
};
export const tableTotalCount = {
  measures: ["studies.study_count"],
};
export const clientList = {
  dimensions: ["studies.client_name"],
  order: {
    "studies.client_name": "asc",
  },
};

export const patientList = {
  dimensions: ["studies.patient_name"],
  order: {
    "studies.study_count": "asc",
  },
};
export const handleQueryFilters = ({
  defaultQuery,
  metrics = "studies",
  defaultFilter = [],
  pageOffset,
  clientName,
  patientName,
  startDate,
  endDate,
}) => {
  let filters = [];
  let offset = "0";
  if (clientName) {
    filters[filters.length] = {
      member: metrics + ".client_name",
      operator: "equals",
      values: [clientName.toString()],
    };
  }
  if (patientName) {
    filters[filters.length] = {
      member: metrics + ".patient_name",
      operator: "equals",
      values: [patientName.toString()],
    };
  }
  if (startDate === endDate) {
    filters[filters.length] = {
      member: "studies.createdAt",
      operator: "afterDate",
      values: [moment(startDate).startOf("day")],
    };
    filters[filters.length] = {
      member: "studies.createdAt",
      operator: "beforeDate",
      values: [moment(endDate)],
    };
  }
  if (startDate) {
    filters[filters.length] = {
      member: "studies.createdAt",
      operator: "afterDate",
      values: [moment(startDate).subtract(1, "days").startOf("date")],
    };
  }
  if (endDate) {
    filters[filters.length] = {
      member: "studies.createdAt",
      operator: "beforeDate",
      values: [moment(endDate).add(1, "days")],
    };
  }
  if (pageOffset > 0) {
    offset = pageOffset.toString();
    return { ...defaultQuery, filters, offset };
  }
  if (filters.length) {
    return { ...defaultQuery, filters };
  } else {
    return defaultQuery;
  }
};
