import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/rootReducer";

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// ** Create store
const storeConfig = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export { storeConfig };
