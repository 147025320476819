import moment from "moment";
import * as actions from "../actions/index";
import { tatDateFilterFormat } from "../../views/tat-dashboard";

const initialState = {
  startDate: moment("2023/01/01")
    .format(tatDateFilterFormat)
    .toString(),
  endDate: moment().format(tatDateFilterFormat).toString(),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case actions.TAT_DATE_RANGE:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    default:
      return state;
  }
};
