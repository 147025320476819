import cubejs from "@cubejs-client/core";
import moment from "moment";

export const radMasterCubejsApi = cubejs(
  process.env.REACT_APP_CUBEJS_RAD_MASTER_AUTH_TOKEN,
  {
    apiUrl: process.env.REACT_APP_CUBEJS_RAD_MASTER_URL,
  }
);

export const toHHMM = (min) => {
  var min_num = parseInt(min, 10);
  var hours = Math.floor(min_num / 60);
  var minutes = Math.floor(min_num - hours * 60);

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return hours + ":" + minutes;
};

export const handleQueryFilters = ({
  defaultQuery,
  tableName = "rads_data",
  defaultFilter = [],
  before_date,
  after_date,
}) => {
  let filters = defaultFilter;

  if (before_date) {
    filters.push({
      member: tableName + ".completed_at",
      operator: "beforeDate",
      values: [before_date],
    });
  }

  if (after_date) {
    filters.push({
      member: tableName + ".completed_at",
      operator: "afterDate",
      values: [moment(after_date).subtract(1, "days")],
    });
  }

  if (filters.length) {
    return { ...defaultQuery, filters };
  }
  return defaultQuery;
};

export const radQuery = {
  dimensions: [
    "rads_data.rad_id",
    "rads_data.rad_name",
    "rads_data.onboarding_date",
    "rads_data.last_active_date",
    "rads_data.modality",
    "rads_data.avg_online_per_day",
    "rads_data.avg_cases_per_day",
    "rads_data.XRAY_cases_till_date",
    "rads_data.cross_section_till_date",
    "rads_data.active_type",
    "rads_data.status",
    "rads_data.skip_rate",
    "rads_data.skip_rate_30days",
    "rads_data.error_rate_tilldate",
    "rads_data.error_rate_30days",
    "rads_data.contact",
    "rads_data.email",
    "rads_data.hour",
  ],
  order: {
    "rads_data.rad_id": "asc",
  },
};

export const radStudyQuery = {
  dimensions: [
    "study_filter.rad_id",
    "study_filter.rad_name",
    "study_filter.onboarding_date",
    "study_filter.last_active_date",
    "study_filter.modality",
    "study_filter.study",
    "study_filter.total_cases_till_date",
    "study_filter.error_rate_till_date",
    "study_filter.error_rate_30_days",
    "study_filter.skip_rate_till_date",
    "study_filter.skip_rate_30_days",
    "study_filter.hour",
  ],
  order: {
    "study_filter.rad_id": "asc",
  },
};

export const radModalityQuery = {
  dimensions: [
    "modality_filter.rad_id",
    "modality_filter.rad_name",
    "modality_filter.onboarding_date",
    "modality_filter.last_active_date",
    "modality_filter.modality",
    "modality_filter.total_cases_till_date",
    "modality_filter.skip_rate_till_date",
    "modality_filter.skip_rate_30_days",
    "modality_filter.error_rate_till_date",
    "modality_filter.error_rate_30_days",
    "modality_filter.hour",
  ],
  order: {
    "modality_filter.rad_id": "asc",
  },
};
