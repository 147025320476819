import { useCubeQuery } from "@cubejs-client/react";
import { Col, Layout, Result, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import KPIwithTrend from "../../../chart/chartComponents/KPIwithTrend";
import { validateNumber } from "../../../chart/utils";
import {
  dauKPIQuery,
  ltvDAUQuery,
  ltvMAUQuery,
  ltvWAUQuery,
  mauKPIQuery,
  mrrDAUQuery,
  mrrMAUQuery,
  mrrWAUQuery,
  newClientDAUQuery,
  newClientMAUQuery,
  newClientWAUQuery,
  onboardingClientsKPIQuery,
  onboardingDrillDownQuery,
  reactivatedClientsKPIQuery,
  reactivatedDrillDownQuery,
  returnQueryOnlyWithID,
  returnQueryWithID,
  totalAccountKPIQuery,
  wauKPIQuery,
} from "../utils";
import KPIAndMultipleBarChart from "./KPIAndMultipleBarChart";
import OverviewModel from "./OverviewModel";

const OverviewDashboard = () => {
  const accountId = useSelector((state) => state.amReducer.accountId);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentView, setCurrentView] = useState({});

  const [finalNewClientDataSet, setFinalNewClientDataSet] = useState([]);
  const [finalMRRDataSet, setFinalMRRDataSet] = useState([]);
  const [finalLTVDataSet, setFinalLTVDataSet] = useState([]);

  // KPI
  const {
    resultSet: totalAccountKPIData,
    isLoading: totalAccountKPILoading,
    refetch: totalAccountKPIRefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: totalAccountKPIQuery,
      tableName: "clients",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );
  const {
    resultSet: onboardingClientsKPIData,
    isLoading: onboardingClientsKPILoading,
    error: onboardingClientsKPIError,
    refetch: onboardingClientsKPIRefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: onboardingClientsKPIQuery,
      tableName: "clients",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );
  const {
    resultSet: reactivatedClientsKPIData,
    isLoading: reactivatedClientsKPILoading,
    error: reactivatedClientsKPIError,
    refetch: reactivatedClientsKPIRefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: reactivatedClientsKPIQuery,
      tableName: "reactivated_clients",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );
  const {
    resultSet: dauKPIData,
    isLoading: dauKPILoading,
    error: dauKPIError,
    refetch: dauKPIRefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: dauKPIQuery,
      tableName: "dau",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );
  const {
    resultSet: wauKPIData,
    isLoading: wauKPILoading,
    error: wauKPIError,
    refetch: wauKPIRefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: wauKPIQuery,
      tableName: "wau",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );
  const {
    resultSet: mauKPIData,
    isLoading: mauKPILoading,
    error: mauKPIError,
    refetch: mauKPIRefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: mauKPIQuery,
      tableName: "mau",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );
  // KPI

  // OVERVIEW

  // const {
  //   resultSet: newClientColdStartKPIData,
  //   isLoading: newClientColdStartKPILoading,
  //   error: newClientColdStartKPIError,
  //   refetch: newClientColdStartKPIRefetch,
  // } = useCubeQuery(
  //   returnQueryOnlyWithID({
  //     query: newClientColdStartKPIQuery,
  //     tableName: "cold_start_loss",
  //     accountId: accountId,
  //   }),
  //   {
  //     subscribe: false,
  //     skip: !accountId,
  //   }
  // );
  // const {
  //   resultSet: newClientOnboardingKPIData,
  //   isLoading: newClientOnboardingKPILoading,
  //   error: newClientOnboardingKPIError,
  //   refetch: newClientOnboardingKPIRefetch,
  // } = useCubeQuery(
  //   returnQueryOnlyWithID({
  //     query: newClientOnboardingKPIQuery,
  //     tableName: "onboarding_loss",
  //     accountId: accountId,
  //   }),
  //   {
  //     subscribe: false,
  //     skip: !accountId,
  //   }
  // );
  
  const {
    resultSet: newClientDAUData,
    isLoading: newClientDAULoading,
    error: newClientDAUError,
    refetch: newClientDAURefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: newClientDAUQuery,
      tableName: "dau",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );
  const {
    resultSet: newClientWAUData,
    isLoading: newClientWAULoading,
    error: newClientWAUError,
    refetch: newClientWAURefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: newClientWAUQuery,
      tableName: "wau",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );
  const {
    resultSet: newClientMAUData,
    isLoading: newClientMAULoading,
    error: newClientMAUError,
    refetch: newClientMAURefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: newClientMAUQuery,
      tableName: "mau",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );

  // MRR

  // const {
  //   resultSet: mrrChurnKPIData,
  //   isLoading: mrrChurnKPILoading,
  //   error: mrrChurnKPIError,
  //   refetch: mrrChurnKPIRefetch,
  // } = useCubeQuery(
  //   returnQueryOnlyWithID({
  //     query: mrrChurnKPIQuery,
  //     tableName: "mrr_churn",
  //     accountId: accountId,
  //   }),
  //   {
  //     subscribe: false,
  //     skip: !accountId,
  //   }
  // );
  
  const {
    resultSet: mrrDAUData,
    isLoading: mrrDAULoading,
    error: mrrDAUError,
    refetch: mrrDAURefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: mrrDAUQuery,
      tableName: "dau",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );
  const {
    resultSet: mrrWAUData,
    isLoading: mrrWAULoading,
    error: mrrWAUError,
    refetch: mrrWAURefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: mrrWAUQuery,
      tableName: "wau",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );
  const {
    resultSet: mrrMAUData,
    isLoading: mrrMAULoading,
    error: mrrMAUError,
    refetch: mrrMAURefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: mrrMAUQuery,
      tableName: "mau",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );

  // LTV

  // const {
  //   resultSet: ltvChurnKPIData,
  //   isLoading: ltvChurnKPILoading,
  //   error: ltvChurnKPIError,
  //   refetch: ltvChurnKPIRefetch,
  // } = useCubeQuery(
  //   returnQueryOnlyWithID({
  //     query: ltvChurnKPIQuery,
  //     tableName: "ltv_churn",
  //     accountId: accountId,
  //   }),
  //   {
  //     subscribe: false,
  //     skip: !accountId,
  //   }
  // );
  const {
    resultSet: ltvDAUData,
    isLoading: ltvDAULoading,
    error: ltvDAUError,
    refetch: ltvDAURefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: ltvDAUQuery,
      tableName: "dau",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );
  const {
    resultSet: ltvWAUData,
    isLoading: ltvWAULoading,
    error: ltvWAUError,
    refetch: ltvWAURefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: ltvWAUQuery,
      tableName: "wau",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );
  const {
    resultSet: ltvMAUData,
    isLoading: ltvMAULoading,
    error: ltvMAUError,
    refetch: ltvMAURefetch,
  } = useCubeQuery(
    returnQueryOnlyWithID({
      query: ltvMAUQuery,
      tableName: "mau",
      accountId: accountId,
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );

  //

  const handleRefetch = () => {
    totalAccountKPIRefetch();
    onboardingClientsKPIRefetch();
    newClientDAURefetch();
    newClientWAURefetch();
    newClientMAURefetch();
    dauKPIRefetch();
    wauKPIRefetch();
    mauKPIRefetch();
    mrrDAURefetch();
    mrrWAURefetch();
    mrrMAURefetch();
    ltvDAURefetch();
    ltvWAURefetch();
    ltvMAURefetch();
    reactivatedClientsKPIRefetch();
  };

  useEffect(() => {
    if (!totalAccountKPILoading && !newClientDAULoading) {
      handleRefetch();
    }
  }, [accountId]);

  const returnRowData = (resultSet, key) => {
    return resultSet?.rawData() && resultSet?.rawData()?.length > 0
      ? parseFloat(resultSet?.rawData()[0][key]).toFixed(2)
      : 0;
  };

  const kpiData = [
    {
      title: "Total Account Assigned",
      isLoading: totalAccountKPILoading,
      value: validateNumber(
        Math.abs(returnRowData(totalAccountKPIData, "clients.total_clients"))
      ),
      trends: [
        {
          value: returnRowData(
            totalAccountKPIData,
            "clients.total_client_pcentage"
          ),
          status:
            returnRowData(
              totalAccountKPIData,
              "clients.total_client_pcentage"
            ) > 0
              ? "green"
              : "red",
          text:
            "Last Month: " +
            validateNumber(
              Math.abs(
                returnRowData(
                  totalAccountKPIData,
                  "clients.total_clients_last_30d"
                )
              )
            ),
        },
      ],
    },
    {
      title: "Onboarding Account",
      isLoading: onboardingClientsKPILoading,
      value: validateNumber(
        Math.abs(
          returnRowData(onboardingClientsKPIData, "clients.onboarded_clients")
        )
      ),
      trends: [
        {
          value: returnRowData(
            onboardingClientsKPIData,
            "clients.onboarded_clients_comparision"
          ),
          status:
            returnRowData(
              onboardingClientsKPIData,
              "clients.onboarded_clients_comparision"
            ) > 0
              ? "green"
              : "red",
          text:
            "Last Month: " +
            validateNumber(
              Math.abs(
                returnRowData(
                  onboardingClientsKPIData,
                  "clients.onboarded_clients_last_month"
                )
              )
            ),
        },
      ],
    },
    {
      title: "Reactivated Account",
      isLoading: reactivatedClientsKPILoading,
      value: validateNumber(
        Math.abs(
          returnRowData(
            reactivatedClientsKPIData,
            "reactivated_clients.total_clients"
          )
        )
      ),
      trends: [
        {
          value: returnRowData(
            reactivatedClientsKPIData,
            "reactivated_clients.last_month_comparision"
          ),
          status:
            returnRowData(
              reactivatedClientsKPIData,
              "reactivated_clients.last_month_comparision"
            ) > 0
              ? "green"
              : "red",
          text:
            "Last Month: " +
            validateNumber(
              Math.abs(
                returnRowData(
                  reactivatedClientsKPIData,
                  "reactivated_clients.last_month_clients"
                )
              )
            ),
        },
      ],
    },
    {
      title: "New Client",
      isLoading: totalAccountKPILoading,
      value: validateNumber(
        Math.abs(returnRowData(totalAccountKPIData, "clients.new_clients"))
      ),
      trends: [
        {
          value: returnRowData(
            totalAccountKPIData,
            "clients.new_clients_comparision"
          ),
          status:
            returnRowData(
              totalAccountKPIData,
              "clients.new_clients_comparision"
            ) > 0
              ? "green"
              : "red",
          text:
            "Last Month: " +
            validateNumber(
              Math.abs(
                returnRowData(
                  totalAccountKPIData,
                  "clients.new_clients_last_month"
                )
              )
            ),
        },
      ],
    },
    {
      title: "MRR",
      isLoading: totalAccountKPILoading,
      value: validateNumber(
        Math.abs(returnRowData(totalAccountKPIData, "clients.mrr_clients"))
      ),
      trends: [
        {
          value: returnRowData(
            totalAccountKPIData,
            "clients.mrr_cleints_comparision"
          ),
          status:
            returnRowData(
              totalAccountKPIData,
              "clients.mrr_cleints_comparision"
            ) > 0
              ? "green"
              : "red",
          text:
            "Last Month: " +
            validateNumber(
              Math.abs(
                returnRowData(
                  totalAccountKPIData,
                  "clients.mrr_clients_last_month"
                )
              )
            ),
        },
      ],
    },
    {
      title: "LTV",
      isLoading: totalAccountKPILoading,
      value: validateNumber(
        Math.abs(returnRowData(totalAccountKPIData, "clients.ltv_clients"))
      ),
      trends: [
        {
          value: returnRowData(
            totalAccountKPIData,
            "clients.ltv_clients_comparision"
          ),
          status:
            returnRowData(
              totalAccountKPIData,
              "clients.ltv_clients_comparision"
            ) > 0
              ? "green"
              : "red",
          text:
            "Last Month: " +
            validateNumber(
              Math.abs(
                returnRowData(
                  totalAccountKPIData,
                  "clients.ltv_clients_last_month"
                )
              )
            ),
        },
      ],
    },
    {
      title: "DAU",
      isLoading: dauKPILoading,
      value: validateNumber(
        Math.abs(returnRowData(dauKPIData, "dau.dau_total"))
      ),
      trends: [
        {
          value: returnRowData(dauKPIData, "dau.lastmonth_comparision"),
          status:
            returnRowData(dauKPIData, "dau.lastmonth_comparision") > 0
              ? "green"
              : "red",
          text:
            "Last Month: " +
            validateNumber(
              Math.abs(returnRowData(dauKPIData, "dau.last_month_dau_total"))
            ),
        },
      ],
    },
    {
      title: "WAU",
      isLoading: wauKPILoading,
      value: validateNumber(
        Math.abs(returnRowData(wauKPIData, "wau.wau_total"))
      ),
      trends: [
        {
          value: returnRowData(wauKPIData, "wau.lastmonth_comparision"),
          status:
            returnRowData(wauKPIData, "wau.lastmonth_comparision") > 0
              ? "green"
              : "red",
          text:
            "Last Month: " +
            validateNumber(
              Math.abs(returnRowData(wauKPIData, "wau.last_month_wau_total"))
            ),
        },
      ],
    },
    {
      title: "MAU",
      isLoading: mauKPILoading,
      value: validateNumber(
        Math.abs(returnRowData(mauKPIData, "mau.mau_total"))
      ),
      trends: [
        {
          value: returnRowData(mauKPIData, "mau.lastmonth_comparision"),
          status:
            returnRowData(mauKPIData, "mau.lastmonth_comparision") > 0
              ? "green"
              : "red",
          text:
            "Last Month: " +
            validateNumber(
              Math.abs(returnRowData(mauKPIData, "mau.last_month_mau_total"))
            ),
        },
      ],
    },
  ];

  const returnMergeData = (data, returnFunc) => {
    if (!data) return [];
    return data?.rawData().map((item) => returnFunc(item));
  };

  useEffect(() => {
    const finalNewClientDAUData = returnMergeData(newClientDAUData, (item) => {
      return {
        title: "DAU",
        growing: item["dau.growing_new_clients"],
        diminishing: item["dau.diminishing_new_clients"],
      };
    });

    const finalNewClientWAUData = returnMergeData(newClientWAUData, (item) => {
      return {
        title: "WAU",
        growing: item["wau.growing_new_clients"],
        diminishing: item["wau.diminishing_new_clients"],
      };
    });

    const finalNewClientMAUData = returnMergeData(newClientMAUData, (item) => {
      return {
        title: "MAU",
        growing: item["mau.growing_new_clients"],
        diminishing: item["mau.diminishing_new_clients"],
      };
    });

    setFinalNewClientDataSet([
      ...finalNewClientDAUData,
      ...finalNewClientWAUData,
      ...finalNewClientMAUData,
    ]);
  }, [newClientDAUData, newClientMAUData, newClientWAUData]);

  useEffect(() => {
    const finalMRRDAUData = returnMergeData(mrrDAUData, (item) => {
      return {
        title: "DAU",
        growing: item["dau.growing_mrr_clients"],
        diminishing: item["dau.diminishing_mrr_clients"],
      };
    });

    const finalMRRWAUData = returnMergeData(mrrWAUData, (item) => {
      return {
        title: "WAU",
        growing: item["wau.growing_mrr_clients"],
        diminishing: item["wau.diminishing_mrr_clients"],
      };
    });

    const finalMRRMAUData = returnMergeData(mrrMAUData, (item) => {
      return {
        title: "MAU",
        growing: item["mau.growing_mrr_clients"],
        diminishing: item["mau.diminishing_mrr_clients"],
      };
    });

    setFinalMRRDataSet([
      ...finalMRRDAUData,
      ...finalMRRWAUData,
      ...finalMRRMAUData,
    ]);
  }, [mrrDAUData, mrrMAUData, mrrWAUData]);

  useEffect(() => {
    const finalLTVDAUData = returnMergeData(ltvDAUData, (item) => {
      return {
        title: "DAU",
        growing: item["dau.growing_ltv_clients"],
        diminishing: item["dau.diminishing_ltv_clients"],
      };
    });

    const finalLTVWAUData = returnMergeData(ltvWAUData, (item) => {
      return {
        title: "WAU",
        growing: item["wau.growing_ltv_clients"],
        diminishing: item["wau.diminishing_ltv_clients"],
      };
    });

    const finalLTVMAUData = returnMergeData(ltvMAUData, (item) => {
      return {
        title: "MAU",
        growing: item["mau.growing_ltv_clients"],
        diminishing: item["mau.diminishing_ltv_clients"],
      };
    });

    setFinalLTVDataSet([
      ...finalLTVDAUData,
      ...finalLTVWAUData,
      ...finalLTVMAUData,
    ]);
  }, [ltvDAUData, ltvMAUData, ltvWAUData]);

  const showModal = (curr) => {
    setCurrentView(curr);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (!accountId)
    return (
      <Result
        status="404"
        title="No Result"
        subTitle="Please select a account manager!"
      />
    );

  return (
    <Layout>
      <Row>
        {kpiData.map((item, index) => (
          <Col sm={24} md={24} lg={8} xl={8} key={`i_${index}`}>
            <KPIwithTrend
              title={item.title}
              value={item.value}
              trends={item.trends}
              isLoading={item.isLoading}
            />
          </Col>
        ))}
      </Row>

      <Row gutter={[8, 8]} style={{ padding: "8px" }}>
        <Col md={24} xl={12}>
          <KPIAndMultipleBarChart
            openModal={showModal}
            title="New Client"
            segment="NEW Client"
            kpiData={
              [
                // {
                //   title: "Cold Start",
                //   value:
                //     newClientColdStartKPIData?.rawData()[0][
                //       "cold_start_loss.cold_start_loss"
                //     ],
                //   isLoading:
                //     newClientColdStartKPILoading || newClientColdStartKPIError,
                //   drillDownQuery: returnQueryOnlyWithID({
                //     query: newClientColdStartDrillDownQuery,
                //     tableName: "cold_start_loss",
                //     accountId: accountId,
                //   }),
                // },
                // {
                //   title: "Onboarding Loss",
                //   value:
                //     newClientOnboardingKPIData?.rawData()[0][
                //       "onboarding_loss.onboarding_loss"
                //     ],
                //   isLoading:
                //     newClientOnboardingKPILoading || newClientOnboardingKPIError,
                //   drillDownQuery: returnQueryOnlyWithID({
                //     query: newClientOnboardingDrillDownQuery,
                //     tableName: "onboarding_loss",
                //     accountId: accountId,
                //   }),
                // },
              ]
            }
            chartData={finalNewClientDataSet}
            chartLoader={
              newClientDAULoading || newClientMAULoading || newClientWAULoading
            }
            chartError={
              newClientDAUError || newClientMAUError || newClientWAUError
            }
          />
        </Col>
        <Col md={24} xl={12}>
          <KPIAndMultipleBarChart
            openModal={showModal}
            title="MRR"
            segment="MRR Client"
            kpiData={
              [
                // {
                //   title: "Churn",
                //   value: mrrChurnKPIData?.rawData()[0]["mrr_churn.mrr_churn"],
                //   isLoading: mrrChurnKPILoading || mrrChurnKPIError,
                //   drillDownQuery: returnQueryOnlyWithID({
                //     query: mrrChurnDrillDownQuery,
                //     tableName: "mrr_churn",
                //     accountId: accountId,
                //   }),
                // },
              ]
            }
            chartData={finalMRRDataSet}
            chartLoader={mrrDAULoading || mrrMAULoading || mrrWAULoading}
            chartError={mrrDAUError || mrrMAUError || mrrWAUError}
          />
        </Col>
        <Col md={24} xl={12}>
          <KPIAndMultipleBarChart
            openModal={showModal}
            title="LTV"
            segment="LTV Client"
            kpiData={
              [
                // {
                //   title: "Churn",
                //   value: ltvChurnKPIData?.rawData()[0]["ltv_churn.ltv_churn"],
                //   isLoading: ltvChurnKPILoading || ltvChurnKPIError,
                //   drillDownQuery: returnQueryOnlyWithID({
                //     query: ltvChurnDrillDownQuery,
                //     tableName: "ltv_churn",
                //     accountId: accountId,
                //   }),
                // },
              ]
            }
            chartData={finalLTVDataSet}
            chartLoader={ltvDAULoading || ltvMAULoading || ltvWAULoading}
            chartError={ltvDAUError || ltvMAUError || ltvWAUError}
          />
        </Col>
        <Col md={24} xl={12}>
          <KPIAndMultipleBarChart
            openModal={null}
            title="Reactivated & Onboarded"
            kpiData={[
              {
                title: "Reactivated Clients",
                value:
                  reactivatedClientsKPIData?.rawData()[0][
                    "reactivated_clients.total_clients"
                  ],
                isLoading:
                  reactivatedClientsKPILoading || reactivatedClientsKPIError,
                drillDownQuery: returnQueryWithID({
                  query: reactivatedDrillDownQuery,
                  tableName: "reactivated_clients",
                  accountId: accountId,
                }),
              },
              {
                title: "Onboarding Clients",
                value:
                  onboardingClientsKPIData?.rawData()[0][
                    "clients.onboarded_clients"
                  ],
                isLoading:
                  onboardingClientsKPILoading || onboardingClientsKPIError,
                drillDownQuery: returnQueryWithID({
                  query: onboardingDrillDownQuery,
                  tableName: "onboarded_clients_list",
                  accountId: accountId,
                }),
              },
            ]}
            chartData={null}
          />
        </Col>
      </Row>

      {isModalOpen && (
        <OverviewModel
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          handleOk={handleOk}
          //
          {...currentView}
        />
      )}
    </Layout>
  );
};

export default OverviewDashboard;
