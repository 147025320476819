import React, { useState } from "react";
import { Layout, Spin } from "antd";
import MenuSidebar from "./MenuSidebar";
import Horizontal from "../../navigation/horizontal";
import { useSelector } from "react-redux";
import "./index.css";

const { Content, Footer, Header, Sider } = Layout;

const skipCopyRights = [];

const Dashboard = ({ render }) => {
  const state = useSelector((st) => st.authReducer);

  const [collapsed, setCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState("70");

  // useEffect(() => {
  //   dispatch(cube(getAuth().currentUser));
  // }, [getAuth().currentUser]);

  return (
    <Layout style={{ width: "100%", minHeight: "100vh", overflow: "hidden" }}>
      <Sider
        width="225"
        theme="dark"
        breakpoint="md"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        collapsedWidth={collapsedWidth}
        onBreakpoint={(broken) => setCollapsedWidth(broken ? "0" : "70")}
      >
        <h2
          style={{
            margin: "8px",
            padding: "8px",
            fontWeight: "900",
            marginBottom: 0,
            color: "white",
          }}
        >
          {collapsed ? "CB" : "CUBEBASE"}
        </h2>
        <MenuSidebar collapsed={collapsed} />
      </Sider>
      <Layout style={{ width: "100%", overflow: "hidden" }}>
        <Header
          style={{
            height: "auto",
            padding: 0,
            backgroundColor: "#fff",
            marginBottom: "2px solid #dbdbdb",
          }}
        >
          <Horizontal />
        </Header>
        <Layout style={{ width: "100%", overflowX: "scroll" }}>
          <Spin tip="Please Wait..." size="large" spinning={state.isVerifying}>
            <Content style={{ minHeight: "90vh" }}>{render}</Content>
            {skipCopyRights.indexOf(window.location.pathname) !== -1 ? (
              <></>
            ) : (
              <>
                <Footer>Copyrights © 2022 CubeBase, All Rights Reserved</Footer>
              </>
            )}
          </Spin>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
