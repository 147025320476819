import { Card, Table } from "antd";
import { useCubeQuery } from "@cubejs-client/react";
import ChartTAT from "./ChartTAT";
import { handleQueryFilters, overallTATQuery } from "../utils";
import { useSelector } from "react-redux";

const OverviewTab = () => {
  const tatReducer = useSelector((state) => state.tatReducer);

  const {
    resultSet: overallTATData,
    isLoading: overallTATLoading,
    error: overallTATError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: overallTATQuery,
      before_date: tatReducer.endDate,
      after_date: tatReducer.startDate,
      defaultFilter: [
        {
          member: "PhaseLevelTat.modality",
          operator: "notEquals",
          values: ["NM"],
        },
      ],
    })
  );

  const columns = [
    {
      title: "Modality",
      dataIndex: "PhaseLevelTat.modality",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{text}</p>,
    },
    {
      title: "Cases within TAT",
      dataIndex: "PhaseLevelTat.within_tat_per",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{text}%</p>,
    },
    {
      title: "TAT Breach",
      dataIndex: "PhaseLevelTat.tat_breach_per",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{text}%</p>,
    },
    {
      title: "TAT Breach with Rework",
      dataIndex: "PhaseLevelTat.with_rework_per",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{text}%</p>,
    },
    {
      title: "TAT Breach without Rework",
      dataIndex: "PhaseLevelTat.without_rework_per",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{text}%</p>,
    },
  ];

  return (
    <>
      <Card size="small" bordered>
        <Table
          columns={columns}
          dataSource={overallTATData?.rawData()}
          loading={overallTATLoading}
          bordered
          pagination={false}
          error={overallTATError}
          scroll={{ x: "max-content" }}
        />
      </Card>
      <ChartTAT />
    </>
  );
};
export default OverviewTab;
