import { Card } from "antd";
import React from "react";
import moment from "moment";

const CustomTooltip = ({ payload, setTitleAsDate = false }) => {
  return (
    <Card
      size="small"
      title={
        setTitleAsDate
          ? moment(payload.label).format("D MMM YYYY")
          : payload.label
      }
    >
      {payload?.payload?.map((data, index) => (
        <p style={{ color: data.fill }} key={`i_${index}`}>
          {/* {keyFormatter(data.name)} :{" "} */}
          {data.name} :{" "}
          <span style={{ fontWeight: 800, fontSize: 16 }}>
            {Math.round(data.value)}
          </span>
        </p>
      ))}
    </Card>
  );
};

export default CustomTooltip;
