import React from "react";
import { CSVLink } from "react-csv";

import { RiFileDownloadLine } from "react-icons/ri";

const CSVConverter = ({ data, headers, filename }) => {
  return (
    <CSVLink
      data={data}
      headers={headers}
      style={{
        display: "inline-flex",
        fontSize: "12px",
        padding: "8px",
        marginRight: "4px",
        textAlign: "center",
        alignItems: "center",
      }}
      filename={filename}
      target="_blank"
    >
      <RiFileDownloadLine size={24} />
      .CSV
    </CSVLink>
  );
};

export default CSVConverter;
