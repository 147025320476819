import { useCubeQuery } from "@cubejs-client/react";
import { Empty, Space, Spin } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { returnQueryWithID, wauNegativeAccountQuery } from "../../utils";
import CenterCardWithTags from "../CenterCardWithTags";

const WAUNegative = () => {
  const accountId = useSelector((state) => state.amReducer.accountId);

  const {
    resultSet: wauNegativeAccountData,
    isLoading: wauNegativeAccountLoading,
    error: wauNegativeAccountError,
    refetch: wauNegativeAccountRefetch,
  } = useCubeQuery(
    returnQueryWithID({
      query: wauNegativeAccountQuery,
      accountId: accountId,
      tableName: "wau",
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );

  useEffect(() => {
    if (!wauNegativeAccountLoading) {
      wauNegativeAccountRefetch();
    }
  }, [accountId]);

  if (wauNegativeAccountLoading || wauNegativeAccountError)
    return <Spin tip={"Please Wait"} />;

  if (wauNegativeAccountData?.rawData().length === 0)
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {wauNegativeAccountData?.rawData().map((item, index) => (
        <CenterCardWithTags
          key={`i_${index}`}
          id={item["clients.client_id"]}
          title={item["clients.client_name"]}
          tags={[item["wau.tag"], item["wau.client_segment"], item["wau.flag"]]}
        />
      ))}
    </Space>
  );
};

export default WAUNegative;
