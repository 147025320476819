import React from "react";
import { Card, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const loadIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const Spinner = ({ tip, spinning, components }) => {
  return (
    <Card
      style={{
        margin: "8px",
        borderRadius: "8px",
        textAlign: "center",
        boxShadow: "1px 4px 8px 1px rgba(208, 216, 243, 0.6)",
      }}
    >
      <Spin tip={tip} size="large" spinning={spinning}>
        {components}
      </Spin>
    </Card>
  );
};

export default Spinner;
