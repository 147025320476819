import cubejs from "@cubejs-client/core";

export const edSummaryDashboardCubejsApi = cubejs(
  process.env.REACT_APP_EXECUTIVE_DASHBOARD_SUMMARY_AUTH_TOKEN,
  {
    apiUrl: process.env.REACT_APP_EXECUTIVE_DASHBOARD_SUMMARY_URL,
  }
);

export const gwEDSummaryDashboardCubejsApi = cubejs(
  process.env.REACT_APP_GW_EXECUTIVE_DASHBOARD_SUMMARY_AUTH_TOKEN,
  {
    apiUrl: process.env.REACT_APP_GW_EXECUTIVE_DASHBOARD_SUMMARY_URL,
  }
);

export const returnClientBasedFilter = {
  "5C": [],
  GENWORKS: ["GW", "GW-New"],
};

// export const operationalDashboardCubejsApi = cubejs({
//   transport: new WebSocketTransport({
//     authorization:
//       process.env.REACT_APP_CUBEJS_OPERATIONAL_ANALYTICS_AUTH_TOKEN,
//     apiUrl: process.env.REACT_APP_CUBEJS_OPERATIONAL_ANALYTICS_URL,
//   }),
// });

export const totalStudiesKPI = {
  measures: ["studies.total_studies", "studies.total_clients"],
};

export const totalStudiesKPITrends = {
  measures: ["studies.total_studies", "studies.total_clients"],
};

export const onlineRadKPI = {
  measures: ["rad_online.onlineradsforday"],
  order: {
    "rad_online.date": "asc",
  },
};

export const activeRadKPI = {
  measures: ["study_status.active_rads_for_the_day"],
  order: [["study_status.date", "asc"]],
};

export const totalStudiesByModality = {
  measures: [
    "studies.total_studies_xray",
    "studies.total_studies_ct",
    "studies.total_studies_MRI",
    "studies.total_studies_nm",
  ],
  order: {
    "studies.created_at": "asc",
  },
};

export const totalIQCRejectsByModality = {
  measures: [
    "iqc_rejects.xray",
    "iqc_rejects.ct",
    "iqc_rejects.mri",
    "iqc_rejects.nm",
  ],
  order: {
    "iqc_rejects.created_at": "asc",
  },
};

export const totalOQCRejectsByModality = {
  measures: [
    "oqc_rejects.total_oqc_reject_xray",
    "oqc_rejects.total_oqc_reject_ct",
    "oqc_rejects.total_oqc_reject_mri",
    "oqc_rejects.total_oqc_reject_nm",
  ],
  order: {
    "oqc_rejects.date": "asc",
  },
};

export const totalClientReworksByModality = {
  measures: [
    "rework.total_reworks_xray",
    "rework.total_reworks_ct",
    "rework.total_reworks_mri",
    "rework.total_reworks_nm",
  ],
  order: {
    "rework.date": "asc",
  },
};

export const tatDefaultStudiesByModality = {
  measures: [
    "tat_default_studies.tat_default_studies",
    "tat_default_studies.tat_default_studies_xray",
    "tat_default_studies.tat_default_studies_ct",
    "tat_default_studies.tat_default_studies_mri",
    "tat_default_studies.tat_default_studies_nm",
  ],
  order: {
    "tat_default_studies.createdAt": "asc",
  },
};

// TRENDS

export const totalStudiesByModalityTrends = {
  measures: [
    "studies.total_studies_xray",
    "studies.total_studies_ct",
    "studies.total_studies_MRI",
    "studies.total_studies_nm",
  ],
  order: {
    "studies.created_at": "asc",
  },
};

export const totalIQCRejectsByModalityTrends = {
  measures: [
    "iqc_rejects.xray",
    "iqc_rejects.ct",
    "iqc_rejects.mri",
    "iqc_rejects.nm",
  ],
  order: {
    "iqc_rejects.created_at": "asc",
  },
};

export const totalOQCRejectsByModalityTrends = {
  measures: [
    "oqc_rejects.total_oqc_reject_xray",
    "oqc_rejects.total_oqc_reject_ct",
    "oqc_rejects.total_oqc_reject_mri",
    "oqc_rejects.total_oqc_reject_nm",
  ],
  order: {
    "oqc_rejects.date": "asc",
  },
};

export const totalClientReworksByModalityTrends = {
  measures: [
    "rework.total_reworks_xray",
    "rework.total_reworks_ct",
    "rework.total_reworks_mri",
    "rework.total_reworks_nm",
  ],
  order: {
    "rework.date": "asc",
  },
};

export const tatDefaultStudiesByModalityTrends = {
  measures: [
    "tat_default_studies.tat_default_studies",
    "tat_default_studies.tat_default_studies_xray",
    "tat_default_studies.tat_default_studies_ct",
    "tat_default_studies.tat_default_studies_mri",
    "tat_default_studies.tat_default_studies_nm",
  ],
  order: {
    "tat_default_studies.createdAt": "asc",
  },
};

// WEEKLY TREND

export const wtTotalStudies = {
  measures: ["studies.total_studies"],
  dimensions: ["studies.date"],
  order: {
    "studies.date": "asc",
  },
};

export const wtTotalStudiesByModality = {
  measures: [
    "studies.total_studies_xray",
    "studies.total_studies_ct",
    "studies.total_studies_MRI",
    "studies.total_studies_nm",
  ],
  dimensions: ["studies.date"],
  order: {
    "studies.date": "asc",
  },
};

export const wtTotalClients = {
  measures: ["studies.total_clients"],
  dimensions: ["studies.date"],
  order: {
    "studies.date": "asc",
  },
};

export const wtTotalIQCRejectsByModality = {
  measures: [
    "iqc_rejects.xray",
    "iqc_rejects.ct",
    "iqc_rejects.mri",
    "iqc_rejects.nm",
  ],
  dimensions: ["iqc_rejects.date"],
  order: {
    "iqc_rejects.date": "asc",
  },
};

export const wtOnlineRad = {
  measures: ["rad_online.onlineradsforday"],
  dimensions: ["rad_online.date"],
  order: {
    "rad_online.date": "asc",
  },
};

export const wtActiveRad = {
  measures: ["study_status.active_rads_for_the_day"],
  dimensions: ["study_status.date"],
  order: [["study_status.date", "asc"]],
};

export const wtTotalOQCRejectsByModality = {
  measures: [
    "oqc_rejects.total_oqc_reject_xray",
    "oqc_rejects.total_oqc_reject_ct",
    "oqc_rejects.total_oqc_reject_mri",
    "oqc_rejects.total_oqc_reject_nm",
  ],
  dimensions: ["oqc_rejects.date"],
  order: {
    "oqc_rejects.date": "asc",
  },
};

export const wtTotalClientReworkByModality = {
  measures: [
    "rework.total_reworks_xray",
    "rework.total_reworks_ct",
    "rework.total_reworks_mri",
    "rework.total_reworks_nm",
  ],
  order: {
    "rework.date": "asc",
  },
  dimensions: ["rework.date"],
};

export const wtTATDefaultStudies = {
  measures: [
    "tat_default_studies.tat_default_studies",
    "tat_default_studies.tat_default_studies_xray",
    "tat_default_studies.tat_default_studies_ct",
    "tat_default_studies.tat_default_studies_mri",
    "tat_default_studies.tat_default_studies_nm",
  ],
  order: {
    "tat_default_studies.date": "asc",
  },
  dimensions: ["tat_default_studies.date"],
};

//WEEK ON WEEK TREND

export const wowTotalStudies = {
  measures: ["studies.total_studies"],
  dimensions: ["studies.week"],
  filters: [
    {
      member: "studies.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  order: [["studies.week", "asc"]],
};

export const wowTotalStudiesByModality = {
  measures: [
    "studies.total_studies_xray",
    "studies.total_studies_ct",
    "studies.total_studies_MRI",
    "studies.total_studies_nm",
  ],
  dimensions: ["studies.week"],
  filters: [
    {
      member: "studies.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  order: [["studies.week", "asc"]],
};

export const wowTotalClients = {
  measures: ["studies.total_clients"],
  dimensions: ["studies.week"],
  filters: [
    {
      member: "studies.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  order: [["studies.week", "asc"]],
};

export const wowTotalIQCRejectsByModality = {
  measures: [
    "iqc_rejects.xray",
    "iqc_rejects.ct",
    "iqc_rejects.mri",
    "iqc_rejects.nm",
  ],
  filters: [
    {
      member: "iqc_rejects.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  dimensions: ["iqc_rejects.week"],
  order: [["iqc_rejects.week", "asc"]],
};

export const wowOnlineRad = {
  measures: ["rad_online.onlineradsforday"],
  filters: [
    {
      member: "rad_online.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  dimensions: ["rad_online.week"],
  order: [["rad_online.week", "asc"]],
};

export const wowActiveRad = {
  measures: ["study_status.active_rads_for_the_day"],
  filters: [
    {
      member: "study_status.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  dimensions: ["study_status.week"],
  order: [["study_status.week", "asc"]],
};

export const wowTotalOQCRejectsByModality = {
  measures: [
    "oqc_rejects.total_oqc_reject_xray",
    "oqc_rejects.total_oqc_reject_ct",
    "oqc_rejects.total_oqc_reject_mri",
    "oqc_rejects.total_oqc_reject_nm",
  ],
  filters: [
    {
      member: "oqc_rejects.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  dimensions: ["oqc_rejects.week"],
  order: [["oqc_rejects.week", "asc"]],
};

export const wowTotalClientReworksByModality = {
  measures: [
    "rework.total_reworks_xray",
    "rework.total_reworks_ct",
    "rework.total_reworks_mri",
    "rework.total_reworks_nm",
  ],
  filters: [
    {
      member: "rework.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  dimensions: ["rework.week"],
  order: [["rework.week", "asc"]],
};

export const wowTATDefaultStudies = {
  measures: [
    "tat_default_studies.tat_default_studies",
    "tat_default_studies.tat_default_studies_xray",
    "tat_default_studies.tat_default_studies_ct",
    "tat_default_studies.tat_default_studies_mri",
    "tat_default_studies.tat_default_studies_nm",
  ],
  filters: [
    {
      member: "tat_default_studies.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  dimensions: ["tat_default_studies.week"],
  order: [["tat_default_studies.week", "asc"]],
};

//MONTH ON MONTH TREND

export const momTotalStudies = {
  measures: ["studies.total_studies"],
  dimensions: ["studies.month"],
  filters: [
    {
      member: "studies.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  order: [["studies.month", "asc"]],
};

export const momTotalStudiesByModality = {
  measures: [
    "studies.total_studies_xray",
    "studies.total_studies_ct",
    "studies.total_studies_MRI",
    "studies.total_studies_nm",
  ],
  dimensions: ["studies.month"],
  filters: [
    {
      member: "studies.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  order: [["studies.month", "asc"]],
};

export const momTotalClients = {
  measures: ["studies.total_clients"],
  dimensions: ["studies.month"],
  filters: [
    {
      member: "studies.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  order: [["studies.month", "asc"]],
};

export const momTotalIQCRejectsByModality = {
  measures: [
    "iqc_rejects.xray",
    "iqc_rejects.ct",
    "iqc_rejects.mri",
    "iqc_rejects.nm",
  ],
  filters: [
    {
      member: "iqc_rejects.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  dimensions: ["iqc_rejects.month"],
  order: [["iqc_rejects.month", "asc"]],
};

export const momOnlineRad = {
  measures: ["rad_online.onlineradsforday"],
  filters: [
    {
      member: "rad_online.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  dimensions: ["rad_online.month"],
  order: [["rad_online.month", "asc"]],
};

export const momActiveRad = {
  measures: ["study_status.active_rads_for_the_day"],
  filters: [
    {
      member: "study_status.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  dimensions: ["study_status.month"],
  order: [["study_status.month", "asc"]],
};

export const momTotalOQCRejectsByModality = {
  measures: [
    "oqc_rejects.total_oqc_reject_xray",
    "oqc_rejects.total_oqc_reject_ct",
    "oqc_rejects.total_oqc_reject_mri",
    "oqc_rejects.total_oqc_reject_nm",
  ],
  filters: [
    {
      member: "oqc_rejects.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  dimensions: ["oqc_rejects.month"],
  order: [["oqc_rejects.month", "asc"]],
};

export const momTotalClientReworksByModality = {
  measures: [
    "rework.total_reworks_xray",
    "rework.total_reworks_ct",
    "rework.total_reworks_mri",
    "rework.total_reworks_nm",
  ],
  filters: [
    {
      member: "rework.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  dimensions: ["rework.month"],
  order: [["rework.month", "asc"]],
};

export const momTATDefaultStudies = {
  measures: [
    "tat_default_studies.tat_default_studies",
    "tat_default_studies.tat_default_studies_xray",
    "tat_default_studies.tat_default_studies_ct",
    "tat_default_studies.tat_default_studies_mri",
    "tat_default_studies.tat_default_studies_nm",
  ],
  filters: [
    {
      member: "tat_default_studies.date",
      operator: "afterDate",
      values: ["2022-03-31"],
    },
  ],
  dimensions: ["tat_default_studies.month"],
  order: [["tat_default_studies.month", "asc"]],
};

export const tableTATDefaultStudiesDetail = {
  dimensions: [
    "tat_default_studies.study_id",
    "tat_default_studies.mongo_id",
    "tat_default_studies.modality",
    "tat_default_studies.organ",
    "tat_default_studies.tat",
    "tat_default_studies.createdAt",
  ],
  order: {
    "tat_default_studies.study_id": "asc",
  },
  filters: [
    // {
    //   member: "tat_default_studies.date",
    //   operator: "afterDate",
    //   values: ["2022-03-31"],
    // },
  ],
};
