import moment from "moment";
import * as actions from "../actions/index";
import { LaDateFilterFormat } from "../../views/large-account-dashboard";

const initialState = {
  startDate: moment("2021/04/30").format(LaDateFilterFormat).toString(),
  endDate: moment().format(LaDateFilterFormat).toString(),
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
      case actions.LA_DATE_RANGE:
        return {
          startDate: action.startDate,
          endDate: action.endDate,
        };
      default:
        return state;
    }
  };
  