import moment from "moment";
import * as actions from "../actions/index";
import { churnDateFilterFormat } from "../../views/churn-analytics";

const initialState = {
  startDate: moment("2021/04/30")
    // .subtract(2, "days")
    .format(churnDateFilterFormat)
    .toString(),
  endDate: moment().format(churnDateFilterFormat).toString(),
  //
  clientListFilter: {
    name: "",
    state: "",
    queryFilter: {},
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CHURN_DATE_RANGE:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case actions.CHURN_CLIENT_LIST_FILTER:
      return {
        ...state,
        clientListFilter: action.clientListFilter,
      };
    case actions.CHURN_CLEAR_CLIENT_LIST_FILTER:
      return {
        ...state,
        clientListFilter: {},
      };
    default:
      return state;
  }
};
