import { Card, Col, Row, Table, Typography } from "antd";
import { useCubeQuery } from "@cubejs-client/react";
import React, { useState } from "react";
import MultiLineChart from "../../../chart/chartComponents/MultiLineChart";
import moment from "moment";
import { Radio } from "antd";
import {
  handleQueryFilters,
  hourlyTATQuery,
  monthlyTATQuery,
  monthlyModalityTATQuery,
  hourlyModalityTATQuery,
  xrayTopOrganQuery,
  mriTopOrganQuery,
  ctTopOrganQuery,
  caseTypeQuery,
} from "../utils";
import { useSelector } from "react-redux";
export const churnDateFilterFormat = "YYYY-MM-DD";

const ChartTAT = () => {
  const tatReducer = useSelector((state) => state.tatReducer);

  const [switchChart, setSwitchChart] = useState(false);
  const [switchModalityChart, setSwitchModalityChart] = useState(false);

  const {
    resultSet: caseTypeData,
    isLoading: caseTypeLoading,
    error: caseTypeError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: caseTypeQuery,
      before_date: tatReducer.endDate,
      after_date: tatReducer.startDate,
      defaultFilter: [
        {
          member: "PhaseLevelTat.modality",
          operator: "contains",
          values: ["CT", "MRI"],
        },
      ],
    })
  );

  const {
    resultSet: hourlyTATData,
    isLoading: hourlyTATLoading,
    error: hourlyTATError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: hourlyTATQuery,
      before_date: tatReducer.endDate,
      after_date: tatReducer.startDate,
    })
  );

  const {
    resultSet: monthlyTATData,
    isLoading: monthlyTATLoading,
    error: monthlTATError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: monthlyTATQuery,
      before_date: tatReducer.endDate,
      after_date: tatReducer.startDate,
    })
  );

  const {
    resultSet: monthlyModalityTATData,
    isLoading: monthlyModalityTATLoading,
    error: monthlyModalityTATError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: monthlyModalityTATQuery,
      before_date: tatReducer.endDate,
      after_date: tatReducer.startDate,
    })
  );

  const {
    resultSet: hourlyModalityTATData,
    isLoading: hourlyModalityTATLoading,
    error: hourlyModalityTATError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: hourlyModalityTATQuery,
      before_date: tatReducer.endDate,
      after_date: tatReducer.startDate,
    })
  );

  const {
    resultSet: xrayTopOrganData,
    isLoading: xrayTopOrganLoading,
    error: xrayTopOrganError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: xrayTopOrganQuery,
      before_date: tatReducer.endDate,
      after_date: tatReducer.startDate,
      defaultFilter: [
        {
          member: "PhaseLevelTat.modality",
          operator: "equals",
          values: ["XRAY"],
        },
      ],
    })
  );

  const {
    resultSet: mriTopOrganData,
    isLoading: mriTopOrganLoading,
    error: mriTopOrganError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: mriTopOrganQuery,
      before_date: tatReducer.endDate,
      after_date: tatReducer.startDate,
      defaultFilter: [
        {
          member: "PhaseLevelTat.modality",
          operator: "equals",
          values: ["MRI"],
        },
      ],
    })
  );

  const {
    resultSet: ctTopOrganData,
    isLoading: ctTopOrganLoading,
    error: ctTopOrganError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: ctTopOrganQuery,
      before_date: tatReducer.endDate,
      after_date: tatReducer.startDate,
      defaultFilter: [
        {
          member: "PhaseLevelTat.modality",
          operator: "equals",
          values: ["CT"],
        },
      ],
    })
  );

  const columns = [
    {
      title: "Type",
      dataIndex: "measures",
      align: "center",
      render(text, row) {
        return {
          props: {
            style: {
              background: text.includes("critical") ? "#FFFAE5" : "white",
            },
          },
          children: (
            <p style={{ margin: 0 }}>
              {text.includes("critical")
                ? "Critical"
                : text.includes("abnormal")
                ? "Abnormal"
                : text.includes("normal")
                ? "Normal"
                : text.includes("overall")
                ? "Overall"
                : "Complex"}
            </p>
          ),
        };
      },
    },
    {
      title: "CT",
      dataIndex: "CT",
      align: "center",
      render(text, row) {
        return {
          props: {
            style: {
              background: row["measures"].includes("critical")
                ? "#FFFAE5"
                : "white",
            },
          },
          children: (
            <p style={{ margin: 0 }}>
              {text.split(",")[0]}
              {text.split(",").length > 1 && (
                <span style={{ color: "#818589" }}>
                  {" "}
                  ({text.split(",")[1]}%)
                </span>
              )}
            </p>
          ),
        };
      },
    },
    {
      title: "MRI",
      dataIndex: "MRI",
      align: "center",
      render(text, row) {
        return {
          props: {
            style: {
              background: row["measures"].includes("critical")
                ? "#FFFAE5"
                : "white",
            },
          },
          children: (
            <p style={{ margin: 0 }}>
              {text.split(",")[0]}
              {text.split(",").length > 1 && (
                <span style={{ color: "#818589" }}>
                  {" "}
                  ({text.split(",")[1]}%)
                </span>
              )}
            </p>
          ),
        };
      },
    },
  ];

  return (
    <>
      <Row style={{ backgroundColor: "#fff" }}>
        <Col xs={24} lg={12}>
          <Card style={{ width: "100%", height: "100%" }}>
            <p>
              <Typography.Title level={5}>
                Breach by Study Type
              </Typography.Title>
            </p>

            <Card bordered={false} size="small">
              <Table
                columns={columns}
                dataSource={caseTypeData?.tablePivot({
                  x: ["measures"],
                  y: ["PhaseLevelTat.modality"],
                  fillMissingDates: true,
                  joinDateRange: false,
                })}
                loading={caseTypeLoading}
                bordered
                pagination={false}
              />
            </Card>
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card style={{ width: "100%", height: "100%" }}>
            <p>
              <Typography.Title level={5}>
                Most Common TAT Breached Study Organ
              </Typography.Title>
            </p>
            <>
              <Card
                bordered={false}
                bodyStyle={{
                  backgroundColor: "rgba(116, 94, 166, 0.15)",
                  borderRadius: "5px",
                }}
              >
                <Row justify="space-around" align="middle">
                  <Col xs={24} lg={4} align="middle">
                    <Typography.Title level={5} style={{ color: "#745EA6" }}>
                      XRAY
                    </Typography.Title>
                  </Col>
                  {xrayTopOrganData?.rawData().map((value) => {
                    return (
                      <Col xs={8} lg={4} style={{ textAlign: "center" }}>
                        <Typography.Text style={{ justifyContent: "center" }}>
                          {parseFloat(value["PhaseLevelTat.organ_avg"]).toFixed(
                            2
                          )}
                          % <br></br>
                          {value["PhaseLevelTat.organ"]}
                        </Typography.Text>
                      </Col>
                    );
                  })}
                </Row>
              </Card>
              <br></br>
              <Card
                bordered={false}
                bodyStyle={{
                  backgroundColor: "rgba(232, 181, 122, 0.15)",
                  borderRadius: "5px",
                }}
              >
                <Row justify="space-around" align="middle">
                  <Col xs={24} lg={4} align="middle">
                    <Typography.Title level={5} style={{ color: "#EA9D43" }}>
                      MRI
                    </Typography.Title>
                  </Col>
                  {mriTopOrganData?.rawData().map((value) => {
                    return (
                      <Col xs={8} lg={4} style={{ textAlign: "center" }}>
                        <Typography.Text style={{ justifyContent: "center" }}>
                          {parseFloat(value["PhaseLevelTat.organ_avg"]).toFixed(
                            2
                          )}
                          % <br></br>
                          {value["PhaseLevelTat.organ"]}
                        </Typography.Text>
                      </Col>
                    );
                  })}
                </Row>
              </Card>
              <br></br>
              <Card
                bordered={false}
                bodyStyle={{
                  backgroundColor: "rgba(208, 120, 102, 0.15)",
                  borderRadius: "5px",
                }}
              >
                <Row justify="space-around" align="middle">
                  <Col xs={24} lg={4} align="middle">
                    <Typography.Title level={5} style={{ color: "#D07866" }}>
                      CT
                    </Typography.Title>
                  </Col>

                  {ctTopOrganData?.rawData().map((value) => {
                    return (
                      <Col xs={8} lg={4} style={{ textAlign: "center" }}>
                        <Typography.Text style={{ justifyContent: "center" }}>
                          {parseFloat(value["PhaseLevelTat.organ_avg"]).toFixed(
                            2
                          )}
                          % <br></br>
                          {value["PhaseLevelTat.organ"]}
                        </Typography.Text>
                      </Col>
                    );
                  })}
                </Row>
              </Card>
            </>
          </Card>
        </Col>
      </Row>

      <br></br>
      <Row style={{ backgroundColor: "#fff" }}>
        <Col xs={24} lg={12}>
          <div className="card-with-bar-chart">
            <Row align="middle">
              <Col xs={24} lg={12}>
                <h4>TAT Breach % by Interval</h4>
              </Col>

              <Col xs={24} lg={12} align="middle">
                <Radio.Group
                  name="tat_modality_breach"
                  defaultValue={1}
                  onChange={(e) => {
                    switchModalityChart
                      ? setSwitchModalityChart(false)
                      : setSwitchModalityChart(true);
                  }}
                >
                  <Radio value={1}>Time</Radio>
                  <Radio value={2}>Date</Radio>
                </Radio.Group>
              </Col>
            </Row>
            {switchModalityChart ? (
              <MultiLineChart
                resultSet={monthlyModalityTATData}
                isLoading={monthlyModalityTATLoading}
                minWidth={300}
                minHeight={300}
                setTooltipTitleAsDate
                labelFormatter={(tick) => {
                  return tick;
                }}
                tickFormatter={(e) => {
                  return e;
                }}
              />
            ) : (
              <MultiLineChart
                resultSet={hourlyModalityTATData}
                isLoading={hourlyModalityTATLoading}
                minWidth={300}
                minHeight={300}
                setTooltipTitleAsDate
                labelFormatter={(tick) => {
                  return tick;
                }}
                tickFormatter={(e) => {
                  return e;
                }}
              />
            )}
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="card-with-bar-chart">
            <Row align="middle">
              <Col xs={24} lg={12}>
                <h4>Study arrival pattern vs TAT Breach</h4>
              </Col>

              <Col xs={24} lg={12} align="middle">
                <Radio.Group
                  name="tat_breach"
                  defaultValue={1}
                  onChange={(e) => {
                    switchChart ? setSwitchChart(false) : setSwitchChart(true);
                  }}
                >
                  <Radio value={1}>Time</Radio>
                  <Radio value={2}>Date</Radio>
                </Radio.Group>
              </Col>
            </Row>

            {!switchChart ? (
              <MultiLineChart
                resultSet={hourlyTATData}
                isLoading={hourlyTATLoading}
                minWidth={300}
                minHeight={300}
                setTooltipTitleAsDate
                labelFormatter={(tick) => {
                  return moment(tick, "HH").format("HH");
                }}
                tickFormatter={(e) => {
                  return moment(e, "HH").format("HH");
                }}
              />
            ) : (
              <MultiLineChart
                resultSet={monthlyTATData}
                isLoading={monthlyTATLoading}
                minWidth={300}
                minHeight={300}
                setTooltipTitleAsDate
                labelFormatter={(tick) => {
                  return tick;
                }}
                tickFormatter={(e) => {
                  return e;
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ChartTAT;
