import { QueryBuilder } from "@cubejs-client/react";
import { Empty } from "antd";
import React from "react";
import { largeAccountCubejsApi } from ".";
import Spinner from "../../../spinner";

export const LAV1CubeQueryBuilder = ({ query, render }) => {
  if (!query) return <></>;
  return (
    <QueryBuilder
      cubejsApi={largeAccountCubejsApi}
      query={query}
      render={(chartData) => {
        if (!chartData.resultSet || chartData.loadingState.isLoading) {
          return <Spinner tip={"Please Wait..."} />;
        }
        return chartData.measures.length > 0 ||
          chartData.dimensions.length > 0 ? (
          render(chartData)
        ) : (
          <Empty description="Query not loaded! Please Try Again." />
        );
      }}
    />
  );
};

export default LAV1CubeQueryBuilder;
