import React, { useState } from "react";
import { Card } from "antd";
import CustomTooltip from "../utils/CustomTooltip";

import { COLORS_SERIES, formatTime, removeGlobalVar } from "../utils";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Line,
  ComposedChart,
} from "recharts";
import Spinner from "../../spinner";
import CustomTooltip2 from "../utils/CustomTooltip2";

const MultiLineChart = ({
  isLoading,
  resultSet,
  minWidth = 1200,
  minHeight = 420,
  tickFormatter = (e) => {
    return formatTime(e);
  },
  tickYFormatter = (e) => {
    return e;
  },
  setTooltipTitleAsDate,
  labelFormatter = (label) => {
    return label;
  },
  margin = { top: 12, right: 36, left: 0, bottom: 0 },
}) => {
  const [lineData, setLineData] = useState(null);

  const selectLine = (event) => {
    setLineData((el) => (el === event.dataKey ? null : event.dataKey.trim()));
  };

  if (!resultSet) return <Spinner tip={"Please Wait..."} />;

  return (
    <Card
      loading={isLoading}
      bordered={false}
      className="card-with-shadow"
      style={{
        overflowX: "scroll",
      }}
    >
      <ResponsiveContainer
        minWidth={minWidth}
        width="99%"
        minHeight={minHeight}
      >
        <ComposedChart data={resultSet.chartPivot()} margin={margin}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            axisLine={false}
            dataKey={"x"}
            tickSize={12}
            angle={-20}
            tickFormatter={tickFormatter}
          />
          <YAxis tickFormatter={tickYFormatter} />

          <Tooltip
            content={(e) => (
              <CustomTooltip2
                {...e}
                formatLabel={(label) => labelFormatter(label)}
                formatValue={(value) => value}
              />
            )}
          />
          <Legend onClick={selectLine} />

          {resultSet.seriesNames().map((s, i) => (
            <Line
              key={`i_${i}`}
              name={removeGlobalVar(s.title)}
              type="monotone"
              dataKey={
                lineData === null
                  ? s.key
                  : lineData === s.key
                  ? s.key
                  : s.key + " "
              }
              stackId={`i_${i}`}
              stroke={COLORS_SERIES[i]}
              fill={COLORS_SERIES[i]}
              strokeWidth="3px"
              dot={false}
              activeDot={{ r: 8 }}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default MultiLineChart;
