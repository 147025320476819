import moment from "moment";
import * as actions from ".";
import { LaDateFilterFormat } from "../../views/large-account-dashboard";

export const handleDateRangeSearch = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: actions.LA_DATE_RANGE,
      startDate: moment(startDate).format(LaDateFilterFormat).toString(),
      endDate: moment(endDate).format(LaDateFilterFormat).toString(),
    });
  };
};
  