import * as actions from "../actions/index";

const initialState = {
  cubeAPI: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CUBE_TOKEN:
      return {
        cubeAPI: action.payload,
      };
    default:
      return state;
  }
};
