import React from "react";
import { Breadcrumb, Card, Col, Dropdown, Menu, Row, Space } from "antd";
import "antd/dist/antd.min.css";

import { CaretDownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { authLogout } from "../../redux/actions/authAction";

import { navigationRoutes } from "../../router/Navigation";
import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import gw_logo from "../../images/logo.png";

const menuItemText = {
  fontSize: "16px",
};

const Horizontal = () => {
  const dispatch = useDispatch();
  const state = useSelector((sts) => sts.authReducer?.userCredentials);
  const userEmail = getAuth()?.currentUser?.email || "";

  const handleMenuClick = (e) => {
    if (e.key === "101") {
      dispatch(authLogout());
    }
  };

  const menuItems = (
    <Menu
      style={{ padding: "4px" }}
      onClick={handleMenuClick}
      items={[
        {
          key: "1",
          label: state?.email,
          disabled: true,
          style: menuItemText,
        },
        {
          type: "divider",
        },
        {
          key: "101",
          danger: true,
          label: "Logout",
          style: menuItemText,
        },
      ]}
    />
  );

  const formatBreadCrumbLabel = (label) => {
    let breakdownLabel = label.split("_");
    breakdownLabel = breakdownLabel
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1).toLowerCase();
      })
      .join(" ");

    return breakdownLabel;
  };

  const handleTopBarUserName = (email) => {
    const str = email?.split("@")[0]?.replace(".", " ");
    return "Welcome " + str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const isRenderGWLogo = (email) => {
    return (
      window.location.pathname.includes("gw-") || email?.includes("genworks")
    );
  };

  return (
    <Card size="small">
      <Row gutter={[0, 16]} style={{ width: "100%" }} align="middle">
        <Col xs={24} xl={18}>
          <Breadcrumb>
            <Breadcrumb.Item>5C Network</Breadcrumb.Item>
            {navigationRoutes.map(
              (route) =>
                route.url === window.location.pathname &&
                route?.breadcrumb?.map((breadcrumb) => (
                  <Breadcrumb.Item>
                    {breadcrumb?.url ? (
                      <Link to={breadcrumb?.url}>
                        {formatBreadCrumbLabel(breadcrumb?.label)}
                      </Link>
                    ) : (
                      formatBreadCrumbLabel(breadcrumb?.label)
                    )}
                  </Breadcrumb.Item>
                ))
            )}
          </Breadcrumb>
          {/* <h3
            style={{
              color: "#707070",
              marginBottom: 0,
              fontWeight: 800,
              fontSize: "16px",
            }}
          >
            5C Network /{" "}
            {
              ROUTES.MENU_ITEMS?.find(
                (el) => el.link === window.location.pathname
              )?.title
            }
          </h3> */}
        </Col>
        <Col xs={24} xl={6} align="right">
          <div className="top-bar-name-container">
            {isRenderGWLogo(userEmail) && (
              <img
                src={gw_logo}
                style={{ width: "64px", height: "30px" }}
                alt="GenWorks"
              ></img>
            )}
            <Dropdown
              align="middle"
              overlay={menuItems}
              trigger={["click"]}
              placement="bottomRight"
            >
              <Space style={{ cursor: "pointer" }}>
                {handleTopBarUserName(userEmail)}
                <CaretDownOutlined />
              </Space>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default Horizontal;
