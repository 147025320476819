import { Tag, Table } from "antd";
import { useCubeQuery } from "@cubejs-client/react";
import { handleQueryFilters, toHHMMSS, casesTATQuery } from "../utils";
import { useSelector } from "react-redux";

const TopCasesTAT = () => {
  const tatReducer = useSelector((state) => state.tatReducer);

  const toColor = (val1) => {
    var color = "";
    let tag = "";
    if (val1 === "normal") {
      color = "green";
      tag = "Normal";
    }
    if (val1 === "abnormal") {
      color = "blue";
      tag = "Abnormal";
    }
    if (val1 === "complex") {
      color = "volcano";
      tag = "Complex";
    }
    return [color, tag];
  };

  const {
    resultSet: casesTATData,
    isLoading: casesTATLoading,
    error: casesTATError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: casesTATQuery,
      before_date: tatReducer.endDate,
      after_date: tatReducer.startDate,
    })
  );

  const columns = [
    {
      title: "Client Name",
      dataIndex: "PhaseLevelTat.client_name",
      width: 200,
      fixed: "left",
      align: "center",
      render: (text, row) => (
        <p style={{ margin: 0 }}>
          {text}
          <br></br>
        </p>
      ),
    },
    {
      title: "Study Type",
      dataIndex: "PhaseLevelTat.type",
      align: "center",
      width: 100,
      fixed: "left",
      render: (text, row) => (
        <>
          {toColor(row["PhaseLevelTat.type"])[1] && (
            <Tag
              color={toColor(row["PhaseLevelTat.type"])[0]}
              key={toColor(row["PhaseLevelTat.type"])[1]}
            >
              {toColor(row["PhaseLevelTat.type"])[1]}
            </Tag>
          )}
          {row["PhaseLevelTat.critcal"] && (
            <Tag color="red" key={row["PhaseLevelTat.critcal"]}>
              {row["PhaseLevelTat.critical"]}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Study Sent at",
      dataIndex: "PhaseLevelTat.start_time",
      align: "center",
      render: (text, row) => (
        <p style={{ margin: 0 }}>
          {text}
          <br></br>
        </p>
      ),
    },
    {
      title: "Study Completed at",
      dataIndex: "PhaseLevelTat.end_time",
      align: "center",
      render: (text, row) => (
        <p style={{ margin: 0 }}>
          {text}
          <br></br>
        </p>
      ),
    },
    {
      title: "IQC TAT",
      dataIndex: "PhaseLevelTat.iqc_tat",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{toHHMMSS(text)}</p>,
    },
    {
      title: "Assignment TAT",
      dataIndex: "PhaseLevelTat.assignment_tat",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{toHHMMSS(text)}</p>,
    },
    {
      title: "Radiologist TAT",
      dataIndex: "PhaseLevelTat.radiologist_tat",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{toHHMMSS(text)}</p>,
    },
    {
      title: "OQC TAT",
      dataIndex: "PhaseLevelTat.oqc_tat",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{toHHMMSS(text)}</p>,
    },
    {
      title: "Rework TAT",
      dataIndex: "PhaseLevelTat.rework_tat",
      align: "center",
      render: (text, row) => <p style={{ margin: 0 }}>{toHHMMSS(text)}</p>,
    },
    {
      title: "Link",
      dataIndex: "PhaseLevelTat.link",
      align: "center",
      fixed: "right",
      width: 100,
      render: (text, row) => (
        <a href={text} style={{ margin: 0 }}>
          Link
        </a>
      ),
    },
  ];

  return (
    <>
      <br></br>
      <p>
        <b>Top 10 TAT Breached Studies</b>
      </p>
      <Table
        columns={columns}
        dataSource={casesTATData?.rawData()}
        loading={casesTATLoading}
        error={casesTATError}
        bordered
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};
export default TopCasesTAT;
