import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const CustomTab = ({
  tabData,
  defaultTab = "overview",
  selectedTab = "",
  setSwitchTab,
  onChangeEvent = () => {},
}) => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(
    new URLSearchParams(window.location.search).get("tab")
  );

  useEffect(() => {
    setTabParams(currentTab !== selectedTab && selectedTab);
    setSwitchTab("");
  }, [selectedTab]);

  useEffect(() => {
    setTabParams(!currentTab ? defaultTab : currentTab);
  }, [currentTab]);

  const setTabParams = (tabKey) => {
    setCurrentTab(tabKey);
    history.push(`?tab=${tabKey}`);
  };
  return (
    <Tabs
      size="large"
      type="card"
      destroyInactiveTabPane
      items={tabData.map((item) => {
        return {
          label: item.label,
          key: item.key,
          children: item.children,
        };
      })}
      activeKey={currentTab}
      onChange={(activeKey) => {
        setTabParams(activeKey);
      }}
      onClick={() => {
        return onChangeEvent();
      }}
    />
  );
};

export default CustomTab;
