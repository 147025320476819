import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Result } from "antd";
import Spinner from "../spinner";
import Can from "../views/Can/Can";
import Dashboard from "../views/dashboard";

const ProtectedRoute = ({
  subject,
  renderDashboard = true,
  Render,
  ...props
}) => {
  const state = useSelector((st) => st.authReducer);

  const CheckCan = () => {
    if (state.isValidating) return <Spinner tip={"Please Wait..."} />;
    if (Can("manage", subject)) return Render;
    return <Result status="warning" title="Permission Denied" />;
  };

  const renderWithPage = () => {
    if (!renderDashboard) return CheckCan();
    return <Dashboard render={CheckCan()} />;
  };

  return (
    <Route {...props}>
      {state.isLoggedIn || state.isVerifying ? (
        renderWithPage()
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
  );
};

export default ProtectedRoute;
