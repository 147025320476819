import moment from "moment";
import * as actions from ".";
import { churnDateFilterFormat } from "../../views/churn-analytics";

export const handleDateRangeSearch = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: actions.CHURN_DATE_RANGE,
      startDate: moment(startDate).format(churnDateFilterFormat).toString(),
      endDate: moment(endDate).format(churnDateFilterFormat).toString(),
    });
  };
};

export const handleClientListFilter = (name, state, queryFilter) => {
  return (dispatch) => {
    dispatch({
      type: actions.CHURN_CLIENT_LIST_FILTER,
      clientListFilter: { name, state, queryFilter },
    });
  };
};

export const handleClearClientListFilter = () => {
  return (dispatch) => {
    dispatch({
      type: actions.CHURN_CLEAR_CLIENT_LIST_FILTER,
    });
  };
};
