import React, { Suspense, useEffect } from "react";
import ProtectedRoute from "./router/ProtectedRoute";
import { Switch, Route } from "react-router-dom";
import LoginPage from "./views/authentication/LoginPage";

import { initializeApp } from "firebase/app";
import { useDispatch } from "react-redux";
import { authVerify } from "./redux/actions/authAction";
import ForgotPassword from "./views/authentication/ForgotPassword";

import "antd/dist/antd.min.css";
import posthog from "posthog-js";
import Spinner from "./spinner";

import { navigationRoutes } from "./router/Navigation";
import RedirectPage from "./views/redirect";
import DemoPage from "./views/demo";

if (!window.location.href.includes("http://localhost")) {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_API_HOST,
  });
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
};

// Initialize Firebase
initializeApp(firebaseConfig);

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authVerify());
  }, [dispatch]);

  return (
    <Suspense fallback={<Spinner tip={"Please Wait"} />}>
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          subject={"HOME"}
          Render={<RedirectPage />}
        />

        <ProtectedRoute
          exact
          path="/demo"
          subject={"DEMO"}
          Render={<DemoPage />}
        />

        {navigationRoutes.map((data, index) => {
          return (
            <ProtectedRoute
              key={`i_${index}`}
              exact
              path={data.url}
              subject={data.key}
              renderDashboard={data.renderDashboard}
              Render={data.component}
            />
          );
        })}

        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route exact path="/forgot-password">
          <ForgotPassword />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default App;
