import cubejs from "@cubejs-client/core";

export const operationalDashboardCubejsApi = cubejs(
  process.env.REACT_APP_CUBEJS_OPERATIONAL_ANALYTICS_AUTH_TOKEN,
  {
    apiUrl: process.env.REACT_APP_CUBEJS_OPERATIONAL_ANALYTICS_URL,
  }
);

// export const operationalDashboardCubejsApi = cubejs({
//   transport: new WebSocketTransport({
//     authorization:
//       process.env.REACT_APP_CUBEJS_OPERATIONAL_ANALYTICS_AUTH_TOKEN,
//     apiUrl: process.env.REACT_APP_CUBEJS_OPERATIONAL_ANALYTICS_URL,
//   }),
// });

export const getDDItem = ({
  tableName,
  query = null,
  defaultFilter = null,
  searchClientName = null,
  searchAccountManager = null,
  ddStatus = null,
  ddStatusList = [],
  ddStatusListNE = [],
  ddModality = null,
  ddByUserType = null,
  ddByUserTypeNE = null,
  ddByUserTypeList = [],
  ddReworkStatusList = [],
  ddReworkStatusListNE = [],
  ddRadOnlineParentGT = null,
  ddRadOnlineParentGTE = null,
  ddRadOnlineParentLTE = null,
  ddFilterSet = [],
}) => {
  return {
    tableName,
    query,
    defaultFilter,
    searchClientName,
    searchAccountManager,
    ddStatus,
    ddStatusList,
    ddStatusListNE,
    ddModality,
    ddByUserType,
    ddByUserTypeNE,
    ddByUserTypeList,
    ddReworkStatusList,
    ddReworkStatusListNE,
    ddRadOnlineParentGT,
    ddRadOnlineParentGTE,
    ddRadOnlineParentLTE,
    ddFilterSet,
  };
};

export const handleQueryFilters = ({
  tableName,
  query = null,
  defaultFilter = null,
  searchClientName = null,
  searchAccountManager = null,
  ddModality = null,
  ddStatus = null,
  ddStatusList = [],
  ddStatusListNE = [],
  ddByUserType = null,
  ddByUserTypeNE = null,
  ddByUserTypeList = [],
  ddReworkStatusList = [],
  ddReworkStatusListNE = [],
  ddRadOnlineParentGT = null,
  ddRadOnlineParentGTE = null,
  ddRadOnlineParentLTE = null,
  ddFilterSet = [],
}) => {
  if (!query) return null;
  delete query["filters"];

  if (ddFilterSet.length) {
    if (query["filters"]) {
      query["filters"] = [...query["filters"], ...ddFilterSet];
    } else {
      query["filters"] = ddFilterSet;
    }
  }

  if (ddStatus && ddStatusList.length === 0 && ddModality) {
    query["filters"] = [
      {
        member: tableName + "." + "status",
        operator: "equals",
        values: [ddStatus.toString()],
      },
      {
        member: tableName + "." + "modality",
        operator: "equals",
        values: [ddModality.toString()],
      },
    ];
  } else if (ddStatusList.length && ddModality) {
    query["filters"] = [
      {
        member: tableName + "." + "status",
        operator: "notContains",
        values: ddStatusList,
      },
      {
        member: tableName + "." + "modality",
        operator: "equals",
        values: [ddModality.toString()],
      },
    ];
  } else if (
    ddByUserTypeList.length &&
    ddReworkStatusList.length &&
    ddModality
  ) {
    query["filters"] = [
      {
        member: tableName + "." + "by_user_type",
        operator: "contains",
        values: ddByUserTypeList,
      },
      {
        member: tableName + "." + "status",
        operator: "contains",
        values: ddReworkStatusList,
      },
      {
        member: tableName + "." + "modality",
        operator: "equals",
        values: [ddModality.toString()],
      },
    ];
  } else if (ddByUserTypeList.length && ddReworkStatusList.length) {
    query["filters"] = [
      {
        member: tableName + "." + "by_user_type",
        operator: "contains",
        values: ddByUserTypeList,
      },
      {
        member: tableName + "." + "status",
        operator: "contains",
        values: ddReworkStatusList,
      },
    ];
  } else if (
    ddByUserTypeList.length &&
    ddReworkStatusListNE.length &&
    ddModality
  ) {
    query["filters"] = [
      {
        member: tableName + "." + "by_user_type",
        operator: "contains",
        values: ddByUserTypeList,
      },
      {
        member: tableName + "." + "status",
        operator: "notContains",
        values: ddReworkStatusListNE,
      },
      {
        member: tableName + "." + "modality",
        operator: "equals",
        values: [ddModality.toString()],
      },
    ];
  } else if (ddByUserTypeList.length && ddReworkStatusListNE.length) {
    query["filters"] = [
      {
        member: tableName + "." + "by_user_type",
        operator: "contains",
        values: ddByUserTypeList,
      },
      {
        member: tableName + "." + "status",
        operator: "notContains",
        values: ddReworkStatusListNE,
      },
    ];
  } else if (ddByUserType && ddModality) {
    query["filters"] = [
      {
        member: tableName + "." + "by_user_type",
        operator: "equals",
        values: [ddByUserType.toString()],
      },
      {
        member: tableName + "." + "modality",
        operator: "equals",
        values: [ddModality.toString()],
      },
    ];
  } else if (ddByUserTypeNE && ddModality) {
    query["filters"] = [
      {
        member: tableName + "." + "by_user_type",
        operator: "notEquals",
        values: [ddByUserTypeNE.toString()],
      },
      {
        member: tableName + "." + "modality",
        operator: "equals",
        values: [ddModality.toString()],
      },
    ];
  } else if (ddByUserTypeList.length && ddModality) {
    query["filters"] = [
      {
        member: tableName + "." + "by_user_type",
        operator: "contains",
        values: ddByUserTypeList,
      },
      {
        member: tableName + "." + "modality",
        operator: "equals",
        values: [ddModality.toString()],
      },
    ];
  } else if (ddReworkStatusList.length && ddModality) {
    query["filters"] = [
      {
        member: tableName + "." + "status",
        operator: "contains",
        values: ddReworkStatusList,
      },
      {
        member: tableName + "." + "modality",
        operator: "equals",
        values: [ddModality.toString()],
      },
    ];
  } else if (ddRadOnlineParentGTE && ddRadOnlineParentLTE) {
    query["filters"] = [
      {
        member: tableName + "." + "parent",
        operator: "gte",
        values: [ddRadOnlineParentGTE.toString()],
      },
      {
        member: tableName + "." + "parent",
        operator: "lte",
        values: [ddRadOnlineParentLTE.toString()],
      },
    ];
  } else if (ddStatusList.length) {
    query["filters"] = [
      {
        member: tableName + "." + "status",
        operator: "notContains",
        values: ddStatusList,
      },
    ];
  } else if (ddByUserTypeList.length) {
    query["filters"] = [
      {
        member: tableName + "." + "by_user_type",
        operator: "contains",
        values: ddByUserTypeList,
      },
    ];
  } else if (ddReworkStatusList.length) {
    query["filters"] = [
      {
        member: tableName + "." + "status",
        operator: "contains",
        values: ddReworkStatusList,
      },
    ];
  } else if (ddRadOnlineParentGT) {
    query["filters"] = [
      {
        member: tableName + "." + "parent",
        operator: "gt",
        values: [ddRadOnlineParentGT.toString()],
      },
    ];
  } else if (ddByUserType) {
    query["filters"] = [
      {
        member: tableName + "." + "by_user_type",
        operator: "equals",
        values: [ddByUserType.toString()],
      },
    ];
  } else if (ddByUserTypeNE) {
    query["filters"] = [
      {
        member: tableName + "." + "by_user_type",
        operator: "notEquals",
        values: [ddByUserTypeNE.toString()],
      },
    ];
  } else if (ddStatus) {
    query["filters"] = [
      {
        member: tableName + "." + "status",
        operator: "equals",
        values: [ddStatus.toString()],
      },
    ];
  } else if (ddModality) {
    if (query["filters"]) {
      query["filters"] = [
        ...query["filters"],
        {
          member: tableName + "." + "modality",
          operator: "equals",
          values: [ddModality.toString()],
        },
      ];
    } else {
      query["filters"] = [
        {
          member: tableName + "." + "modality",
          operator: "equals",
          values: [ddModality.toString()],
        },
      ];
    }
  }

  if (searchClientName) {
    if (query["filters"]) {
      query["filters"] = [
        ...query["filters"],
        {
          member: tableName + "." + "client_id",
          operator: "equals",
          values: [searchClientName.toString()],
        },
      ];
    } else {
      query["filters"] = [
        {
          member: tableName + "." + "client_id",
          operator: "equals",
          values: [searchClientName.toString()],
        },
      ];
    }
  }

  if (searchAccountManager) {
    query["filters"] = [
      ...query["filters"],
      {
        member: tableName + "." + "am_id",
        operator: "equals",
        values: [searchAccountManager.toString()],
      },
    ];
  }

  if (defaultFilter && query["filters"]) {
    query["filters"] = [...query["filters"], defaultFilter];
  } else if (defaultFilter) {
    query["filters"] = [defaultFilter];
  }

  return query;
};

export const clientNameListQuery = {
  dimensions: ["ClientName.client_id", "ClientName.client_name"],
  order: {
    "ClientName.client_id": "asc",
  },
  filters: [
    {
      member: "ClientName.client_name",
      operator: "set",
      values: [],
    },
  ],
};

// export const accountManagerQuery = {
//   dimensions: ["AmName.am_id", "AmName.am_name"],
//   order: { "AmName.am_id": "asc" },
// };

export const studiesTableQuery = {
  measures: [
    "study.total_received_studies",
    "study.total_completed_studies",
    "study.total_deleted_studies",
    "study.total_pending_studies",
    "study.total_received_studies_xray",
    "study.total_received_studies_ct",
    "study.total_received_studies_mri",
    "study.total_received_studies_nm",
    "study.total_completed_studies_xray",
    "study.total_completed_studies_ct",
    "study.total_completed_studies_mri",
    "study.total_completed_studies_nm",
    "study.total_deleted_studies_xray",
    "study.total_deleted_studies_ct",
    "study.total_deleted_studies_mri",
    "study.total_deleted_studies_nm",
    "study.total_pending_studies_xray",
    "study.total_pending_studies_ct",
    "study.total_pending_studies_mri",
    "study.total_pending_studies_nm",
  ],
  filters: [],
};

export const iqcTableQuery = {
  measures: [
    "iqc.iqc_active",
    "iqc.iqc_unopened",
    "iqc.iqc_unopened_xray",
    "iqc.iqc_unopened_ct",
    "iqc.iqc_unopened_mri",
    "iqc.iqc_unopened_nm",
    "iqc.iqc_opened",
    "iqc.iqc_opened_xray",
    "iqc.iqc_opened_ct",
    "iqc.iqc_opened_mri",
    "iqc.iqc_opened_nm",
  ],
  filters: [],
};

export const iqcPhaseTableQuery = {
  measures: [
    "iqc_phase.total_received",
    "iqc_phase.rejected",
    "iqc_phase.approved",
    "iqc_phase.active",
    "iqc_phase.unopened",
    "iqc_phase.unopenedgreater10mins",
    "iqc_phase.unopenedgreater20mins",
    "iqc_phase.opened",
    "iqc_phase.openedgreater10mins",
    "iqc_phase.openedgreater20mins",
  ],
  filters: [],
};

export const assignedTableQuery = {
  measures: [
    "assignment.assignments_count",
    "assignment.bot_assignments_count",
    "assignment.bot_assignments_xray",
    "assignment.bot_assignments_ct",
    "assignment.bot_assignments_mri",
    "assignment.bot_assignments_nm",
    "assignment.manual_assignments_count",
    "assignment.manual_assignments_xray",
    "assignment.manual_assignments_ct",
    "assignment.manual_assignments_mri",
    "assignment.manual_assignments_nm",
  ],
  filters: [],
};

export const oqcTableQuery = {
  measures: [
    "oqc.oqc_active",
    "oqc.oqc_unopened",
    "oqc.oqc_opened",
    "oqc.oqc_rejected",
    "oqc.oqc_unopened_xray",
    "oqc.oqc_unopened_ct",
    "oqc.oqc_unopened_mri",
    "oqc.oqc_unopened_nm",
    "oqc.oqc_opened_xray",
    "oqc.oqc_opened_ct",
    "oqc.oqc_opened_mri",
    "oqc.oqc_opened_nm",
    "oqc.oqc_rejected_xray",
    "oqc.oqc_rejected_ct",
    "oqc.oqc_rejected_mri",
    "oqc.oqc_rejected_nm",
  ],
  filters: [],
};

export const oqcPhaseTableQuery = {
  measures: [
    "oqc_phase.total_received",
    "oqc_phase.rejected",
    "oqc_phase.completed",
    "oqc_phase.active",
    "oqc_phase.unopened",
    "oqc_phase.unopenedgreater10mins",
    "oqc_phase.unopenedgreater30mins",
    "oqc_phase.opened",
    "oqc_phase.openedgreater20mins",
    "oqc_phase.openedgreater30mins",
  ],
  filters: [],
};

export const clientRejectTableQuery = {
  measures: [
    "client_reject.client_reject_active",
    "client_reject.client_reject_unopened",
    "client_reject.client_reject_opened",
    "client_reject.client_reject_unopened_xray",
    "client_reject.client_reject_unopened_ct",
    "client_reject.client_reject_unopened_mri",
    "client_reject.client_reject_unopened_nm",
    "client_reject.client_reject_opened_xray",
    "client_reject.client_reject_opened_ct",
    "client_reject.client_reject_opened_mri",
    "client_reject.client_reject_opened_nm",
  ],
  filters: [],
};

export const normalOpenedTableQuery = {
  measures: [
    "normal_opened.normal_opened_count",
    "normal_opened.normal_opened_xray",
    "normal_opened.normal_opened_ct",
    "normal_opened.normal_opened_mri",
    "normal_opened.normal_opened_nm",
  ],
  filters: [],
};

export const aRTableQuery = {
  measures: ["ar.ar_count", "ar.ar_xray", "ar.ar_ct", "ar.ar_mri", "ar.ar_nm"],
  filters: [],
};

export const reworkPhaseTableQuery = {
  measures: [
    "rework_phase.total_received",
    "rework_phase.completed",
    "rework_phase.active",
    "rework_phase.unassigned",
    "rework_phase.unassignedgreat10mins",
    "rework_phase.pendingwithradiologist",
    "rework_phase.pendingwithradiologistgreat10mins",
    "rework_phase.withoqc",
    "rework_phase.withoqcgreat20mins",
    "rework_phase.withoqcopengreat20mins",
  ],
  filters: [],
};

export const returnPhaseTableQuery = {
  measures: [
    "return_phase.total__ar",
    "return_phase.return__ar",
    "return_phase.completed",
    "return_phase.deleted",
    "return_phase.active_ar",
    "return_phase.reassigned",
    "return_phase.reassigned_ar",
    "return_phase.unassigned",
    "return_phase.unassignedgreat10mins",
    "return_phase.unopened",
    "return_phase.unopenendgreat20mins",
    "return_phase.unopenendgreat30mins",
  ],
  filters: [],
};

export const radiologistOnlineTableQuery = {
  measures: [
    "online_rads.activeradiologists",
    "online_rads.xray",
    "online_rads.ct",
    "online_rads.mri",
    "online_rads.nm",
  ],
  filters: [],
};

export const radiologistOnlineNotAssignedTableQuery = {
  measures: ["rad_with_studies.radwithzerocaseassigned"],
};

export const radiologistReturnTableQuery = {
  measures: [
    "rad_active.radiologist_active",
    "rad_active.radiologist_unopened",
    "rad_active.radiologist_unopenedgreat10mins",
    "rad_active.radiologist_unopenedgreat30mins",
    "rad_active.radiologist_opened",
    "rad_active.radiologist_openedgreat20mins",
    "rad_active.radiologist_openedgreat30mins",
  ],
  filters: [],
};

export const oldPendingCasesTableQuery = {
  measures: [
    "old_pending_cases.total_old_studies",
    "old_pending_cases.total_old_studies_xray",
    "old_pending_cases.total_old_studies_ct",
    "old_pending_cases.total_old_studies_mri",
    "old_pending_cases.total_old_studies_nm",
  ],
  filters: [],
};

export const pendingByOrganQuery = {
  measures: [
    "pendingstudiesbyorgan.studycount",
    "pendingstudiesbyorgan.radcount",
  ],
  dimensions: ["pendingstudiesbyorgan.organ"],
  order: {
    "pendingstudiesbyorgan.studycount": "desc",
  },
  limit: 15,
};

// DRILL DOWNS:

export const studiesDDQuery = {
  dimensions: [
    "study.study_id",
    "study.mongo_id",
    "study.modality",
    "study.organ",
    "study.status",
    "study.clientName",
    "study.url",
  ],
  order: [["study.createdat", "desc"]],
  // filters: [],
};

export const deletedStudiesDDQuery = {
  dimensions: [
    "deleted_studies.study_id",
    "deleted_studies.deleted_by",
    "deleted_studies.status",
    "deleted_studies.organ",
    "deleted_studies.reason",
    "deleted_studies.createdat",
    "deleted_studies.url",
  ],
  order: [["deleted_studies.createdat", "desc"]],
  // filters: [],
};

export const iqcDDQuery = {
  dimensions: [
    "iqc.studyid",
    "iqc.mongoid",
    "iqc.modality",
    "iqc.organ",
    "iqc.status",
    "iqc.clientname",
    "iqc.url",
  ],
  order: [["iqc.createdat", "desc"]],
  filters: [],
};

export const assignedDDQuery = {
  dimensions: [
    "assignment.study_ID",
    "assignment.mongo_id",
    "assignment.modality",
    "assignment.organ",
    "assignment.status",
    "assignment.client_name",
    "assignment.url",
  ],
  filters: [],
};

export const openedDDQuery = {
  dimensions: [
    "opened.study_id",
    "opened.mongo_id",
    "opened.modality",
    "opened.organ",
    "opened.status",
    "opened.client_name",
    "opened.url",
  ],
  filters: [],
};

export const arDDQuery = {
  dimensions: [
    "ar.study_id",
    "ar.mongo_id",
    "ar.modality",
    "ar.organ",
    "ar.status",
    "ar.client_name",
    "ar.url",
  ],
  filters: [],
};

export const normalOpenedDDQuery = {
  dimensions: [
    "normal_opened.study_id",
    "normal_opened.mongo_id",
    "normal_opened.modality",
    "normal_opened.organ",
    "normal_opened.status",
    "normal_opened.client_name",
    "normal_opened.url",
  ],
  filters: [],
};

export const oqcDDQuery = {
  dimensions: [
    "oqc.study_id",
    "oqc.mongo_id",
    "oqc.modality",
    "oqc.organ",
    "oqc.status",
    "oqc.client_name",
    "oqc.url",
  ],
  filters: [],
};

export const clientRejectDDQuery = {
  dimensions: [
    "client_reject.study_id",
    "client_reject.mongo_id",
    "client_reject.modality",
    "client_reject.organ",
    "client_reject.status",
    "client_reject.client_name",
    "client_reject.url",
  ],
  order: {
    "client_reject.study_id": "asc",
  },
  filters: [],
};

export const radiologistOnlineDDQuery = {
  dimensions: ["online_rads.userid", "online_rads.rad_name"],
  order: {
    "online_rads.userid": "asc",
  },
  filters: [],
};

export const oldPendingCasesDDQuery = {
  dimensions: [
    "old_pending_cases.study_id",
    "old_pending_cases.mongo_id",
    "old_pending_cases.modality",
    "old_pending_cases.organ",
    "old_pending_cases.status",
    "old_pending_cases.clientName",
    "old_pending_cases.studyurl",
  ],
  order: {
    "old_pending_cases.study_id": "asc",
  },
};

export const iqcPhaseDDQuery = {
  dimensions: [
    "iqc_phase.study_id",
    "iqc_phase.mongo_id",
    "iqc_phase.modality",
    "iqc_phase.organ",
    "iqc_phase.status",
    "iqc_phase.client_name",
    "iqc_phase.url",
  ],
  order: {
    "iqc_phase.study_id": "asc",
  },
  filters: [],
};

export const oqcPhaseDDQuery = {
  dimensions: [
    "oqc_phase.study_id",
    "oqc_phase.mongo_id",
    "oqc_phase.modality",
    "oqc_phase.organ",
    "oqc_phase.status",
    "oqc_phase.client_name",
    "oqc_phase.url",
  ],
  order: [["oqc_phase.created_at", "desc"]],
  filters: [],
};

export const returnPhaseDDQuery = {
  dimensions: [
    "return_phase.study_id",
    "return_phase.mongo_id",
    "return_phase.modality",
    "return_phase.organ",
    "return_phase.status",
    "return_phase.client_name",
    "return_phase.url",
  ],
  order: [["return_phase.created_at", "desc"]],
  filters: [],
};

export const reworkPhaseDDQuery = {
  dimensions: [
    "rework_phase.study_id",
    "rework_phase.mongo_id",
    "rework_phase.modality",
    "rework_phase.organ",
    "rework_phase.status",
    "rework_phase.client_name",
    "rework_phase.url",
  ],
  order: [["rework_phase.created_at", "desc"]],
  filters: [],
};

export const radiologistActiveDDQuery = {
  dimensions: [
    "rad_active.study_id",
    "rad_active.mongo_id",
    "rad_active.status",
    "rad_active.modality",
    "rad_active.organ",
    "rad_active.status",
    "rad_active.client_name",
    "rad_active.url",
  ],
  order: {
    "rad_active.study_id": "asc",
  },
  filters: [],
};

export const activeAgentQuery = {
  measures: ["active_agents.iqc_agent_count", "active_agents.oqc_agent_count"],
};

export const activeAgentDDQuery = {
  dimensions: ["active_agents.user_id", "active_agents.agent_name"],
  order: {
    "active_agents.user_id": "asc",
  },
};

export const radiologistDDQueryAndFilterList = [
  {
    tableName: "rad_assigned",
    query: {
      dimensions: [
        "rad_assigned.study_id",
        "rad_assigned.mongo_id",
        "rad_assigned.modality",
        "rad_assigned.organ",
        "rad_assigned.status",
        "rad_assigned.client_name",
        "rad_assigned.url",
      ],
      order: {
        "rad_assigned.study_id": "asc",
      },
      filters: [],
    },
    ddFilterSet: [],
  },
  {
    tableName: "return_phase",
    query: reworkPhaseDDQuery,
    ddFilterSet: [],
  },
  {
    tableName: "rad_completed",
    query: {
      dimensions: [
        "rad_completed.study_id",
        "rad_completed.mongo_id",
        "rad_completed.modality",
        "rad_completed.organ",
        "rad_completed.status",
        "rad_completed.client_name",
        "rad_completed.url",
      ],
      order: {
        "rad_completed.study_id": "asc",
      },
      filters: [],
    },
    ddFilterSet: [],
  },
  {
    tableName: "rad_active",
    query: radiologistActiveDDQuery,
    ddFilterSet: [],
  },
  {
    tableName: "rad_active",
    query: radiologistActiveDDQuery,
    ddFilterSet: [
      {
        member: "rad_active.status",
        operator: "notEquals",
        values: ["OPENED"],
      },
    ],
  },
  {
    tableName: "rad_active",
    query: radiologistActiveDDQuery,
    ddFilterSet: [
      {
        member: "rad_active.status",
        operator: "notEquals",
        values: ["OPENED"],
      },
      {
        member: "rad_active.modality",
        operator: "notEquals",
        values: ["NM"],
      },
      {
        member: "rad_active.timediff",
        operator: "gt",
        values: ["10"],
      },
      {
        member: "rad_active.timediff",
        operator: "lt",
        values: ["30"],
      },
    ],
  },
  {
    tableName: "rad_active",
    query: radiologistActiveDDQuery,
    ddFilterSet: [
      {
        member: "rad_active.status",
        operator: "notEquals",
        values: ["OPENED"],
      },
      {
        member: "rad_active.modality",
        operator: "notEquals",
        values: ["NM"],
      },
      {
        member: "rad_active.timediff",
        operator: "gt",
        values: ["30"],
      },
    ],
  },
  {
    tableName: "rad_active",
    query: radiologistActiveDDQuery,
    ddFilterSet: [
      {
        member: "rad_active.status",
        operator: "equals",
        values: ["OPENED"],
      },
    ],
  },
  {
    tableName: "rad_active",
    query: radiologistActiveDDQuery,
    ddFilterSet: [
      {
        member: "rad_active.status",
        operator: "equals",
        values: ["OPENED"],
      },
      {
        member: "rad_active.modality",
        operator: "notEquals",
        values: ["NM"],
      },
      {
        member: "rad_active.timediff",
        operator: "gt",
        values: ["20"],
      },
      {
        member: "rad_active.timediff",
        operator: "lt",
        values: ["30"],
      },
    ],
  },
  {
    tableName: "rad_active",
    query: radiologistActiveDDQuery,
    ddFilterSet: [
      {
        member: "rad_active.status",
        operator: "equals",
        values: ["OPENED"],
      },
      {
        member: "rad_active.modality",
        operator: "notEquals",
        values: ["NM"],
      },
      {
        member: "rad_active.timediff",
        operator: "gt",
        values: ["30"],
      },
    ],
  },
];
