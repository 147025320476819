import { useCubeQuery } from "@cubejs-client/react";
import { Card, Empty, Modal } from "antd";
import Title from "antd/lib/skeleton/Title";
import React from "react";
import CommmonTable from "../../../chart/chartComponents/CommonTable";
import Spinner from "../../../spinner";

const DrillDownModal = ({
  title,
  queryData,
  isModalVisible,
  handleOk,
  handleCancel,
}) => {
  const {
    resultSet: ddData,
    isLoading: ddLoading,
    error: ddError,
  } = useCubeQuery(queryData, {
    subscribe: false,
  });

  if (ddError)
    return (
      <Empty
        description={
          <>
            {console.log(ddError)}
            <Title level={5}>Unexpected Error!</Title>
          </>
        }
      />
    );

  return (
    <Modal
      title={title}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={"60%"}
      style={{ maxHeight: "90vh" }}
    >
      {!ddData ? (
        <Spinner tip={"Please Wait..."} />
      ) : (
        <Card loading={ddLoading}>
          <CommmonTable
            type="CLIENTS"
            pagination={true}
            resultSet={ddData}
            pivotConfig={{}}
            isLoading={ddLoading}
            searchKeys={[]}
            size="middle"
            scrollX={700}
            csv={true}
            bordered
          />
        </Card>
      )}
    </Modal>
  );
};

export default DrillDownModal;
