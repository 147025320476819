import { useCubeQuery } from "@cubejs-client/react";
import { Card, Empty, Spin } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CommmonTable from "../../../../chart/chartComponents/CommonTable";
import { newClientNurturingQuery, returnQueryWithID } from "../../utils";

const NewClientNurturing = () => {
  const accountId = useSelector((state) => state.amReducer.accountId);

  const {
    resultSet: newClientNurturingData,
    isLoading: newClientNurturingLoading,
    error: newClientNurturingError,
    refetch: newClientNurturingRefetch,
  } = useCubeQuery(
    returnQueryWithID({
      query: newClientNurturingQuery,
      accountId: accountId,
      tableName: "clients",
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );

  useEffect(() => {
    if (!newClientNurturingLoading) {
      newClientNurturingRefetch();
    }
  }, [accountId]);

  if (newClientNurturingLoading || newClientNurturingError)
    return <Spin tip={"Please Wait"} />;

  if (newClientNurturingData?.rawData().length === 0 || !newClientNurturingData)
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <Card size="small" bordered>
      <CommmonTable
        type="ONBOARDED_NURTURING"
        pagination={true}
        resultSet={newClientNurturingData}
        pivotConfig={{}}
        isLoading={newClientNurturingLoading}
        searchKeys={[]}
        size="middle"
        scrollX={700}
        csv={true}
        bordered
      />
    </Card>
    // <Space direction="vertical" style={{ width: "100%" }}>
    //   {newClientNurturingData?.rawData().map((item, index) => (
    //     <CenterCardWithTags
    //       key={`i_${index}`}
    //       id={""}
    //       title={item["onboarded.client_name"]}
    //       tags={[]}
    //     />
    //   ))}
    // </Space>
  );
};

export default NewClientNurturing;
