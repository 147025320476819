import cubejs from "@cubejs-client/core";
// import WebSocketTransport from "@cubejs-client/ws-transport";

// export const alertDashboardCubejsApi = cubejs({
//   transport: new WebSocketTransport({
//     authorization: process.env.REACT_APP_CUBEJS_OD_TV_AUTH_TOKEN,
//     apiUrl: process.env.REACT_APP_CUBEJS_OD_TV_URL,
//   }),
// });

export const alertDashboardCubejsApi = cubejs(
  process.env.REACT_APP_CUBEJS_OD_TV_AUTH_TOKEN,
  {
    apiUrl: process.env.REACT_APP_CUBEJS_OD_TV_URL,
  }
);
