// ** Redux Imports
import { combineReducers } from "redux";

import authReducer from "./authReducer";
import cubeReducer from "./cubeReducer";
import oadReducer from "./oadReducer";
import odAlertReducer from "./odAlertReducer";
import amReducer from "./amReducer";
import churnReducer from "./churnReducer";
import tatReducer from "./tatReducer";
import laReducer from "./laReducer";

const rootReducer = combineReducers({
  authReducer,
  cubeReducer,
  oadReducer,
  odAlertReducer,
  amReducer,
  churnReducer,
  tatReducer,
  laReducer
});

export default rootReducer;
