import React from "react";

import { Form, Input, Button, Col, Card, Row, Divider, Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import "antd/dist/antd.min.css";
import { Redirect } from "react-router-dom";

import { AiOutlineMail, AiOutlineClockCircle } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import Password from "antd/lib/input/Password";
import { authLogin } from "../../redux/actions/authAction";

const LoginPage = () => {
  const dispatch = useDispatch();
  const state = useSelector((states) => states);

  const onFinish = (values) => {
    dispatch(authLogin(values));
  };

  if (state.authReducer.isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Layout>
      <Content style={{ backgroundColor: "#eeeeee" }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ minHeight: "100vh" }}
        >
          <Col>
            <Card
              size="default"
              style={{
                width: "360px",
                borderRadius: "4px",
              }}
              align="middle"
            >
              <Form
                name="normal_login"
                align="start"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <p style={{ fontWeight: "800", fontSize: "18px" }}>CUBEBASE</p>

                <p style={{ fontWeight: "800" }}>Login</p>

                <Divider />

                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email Id!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    prefix={<AiOutlineMail className="site-form-item-icon" />}
                    placeholder="Email Id"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Password
                    size="large"
                    prefix={
                      <AiOutlineClockCircle className="site-form-item-icon" />
                    }
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}

                <Form.Item>
                  <Button
                    loading={state.authReducer.loading}
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    style={{ width: "100%", marginTop: "16px" }}
                  >
                    Log in
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Link to="forgot-password" className="login-form-forgot">
                    Change or Forgot password?
                  </Link>
                </Form.Item>

                {/* <a href="">Register now!</a> */}
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default LoginPage;
