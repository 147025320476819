import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import * as ROUTES from "../../../router/Routes";
import Can from "../../Can/Can";
import moment from "moment";

const MenuSidebar = ({ collapsed }) => {
  return (
    <Menu
      defaultSelectedKeys={[
        ROUTES.MENU_ITEMS?.find((el) => {
          return (
            el?.link?.split("/")[1] === window.location.pathname.split("/")[1]
          );
        })?.key,
      ]}
      style={{ width: "100%", minHeight: "100%" }}
      mode={"inline"}
      inlineCollapsed={collapsed}
      theme={"dark"}
    >
      {ROUTES.MENU_ITEMS.map((item) => {
        return Can(item.action, item.keyword) && item.type === "MENU" ? (
          <Menu.Item key={item.key}>
            <Link to={item.link} className="menu-items">
              <item.icon />
              <span>{item.label}</span>
              {item.badge &&
              moment(item.expiry).diff(Date.now(), "days") >= 1 ? (
                <span className="menu-item-badge">{item.badge}</span>
              ) : null}
            </Link>
          </Menu.Item>
        ) : (
          item.type === "SUB_MENU" && (
            <Menu.Item key={item.key} disabled>
              <div className="menu-items">
                <span style={{marginLeft:collapsed ? '-9px' : null}}>{collapsed ? "GW" : item.label}</span>
              </div>
            </Menu.Item>
          )
        );
      })}
    </Menu>
  );
};

export default MenuSidebar;
