import { Card, Empty } from "antd";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { PRIMARY_COLORS_SERIES } from "../utils";
import CustomLegend from "../utils/CustomLegend";

const MultipleBarChart = ({
  height = 300,
  chartData = [],
  isLoading = false,
}) => {
  if (!chartData) return <></>;

  if (chartData.length === 0) return <Empty />;

  return (
    <Card bordered={false} size="small" loading={isLoading}>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart data={chartData}>
          <XAxis dataKey="title" />
          <YAxis type="number" />
          <Tooltip cursor={false} />
          <Legend
            content={(e) => (
              <div className="legend-container-row">
                <CustomLegend payload={e.payload} />
              </div>
            )}
          />
          <Bar dataKey="growing" fill={PRIMARY_COLORS_SERIES[0]} />
          <Bar dataKey="diminishing" fill={PRIMARY_COLORS_SERIES[2]} />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default MultipleBarChart;
