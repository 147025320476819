import * as actions from "../actions/index";

const initialState = {
  accountId: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case actions.AM_ACCOUNT_ID:
      return {
        ...state,
        accountId: action.accountId,
      };
    default:
      return state;
  }
};
