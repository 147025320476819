import { Card, Col, DatePicker, Row, Typography , Button } from "antd";
import { useCubeQuery } from "@cubejs-client/react";
import KPIwithTrend from "../../chart/chartComponents/KPIwithTrend";
import React, { useState } from "react";
import Auxi from "../../chart/utils/Auxi";
import moment from "moment";
import CustomTab from "../../chart/utils/CustomTab";
import {
  handleQueryFilters,
  tatDashboardTabs,
  avgTATQuery,
  toHHMMSS,
} from "./utils";
import { handleDateRangeSearch } from "../../redux/actions/tatAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
export const tatDateFilterFormat = "YYYY-MM-DD";
const { Title } = Typography;
const { RangePicker } = DatePicker;

const TatDashbaord = () => {
  const [switchTab, setSwitchTab] = useState("");

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const tatReducer = useSelector((state) => state.tatReducer);

  const {
    resultSet: avgTATData,
    isLoading: avgTATLoading,
    error: avgTATError,
  } = useCubeQuery(
    handleQueryFilters({
      defaultQuery: avgTATQuery,
      before_date: tatReducer.endDate,
      after_date: tatReducer.startDate,
    })
  );

  const handleDateRangePicker = (e) => {
    dispatch(
      e?.length
        ? handleDateRangeSearch(
            e[0]?.format(tatDateFilterFormat).toString(),
            e[1]?.format(tatDateFilterFormat).toString()
          )
        : handleDateRangeSearch("", "")
    );
  };




  return (
    <>
      <Row style={{ backgroundColor: "#fff",display:'flex' , justifyContent:'space-between' , alignItems:'center'}}>
      <Col xs={24} xl={6}>
          <Card size="small" bordered={false}>
            <Title level={5}>Date Range</Title>
            <RangePicker
              style={{ width: "100%" }}
              size="large"
              defaultValue={[
                moment(tatReducer.startDate),
                moment(tatReducer.endDate),
              ]}
              onChange={handleDateRangePicker}
              format={tatDateFilterFormat}
              allowClear={false}
            />
          </Card>
        </Col>


        <Col xs={24} xl={4} style={{padding:'10px'}}>
        <Button type="primary" block><Link to='/tat-dashboard-detail'>Detailed Study</Link></Button>
        </Col>

      </Row>

      
      <Title level={3} className="tat_heading">
        Average TAT for cross-section (MRI and CT)
      </Title>
      <Card bordered={false} size="small" className="card-plain">
        <Row style={{ width: "100%" }}>
          <Col xs={24} lg={8}>
            <Auxi
              rawData={avgTATData}
              error={avgTATError}
              isLoading={avgTATLoading}
              filterMeasures={["PhaseLevelTat.avg_overall_tat_cross"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  const val = toHHMMSS(
                    parseFloat(
                      avgTATData.totalRow()[
                        "PhaseLevelTat.avg_overall_tat_cross"
                      ]
                    )
                  );

                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"Overall TAT"}
                      value={val}
                      font_size='20px'
                      tooltipText={
                        "Average overall TAT which includes both normal TAT and rework TAT"
                      }
                    />
                  );
                })
              }
            />
          </Col>

          <Col xs={24} lg={8}>
            <Auxi
              rawData={avgTATData}
              error={avgTATError}
              isLoading={avgTATLoading}
              filterMeasures={["PhaseLevelTat.avg_normal_tat_cross"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  const val = toHHMMSS(
                    parseFloat(
                      avgTATData.totalRow()[
                        "PhaseLevelTat.avg_normal_tat_cross"
                      ]
                    )
                  );

                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"Normal TAT"}
                      value={val}
                      font_size='20px'
                      tooltipText={
                        "Average normal TAT which does not includes client rework TAT"
                      }
                    />
                  );
                })
              }
            />
          </Col>

          <Col xs={24} lg={8}>
            <Auxi
              rawData={avgTATData}
              error={avgTATError}
              isLoading={avgTATLoading}
              filterMeasures={["PhaseLevelTat.avg_rework_tat_cross"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  const val = toHHMMSS(
                    parseFloat(
                      avgTATData.totalRow()[
                        "PhaseLevelTat.avg_rework_tat_cross"
                      ]
                    )
                  );

                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"Rework TAT"}
                      value={val}
                      font_size='20px'
                      tooltipText={
                        "Average client rework TAT from client activated study to rework completed"
                      }
                    />
                  );
                })
              }
            />
          </Col>
        </Row>

        <Row style={{ width: "100%" }}>
          <Col xs={24} lg={6}>
            <Auxi
              rawData={avgTATData}
              error={avgTATError}
              isLoading={avgTATLoading}
              filterMeasures={["PhaseLevelTat.avg_iqc_tat_cross"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  const val = toHHMMSS(
                    parseFloat(
                      avgTATData.totalRow()["PhaseLevelTat.avg_iqc_tat_cross"]
                    )
                  );

                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"IQC TAT"}
                      value={val}
                      font_size='20px'
                      tooltipText={
                        "Average TAT from IQC assign to IQC completed"
                      }
                    />
                  );
                })
              }
            />
          </Col>

          <Col xs={24} lg={6}>
            <Auxi
              rawData={avgTATData}
              error={avgTATError}
              isLoading={avgTATLoading}
              filterMeasures={["PhaseLevelTat.avg_assignment_tat_cross"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  const val = toHHMMSS(
                    parseFloat(
                      avgTATData.totalRow()[
                        "PhaseLevelTat.avg_assignment_tat_cross"
                      ]
                    )
                  );

                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"Assignment TAT"}
                      value={val}
                      font_size='20px'
                      tooltipText={
                        "Average TAT from Bot/ Manual assign to radiologist to radiologist open"
                      }
                    />
                  );
                })
              }
            />
          </Col>

          <Col xs={24} lg={6}>
            <Auxi
              rawData={avgTATData}
              error={avgTATError}
              isLoading={avgTATLoading}
              filterMeasures={["PhaseLevelTat.avg_radiologist_tat_cross"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  const val = toHHMMSS(
                    parseFloat(
                      avgTATData.totalRow()[
                        "PhaseLevelTat.avg_radiologist_tat_cross"
                      ]
                    )
                  );

                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"Radiologist TAT"}
                      value={val}
                      font_size='20px'
                      tooltipText={
                        "Average TAT from radiologist open to radiologist reported"
                      }
                    />
                  );
                })
              }
            />
          </Col>

          <Col xs={24} lg={6}>
            <Auxi
              rawData={avgTATData}
              error={avgTATError}
              isLoading={avgTATLoading}
              filterMeasures={["PhaseLevelTat.avg_oqc_tat_cross"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  const val = toHHMMSS(
                    parseFloat(
                      avgTATData.totalRow()["PhaseLevelTat.avg_oqc_tat_cross"]
                    )
                  );

                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"OQC TAT"}
                      value={val}
                      font_size='20px'
                      tooltipText={
                        "Average TAT from OQC assign to OQC completed"
                      }
                    />
                  );
                })
              }
            />
          </Col>
        </Row>
      </Card>

      <Title level={3} className="tat_heading">
        Average TAT for XRAY
      </Title>

      <Card bordered={false} size="small" className="card-plain">
        <Row style={{ width: "100%" }}>
          <Col xs={24} lg={8}>
            <Auxi
              rawData={avgTATData}
              error={avgTATError}
              isLoading={avgTATLoading}
              filterMeasures={["PhaseLevelTat.avg_overall_tat_xray"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  const val = toHHMMSS(
                    parseFloat(
                      avgTATData.totalRow()[
                        "PhaseLevelTat.avg_overall_tat_xray"
                      ]
                    )
                  );

                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"Overall TAT"}
                      value={val}
                      font_size='20px'
                      tooltipText={
                        "Average overall TAT which includes both normal TAT and rework TAT"
                      }
                    />
                  );
                })
              }
            />
          </Col>

          <Col xs={24} lg={8}>
            <Auxi
              rawData={avgTATData}
              error={avgTATError}
              isLoading={avgTATLoading}
              filterMeasures={["PhaseLevelTat.avg_normal_tat_xray"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  const val = toHHMMSS(
                    parseFloat(
                      avgTATData.totalRow()["PhaseLevelTat.avg_normal_tat_xray"]
                    )
                  );

                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"Normal TAT"}
                      value={val}
                      font_size='20px'
                      tooltipText={
                        "Average normal TAT which does not includes client rework TAT"
                      }
                    />
                  );
                })
              }
            />
          </Col>

          <Col xs={24} lg={8}>
            <Auxi
              rawData={avgTATData}
              error={avgTATError}
              isLoading={avgTATLoading}
              filterMeasures={["PhaseLevelTat.avg_rework_tat_xray"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  const val = toHHMMSS(
                    parseFloat(
                      avgTATData.totalRow()["PhaseLevelTat.avg_rework_tat_xray"]
                    )
                  );

                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"Rework TAT"}
                      value={val}
                      font_size='20px'
                      tooltipText={
                        "Average client rework TAT from client activated study to rework completed"
                      }
                    />
                  );
                })
              }
            />
          </Col>
        </Row>

        <Row style={{ width: "100%" }}>
          <Col xs={24} lg={12}>
            <Auxi
              rawData={avgTATData}
              error={avgTATError}
              isLoading={avgTATLoading}
              filterMeasures={["PhaseLevelTat.avg_assignment_tat_xray"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  const val = toHHMMSS(
                    parseFloat(
                      avgTATData.totalRow()[
                        "PhaseLevelTat.avg_assignment_tat_xray"
                      ]
                    )
                  );

                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"Assignment TAT"}
                      value={val}
                      font_size='20px'
                      tooltipText={
                        "Average TAT from Bot/ Manual assign to radiologist to radiologist open"
                      }
                    />
                  );
                })
              }
            />
          </Col>

          <Col xs={24} lg={12}>
            <Auxi
              rawData={avgTATData}
              error={avgTATError}
              isLoading={avgTATLoading}
              filterMeasures={["PhaseLevelTat.avg_radiologist_tat_xray"]}
              component={(filterData) =>
                filterData?.map((series, i) => {
                  const val = toHHMMSS(
                    parseFloat(
                      avgTATData.totalRow()[
                        "PhaseLevelTat.avg_radiologist_tat_xray"
                      ]
                    )
                  );

                  return (
                    <KPIwithTrend
                      key={`i_${i}`}
                      title={"Radiologist TAT"}
                      value={val}
                      font_size='20px'
                      tooltipText={
                        "Average TAT from radiologist open to radiologist reported"
                      }
                    />
                  );
                })
              }
            />
          </Col>
        </Row>
      </Card>

      <Card size="small" bordered={false}>
        <CustomTab
          tabData={tatDashboardTabs({
            handleTabChange: (value) => setSwitchTab(value),
          })}
          defaultTab="overview"
          selectedTab={switchTab}
          setSwitchTab={setSwitchTab}
        />
      </Card>
    </>
  );
};

export default TatDashbaord;
