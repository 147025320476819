import { Button, Card, Col, Row, Typography } from "antd";
import React, { useState } from "react";
import KPIwithTrend from "../../../chart/chartComponents/KPIwithTrend";
import MultipleBarChart from "../../../chart/chartComponents/MultipleBarChart";
import { ArrowRightOutlined } from "@ant-design/icons";
import DrillDownModal from "./DrillDownModal";

const { Title } = Typography;

const KPIAndMultipleBarChart = ({
  openModal,
  title,
  segment,
  kpiData = [],
  chartData = [],
  chartLoader = false,
  chartError = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentView, setCurrentView] = useState({});

  const showModal = (curr) => {
    setCurrentView(curr);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Card
        bordered={false}
        className="card-with-shadow"
        title={<Title level={5}>{title}</Title>}
        extra={
          openModal && (
            <Button
              type="primary"
              onClick={() =>
                openModal({
                  modalTitle: title,
                  clientType: segment,
                })
              }
            >
              View Details <ArrowRightOutlined />
            </Button>
          )
        }
        loading={chartLoader}
      >
        <Row>
          {kpiData?.map((item, index) => (
            <Col md={24} lg={12} key={`i_${index}`}>
              <KPIwithTrend
                key={`i_${index}`}
                title={item.title}
                value={item.value}
                isLoading={item.isLoading}
                onClickListener={() =>
                  showModal({
                    title: item.title,
                    queryData: item.drillDownQuery,
                  })
                }
              />
            </Col>
          ))}
        </Row>
        <MultipleBarChart
          height={500}
          chartData={chartData}
          isLoading={chartLoader || chartError}
        />
      </Card>

      {isModalOpen && (
        <DrillDownModal
          title={currentView.title}
          queryData={currentView.queryData}
          isModalVisible={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default KPIAndMultipleBarChart;
