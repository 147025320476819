import { useCubeQuery } from "@cubejs-client/react";
import { Empty, Space, Spin } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { dauNegativeAccountQuery, returnQueryWithID } from "../../utils";
import CenterCardWithTags from "../CenterCardWithTags";

const DAUNegative = () => {
  const accountId = useSelector((state) => state.amReducer.accountId);

  const {
    resultSet: dauNegativeAccountData,
    isLoading: dauNegativeAccountLoading,
    error: dauNegativeAccountError,
    refetch: dauNegativeAccountRefetch,
  } = useCubeQuery(
    returnQueryWithID({
      query: dauNegativeAccountQuery,
      accountId: accountId,
      tableName: "dau",
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );

  useEffect(() => {
    if (!dauNegativeAccountLoading) {
      dauNegativeAccountRefetch();
    }
  }, [accountId]);

  if (dauNegativeAccountLoading || dauNegativeAccountError)
    return <Spin tip={"Please Wait"} />;

  if (dauNegativeAccountData?.rawData().length === 0) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {dauNegativeAccountData?.rawData().map((item, index) => (
        <CenterCardWithTags
          key={`i_${index}`}
          id={item["clients.client_id"]}
          title={item["clients.client_name"]}
          tags={[item["dau.tag"], item["dau.client_segment"], item["dau.flag"]]}
        />
      ))}
    </Space>
  );
};

export default DAUNegative;
