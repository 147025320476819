import { useCubeQuery } from "@cubejs-client/react";
import { Card, Empty, Spin } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CommmonTable from "../../../../chart/chartComponents/CommonTable";
import { reactivatedNurturingQuery, returnQueryWithID } from "../../utils";

const ReactivatedNurturing = () => {
  const accountId = useSelector((state) => state.amReducer.accountId);

  const {
    resultSet: reactivatedNurturingData,
    isLoading: reactivatedNurturingLoading,
    error: reactivatedNurturingError,
    refetch: reactivatedNurturingRefetch,
  } = useCubeQuery(
    returnQueryWithID({
      query: reactivatedNurturingQuery,
      accountId: accountId,
      tableName: "reactivated_clients",
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );

  useEffect(() => {
    if (!reactivatedNurturingLoading) {
      reactivatedNurturingRefetch();
    }
  }, [accountId]);

  if (reactivatedNurturingLoading || reactivatedNurturingError)
    return <Spin tip={"Please Wait"} />;

  if (
    reactivatedNurturingData?.rawData().length === 0 ||
    !reactivatedNurturingData
  )
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <Card size="small">
      <CommmonTable
        type="REACTIVATED_NURTURING"
        pagination={true}
        resultSet={reactivatedNurturingData}
        pivotConfig={{}}
        isLoading={reactivatedNurturingLoading}
        searchKeys={[]}
        size="middle"
        scrollX={900}
        csv={true}
        bordered
        onRowClick={(record, index) => console.log(record, index)}
      />
    </Card>
  );
};

export default ReactivatedNurturing;
