import { useCubeQuery } from "@cubejs-client/react";
import { Empty, Space, Spin } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { returnQueryWithID, wauTodauAccountQuery } from "../../utils";
import CenterCardWithTags from "../CenterCardWithTags";

const WAUtoMAU = () => {
  const accountId = useSelector((state) => state.amReducer.accountId);

  const {
    resultSet: wauTodauAccountData,
    isLoading: wauTodauAccountLoading,
    error: wauTodauAccountError,
    refetch: wauTodauAccountRefetch,
  } = useCubeQuery(
    returnQueryWithID({
      query: wauTodauAccountQuery,
      accountId: accountId,
      tableName: "wau_to_mau",
    }),
    {
      subscribe: false,
      skip: !accountId,
    }
  );

  useEffect(() => {
    if (!wauTodauAccountLoading) {
      wauTodauAccountRefetch();
    }
  }, [accountId]);

  if (wauTodauAccountLoading || wauTodauAccountError)
    return <Spin tip={"Please Wait"} />;

  if (wauTodauAccountData?.rawData().length === 0)
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {wauTodauAccountData?.rawData().map((item, index) => (
        <CenterCardWithTags
          key={`i_${index}`}
          id={item["wau_to_mau.client_id"]}
          title={item["wau_to_mau.client_name"]}
          tags={[
            item["wau_to_mau.tag"],
            item["wau_to_mau.client_segment"],
            item["wau_to_mau.flag"],
          ]}
        />
      ))}
    </Space>
  );
};

export default WAUtoMAU;
