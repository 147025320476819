import { Card } from "antd";
import React from "react";

const CustomTooltip2 = ({
  active,
  payload,
  label,
  formatLabel,
  formatValue,
}) => {
  if (!active && !payload && !payload.length) return null;

  return (
    <Card size="small" title={formatLabel(label)}>
      {payload?.map((data, index) => (
        <p style={{ color: data.fill }} key={`i_${index}`}>
          {data.name} :{" "}
          <span style={{ fontWeight: 800, fontSize: 16 }}>
            {Math.round(data.value)}
          </span>
        </p>
      ))}
    </Card>
  );
};

export default CustomTooltip2;
