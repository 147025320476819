import moment from "moment";
import { Label } from "recharts";

export const removeGlobalVar = (value) => {
  if (!value) return value;
  return value?.replace(
    /Client Overview |Client Study Overview |Study Rework |View Daily |View Tat Summary |Study Iqc |Study Ops |Iqc |Oqc |Reworks |Pending Organs |Study Status |Last3 Months Studycount |Acq Cold Start Metrics |Acq Onb Loss Metrics |Acq Cold Start Clients |Acq Onb Loss Clients |Mrr Churn Clients |Ncc Churn Metrics |Ret Ncc Lt30 D |Ret Mrr Metrics |Assignment |Opened |Ar |Normal Opened |Client Reject |Radiologistsonline |Iqcphase |Oqcphase |Rework Phase |Return Phase |Radiologist Completed |Radiolgist Active |Radiologist Assigned |Tat Default Studies |Studies |Notification Ancillarypresences |Onboarding Loss |Cold Start Loss |Ret Ltv Metrics |Ret Ltv Clients |Rejects |TAT Default |Total Online |Phase |Rad Assigned |Tat Default |Cumulative |Old Pending Cases |List |Dau |Wau |Mau |Reactivated |Onboarded |Active Agents |Online Rads |Active Agents |Study |Deleted |Churn |Level Tat |Hourly Availability |/g,
    ""
  );
};

// #A989C5
// #7172AD
// #F9D45C
// #88BF4D

// "#38b9ff"
// "#ff4938"
// "#2ca02c"
// "#ff7f0e"

export const COLORS_SERIES = [
  "#A989C5",
  "#7172AD",
  "#F9D45C",
  "#88BF4D",
  "#FF9845",
  "#9467bd",
  "#e377c2",
  "#8c564b",
  "#FF9845",
  "#00f7ff",
  "#e35c4f",
];

export const PRIMARY_COLORS_SERIES = ["#124E78", "#369BC9", "#AAE5FF"];

export const SECONDARY_COLORS_SERIES = [
  "#765B98",
  "#E76F55",
  "#FCB163",
  "#4EB1B3",
];

export const validateNumber = (num) => {
  return num || !isNaN(num) ? num : 0;
};

export const formatTime = (tickItem) => {
  if (Date.parse(tickItem)) {
    const ft = moment(tickItem).format("D MMM YY");
    return ft === "1 Jan 1970" ? tickItem : ft;
  }
  return tickItem;
};

export const renderLabel = (props) => {
  const { x, y, width, value } = props;

  return value !== 0 ? (
    <text x={x + width / 1.2} y={y} dy={-2} fill={"#707070"} textAnchor="end">
      {Math.round(value)}
    </text>
  ) : null;
};

export const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;
  // value={parseFloat(props.value).toFixed(2)}
  return <Label {...rest} fontSize="16" fill="#000" />;
};

export const renderCustomizedLegend = (props) => {
  const { payload } = props;
  return (
    <div>
      {payload.reverse().map((entry, index) => (
        <span key={`item-${index}`} style={{ padding: "0px 4px 0px 4px" }}>
          <div
            style={{
              display: "inline-block",
              width: "12px",
              height: "12px",
              backgroundColor: entry.color,
            }}
          ></div>
          <span style={{ padding: "0px 0px 0px 4px" }}>
            {entry.value.split(".")[1].toUpperCase()}
          </span>
        </span>
      ))}
    </div>
  );
};

export const mergeResultSet = (totalResultSet) => {
  let tempSeriesData = [],
    tempTotalRow = [];

  totalResultSet.map((col, i) => {
    tempSeriesData = tempSeriesData.concat(
      col?.data
        ?.tableColumns()
        .filter((el) => col.measure?.indexOf(el.key) !== -1)
    );

    tempTotalRow = { ...tempTotalRow, ...col?.data?.totalRow() };
  });

  return { seriesNames: tempSeriesData, totalRows: tempTotalRow };
};
