import { lazy } from "react";
import { CubeProvider } from "@cubejs-client/react";
import * as PERMISSION from "../config/permissions";
import * as ROUTES from "./Routes";
//import cubejs from 'cubejs';
import cubejs from '@cubejs-client/core';

import { executiveDashboardCubejsApi } from "../views/executive_dashboard/utils";
import {
  edSummaryDashboardCubejsApi,
  gwEDSummaryDashboardCubejsApi,
} from "../views/executive_dashboard_summary/utils";
import { operationalDashboardCubejsApi } from "../views/operational_analytics_dashboard/utils";
import { churnDashboardCubejsApi } from "../views/churn-metrics-dashboard/utils";
// import { salesAnalyticsCubejsAPI } from "../views/sales-analytics-dashboard/utils";
// import { eodSummaryDashboardCubejsApi } from "../views/eod-summary-dashboard/utils";
import { alertDashboardCubejsApi } from "../views/alerts/utils";
import { accountManagementV2CubejsApi } from "../views/account-management-v2/utils";
// import { largeAccountCubejsApi } from "../views/large-account-dashboard/utils";
import { radPerformanceDashboardCubejsApi } from "../views/radiologist-performance/utils";
import { CLIENT_5C } from "../utility/contants";
import ChurnAnalytics from "../views/churn-analytics";
import { churnAnalyticsCubejsApi } from "../views/churn-analytics/utils";
import { radMasterCubejsApi } from "../views/radiologist_master_data/utils";
import { tatDashboardCubejsApi } from "../views/tat-dashboard/utils";

import { tatMetricsCubejsApi } from "../views/tat-metrics/utils";

import { largeAccountCubejsApi } from "../views/large-account-dashboard/utils";
import { whereIsMycaseCubejsApi } from "../views/where_is_my_case/utils";
import WhereIsMycase from "../views/where_is_my_case";
import WhereIsMyCase from "../views/where_is_my_case";


const cubejsApi = cubejs(
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2ODY3NDA2OTUsImV4cCI6MTY4NjgyNzA5NX0.dBfvIeL0i2_xn1a1QkyGByqgbZHhVe2n7ref_gBKFrs',
  { apiUrl: 'https://www.caselist.cube.5cnetwork.com/cubejs-api/v1' }
);




const Maintenance = lazy(() => import("../views/maintenance"));

const AccountManagementV2 = lazy(() =>
  import("../views/account-management-v2")
);
const CenterDetailDashboard = lazy(() =>
  import("../views/center-detail-dashboard")
);
const ExecutiveDashboard = lazy(() => import("../views/executive_dashboard"));

const OperationalAnalyticsDashboard = lazy(() =>
  import("../views/operational_analytics_dashboard")
);
const ChurnMetricsDashboard = lazy(() =>
  import("../views/churn-metrics-dashboard")
);
const ExecutiveDashboardSummary = lazy(() =>
  import("../views/executive_dashboard_summary")
);

const RadPerformanceDashboard = lazy(() =>
  import("../views/radiologist-performance")
);

const TatDashboard = lazy(() => import("../views/tat-dashboard"));

const TatMetrics = lazy(() =>
  import("../views/tat-metrics")
);

// importing study level tat page
const StudyLevelTat = lazy(() =>
  import("../views/tat-dashboard/tat-dashboard-detail")
);

const RadPerformanceDrillDown = lazy(() =>
  import("../views/radiologist-performance/drill-down")
);

const ODAlertsPage = lazy(() => import("../views/alerts"));

//GENWORKS
const GWCenterDetailDashboard = lazy(() =>
  import("../views/genworks/center-detail-dashboard")
);
const GWExecutiveDashboardSummary = lazy(() =>
  import("../views/genworks/executive_dashboard_summary")
);

const RadiologistMasterData = lazy(() =>
  import("../views/radiologist_master_data")
);


//importing Large Account Components
const LargeAccountDashboard = lazy(() =>
  import("../views/large-account-dashboard")
);
//
const WhereIsMyCaseDashboard=lazy(()=>{
  import("../views/where_is_my_case")
})

export const navigationRoutes = [
  {
    key: PERMISSION.P_EXECUTIVE_DASHBOARD,
    url: ROUTES.EXECUTIVE_DASHBOARD,
    breadcrumb: [
      {
        label: PERMISSION.P_EXECUTIVE_DASHBOARD,
        url: ROUTES.EXECUTIVE_DASHBOARD,
      },
    ],
    component: (
      <CubeProvider cubejsApi={executiveDashboardCubejsApi}>
        <ExecutiveDashboard />
      </CubeProvider>
    ),
  },

  {
    key: PERMISSION.P_OPERATIONAL_DASHBOARD,
    url: ROUTES.OPERATIONAL_ANALYTICS_DASHBOARD,
    breadcrumb: [
      {
        label: PERMISSION.P_OPERATIONAL_DASHBOARD,
        url: ROUTES.OPERATIONAL_ANALYTICS_DASHBOARD,
      },
    ],
    component: (
      <CubeProvider cubejsApi={operationalDashboardCubejsApi}>
        <OperationalAnalyticsDashboard />
      </CubeProvider>
    ),
  },
  {
    key: PERMISSION.P_EXECUTIVE_DASHBOARD_SUMMARY,
    url: ROUTES.EXECUTIVE_DASHBOARD_SUMMARY,
    breadcrumb: [
      {
        label: PERMISSION.P_EXECUTIVE_DASHBOARD_SUMMARY,
        url: ROUTES.EXECUTIVE_DASHBOARD_SUMMARY,
      },
    ],
    component: (
      <CubeProvider cubejsApi={edSummaryDashboardCubejsApi}>
        <ExecutiveDashboardSummary />
      </CubeProvider>
    ),
  },
  {
    key: PERMISSION.P_ACCOUNT_MANAGEMENT,
    url: ROUTES.ACCOUNT_MANAGEMENT_DASHBOARD,
    breadcrumb: [
      {
        label: PERMISSION.P_ACCOUNT_MANAGEMENT,
        url: ROUTES.ACCOUNT_MANAGEMENT_DASHBOARD,
      },
    ],
    component: (
      <CubeProvider cubejsApi={accountManagementV2CubejsApi}>
        <AccountManagementV2 />
      </CubeProvider>
    ),
  },
  {
    key: PERMISSION.P_CENTER_DETAIL_DASHBOARD,
    url: ROUTES.CENTER_DETAIL_DASHBOARD,
    breadcrumb: [
      // {
      //   label: PERMISSION.P_ACCOUNT_MANAGEMENT,
      //   url: ROUTES.ACCOUNT_MANAGEMENT_DASHBOARD,
      // },
      {
        label: PERMISSION.P_CENTER_DETAIL_DASHBOARD,
        url: ROUTES.CENTER_DETAIL_DASHBOARD,
      },
    ],
    component: <CenterDetailDashboard clientType={CLIENT_5C} />,
  },
  {
    key: PERMISSION.P_CHURN_DASHBOARD,
    url: ROUTES.CHURN_DASHBOARD,
    breadcrumb: [
      {
        label: PERMISSION.P_CHURN_DASHBOARD,
        url: ROUTES.CHURN_DASHBOARD,
      },
    ],
    component: (
      <CubeProvider cubejsApi={churnDashboardCubejsApi}>
        <ChurnMetricsDashboard />
      </CubeProvider>
    ),
  },
  {
    key: PERMISSION.P_CHURN_ANALYTICS,
    url: ROUTES.CHURN_ANALYTICS,
    breadcrumb: [
      {
        label: PERMISSION.P_CHURN_ANALYTICS,
        url: ROUTES.CHURN_ANALYTICS,
      },
    ],
    component: (
      <CubeProvider cubejsApi={churnAnalyticsCubejsApi}>
        <ChurnAnalytics />
      </CubeProvider>
    ),
  },
  {
    key: PERMISSION.P_RAD_PERFORMANCE_DASHBOARD,
    url: ROUTES.RAD_PERFORMANCE_DASHBOARD,
    breadcrumb: [
      {
        label: PERMISSION.P_RAD_PERFORMANCE_DASHBOARD,
        url: ROUTES.RAD_PERFORMANCE_DASHBOARD,
      },
    ],
    component: (
      <CubeProvider cubejsApi={radPerformanceDashboardCubejsApi}>
        <RadPerformanceDashboard />
      </CubeProvider>
    ),
  },
  {
    key: PERMISSION.P_RAD_PERFORMANCE_DRILL_DOWN,
    url: ROUTES.RAD_PERFORMANCE_DRILL_DOWN,
    breadcrumb: [
      {
        label: PERMISSION.P_RAD_PERFORMANCE_DRILL_DOWN,
        url: ROUTES.RAD_PERFORMANCE_DRILL_DOWN,
      },
    ],
    component: (
      <CubeProvider cubejsApi={radPerformanceDashboardCubejsApi}>
        <RadPerformanceDrillDown />
      </CubeProvider>
    ),
  },
  // {
  //   key: PERMISSION.P_SALES_ANALYTICS_DASHBOARD,
  //   url: ROUTES.SALES_ANALYTICS_DASHBOARD,
  //   breadcrumb: [
  //     {
  //       label: PERMISSION.P_SALES_ANALYTICS_DASHBOARD,
  //       url: ROUTES.SALES_ANALYTICS_DASHBOARD,
  //     },
  //   ],
  //   component: (
  //     <CubeProvider cubejsApi={salesAnalyticsCubejsAPI}>
  //       <Maintenance />
  //     </CubeProvider>
  //   ),
  // },
  {
    key: PERMISSION.P_RADIOLOGIST_MASTER_DATA,
    url: ROUTES.RADIOLOGIST_MASTER_DATA,
    breadcrumb: [
      {
        label: PERMISSION.P_RADIOLOGIST_MASTER_DATA,
        url: ROUTES.RADIOLOGIST_MASTER_DATA,
      },
    ],
    component: (
      <CubeProvider cubejsApi={radMasterCubejsApi}>
        <RadiologistMasterData />
      </CubeProvider>
    ),
  },
  {
    key: PERMISSION.P_TAT_DASHBOARD_DETAILS,
    url: ROUTES.TAT_DASHBOARD_DETAILED,
    component: (
      <CubeProvider cubejsApi={tatDashboardCubejsApi}>
        <StudyLevelTat />
      </CubeProvider>
    ),
  },
  {
    key: PERMISSION.P_LARGE_ACCOUNT,
    url: ROUTES.LARGE_ACCOUNTS_DASHBOARD,
    breadcrumb: [
      {
        label: PERMISSION.P_LARGE_ACCOUNT,
        url: ROUTES.LARGE_ACCOUNTS_DASHBOARD,
      },
    ],
    component: (
      <CubeProvider cubejsApi={largeAccountCubejsApi}>
        <LargeAccountDashboard />
      </CubeProvider>
    ),
  },
  {
    key: PERMISSION.P_WHERE_IS_MY_CASE,
    url: ROUTES.WHERE_IS_MY_CASE,
    breadcrumb: [
      {
        label: PERMISSION.P_WHERE_IS_MY_CASE,
        url: ROUTES.WHERE_IS_MY_CASE,
      },
    ],
    component: (
      <CubeProvider cubejsApi={whereIsMycaseCubejsApi}>
        <WhereIsMyCase />
      </CubeProvider>
    ),
  },
  // {
  //   key: PERMISSION.P_SALES_EOD_DASHBOARD,
  //   url: ROUTES.SALES_EOD_SUMMARY_DASHBOARD,
  //   breadcrumb: [
  //     {
  //       label: PERMISSION.P_SALES_EOD_DASHBOARD,
  //       url: ROUTES.SALES_EOD_SUMMARY_DASHBOARD,
  //     },
  //   ],
  //   component: (
  //     <CubeProvider cubejsApi={eodSummaryDashboardCubejsApi}>
  //       <Maintenance />
  //     </CubeProvider>
  //   ),
  // },

  //ALERTS
  {
    key: PERMISSION.A_ALERT_DASHBOARD,
    url: ROUTES.ALERT_DASHBOARD,
    renderDashboard: false,
    component: (
      <CubeProvider cubejsApi={alertDashboardCubejsApi}>
        <ODAlertsPage />
      </CubeProvider>
    ),
  },

  {
    key: PERMISSION.P_TAT_DASHBOARD,
    url: ROUTES.TAT_DASHBOARD,
    component: (
      <CubeProvider cubejsApi={tatDashboardCubejsApi}>
        <TatDashboard />
      </CubeProvider>
    ),
  },


  {
    key: PERMISSION.P_TAT_METRICS,
    url: ROUTES.TAT_METRICS,
    component: (
      <CubeProvider cubejsApi={tatMetricsCubejsApi}>
        <TatMetrics />
      </CubeProvider>
    ),
  },

  // changes made
  {
    key: PERMISSION.P_TAT_DASHBOARD_DETAILS,
    url: ROUTES.TAT_DASHBOARD_DETAILED,
    component: (
      <CubeProvider cubejsApi={tatDashboardCubejsApi}>
        <StudyLevelTat />
      </CubeProvider>
    ),
  },


  //GENWORKS

  {
    key: PERMISSION.GW_ACCOUNT_MANAGEMENT_DASHBOARD,
    url: ROUTES.GW_ACCOUNT_MANAGEMENT_DASHBOARD,
    breadcrumb: [
      {
        label: PERMISSION.GENWORKS,
      },
      {
        label: PERMISSION.GW_ACCOUNT_MANAGEMENT_DASHBOARD,
        url: ROUTES.GW_ACCOUNT_MANAGEMENT_DASHBOARD,
      },
    ],
    component: <GWCenterDetailDashboard />,
  },
  {
    key: PERMISSION.GW_EXECUTIVE_SUMMARY_DASHBOARD,
    url: ROUTES.GW_EXECUTIVE_SUMMARY_DASHBOARD,
    breadcrumb: [
      {
        label: PERMISSION.GENWORKS,
      },
      {
        label: PERMISSION.GW_EXECUTIVE_SUMMARY_DASHBOARD,
        url: ROUTES.GW_EXECUTIVE_SUMMARY_DASHBOARD,
      },
    ],
    component: (
      <CubeProvider cubejsApi={gwEDSummaryDashboardCubejsApi}>
        <GWExecutiveDashboardSummary />
      </CubeProvider>
    ),
  },

  // {
  //   key: PERMISSION.TABLE_SEARCH,
  //   url: ROUTES.TABLE_SEARCH_DASHBOARD,
  //   breadcrumb: [
  //     {
  //       label: PERMISSION.TABLE_SEARCH,
  //     },

  //   ],
  //   component: (
  //     <CubeProvider cubejsApi={searchTableCubejsApi}>
  //       <SearchTable/>
  //     </CubeProvider>
  //   ),
  // },
];
