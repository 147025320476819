import { Card, Result, Tabs } from "antd";
import Layout from "antd/lib/layout/layout";
import React from "react";
import { useSelector } from "react-redux";
import { alertsTabs } from "../utils";

const AlertsDashboard = () => {
  const accountId = useSelector((state) => state.amReducer.accountId);

  if (!accountId)
    return (
      <Result
        status="404"
        title="No Result"
        subTitle="Please select a account manager!"
      />
    );
  return (
    <Layout>
      <Card size="small" bordered={false}>
        <Tabs
          size="large"
          tabPosition="left"
          type="line"
          items={alertsTabs.map((item) => {
            return {
              label: item.label,
              key: item.key,
              children: item.children,
            };
          })}
        />
      </Card>
    </Layout>
  );
};

export default AlertsDashboard;
